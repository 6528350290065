import React, { useState, useEffect, useRef } from "react";
import {
    Row,
    Col,
    Input,
    Button,
    Form,
    Select,
    Switch,
    Modal,
    Upload,
    Image,
    Checkbox,
    DatePicker,
    TimePicker
} from "antd";
import Sidebar from "../../components/Sidebar/sidebar";
import { GoArrowLeft } from "react-icons/go";
import { LuUpload } from "react-icons/lu";
import { CiUser } from "react-icons/ci";
import { LiaCalendarAltSolid } from "react-icons/lia";
import { BsInfoCircle } from "react-icons/bs";
import { FaArrowRightLong } from "react-icons/fa6";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { MdOutlineCategory } from "react-icons/md";
import { BsBarChart } from "react-icons/bs";
import { FaCompressArrowsAlt, FaExpandArrowsAlt } from "react-icons/fa";
import { CiDollar } from "react-icons/ci";
import { BsFileCode } from "react-icons/bs";
import { FiPlusCircle } from "react-icons/fi";
import TopNavBar from "../../components/topNavbar";
import * as getServices from "../../services/getServices";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as uploadServices from "../../services/uploadServices";
import Swal from "sweetalert2";
import { FaClipboardQuestion } from "react-icons/fa6";
import { MdOutlineQuestionAnswer } from "react-icons/md";
import { FaRegCalendarAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { HiMiniCheckBadge } from "react-icons/hi2";
import StripePaymentForm from "../paymentPrcess/paymentForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { IoIosHelpCircleOutline } from "react-icons/io";


const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export default function AddNewCourse() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const vendordata = useSelector((state) => state.user.userData);
    const { TextArea } = Input;

    const onFinishFailed = () => { };

    const [wsqChecked, setWsqChecked] = useState(true);
    const [gstChecked, setGstChecked] = useState(false);
    const [categories, setCategories] = useState('');
    const [childCategories, setChildCategories] = useState('');
    const [subCategories, setSubCategories] = useState();
    const [file, setFile] = useState(null);
    const [document, setDocument] = useState(null);
    const [faqs, setFAQs] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [video, setVideo] = useState(null);
    const [videoThumbnail, setVideoThumbnail] = useState(null);
    const [isModalOpenThumbnail, setIsModalOpenThumbnail] = useState(false);
    const [profilePhoto, setProfilePhoto] = useState('');
    // const [upload, setUpload] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const [selectedChildCategory, setSelectedChildCategory] = useState('');
    const [filteredSubCategories, setFilteredSubCategories] = useState([]);
    const [paymentForm, setPaymentForm] = useState(false);
    const [addedCourseData, setAddedCourseData] = useState([]);
    const [sponsoredCourse, setSponsoredCourse] = useState(false);
    const [sponsorshipData, setSponsorshipData] = useState([]);
    const [sponrshipPlan, setSponsorshipPlan] = useState();
    const [days, setDays] = useState('');
    const [courseAmount, setCourseAmount] = useState('');

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [fileList, setFileList] = useState([]);
    const [classes, setClasses] = useState([]);
    const [selectedClassLevel, setSelectedCLassLevel] = useState();
    const [addCancellationPlicy, setAddCancellationPolicy] = useState(false);
    const [addTrialDays, SetAddTrialDays] = useState(false);
    const fileInputRef = useRef(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isPlanModalVisible, setIsPlanModalVisible] = useState(false);
    const [isDurationModal, setIsDurationModal] = useState(false);
    const [isFreCourse, setIsFreeCourse] = useState(false);
    const [stripePromise, setStripePromise] = useState(
        loadStripe(
            "pk_test_51NuUdDDhhEVxDG993lu35F4QwDa8YaZcktRtzp4d5f0Mtplas4c5Hc01lJFPH0R9XgshjLP7BWpoE6IYCanmu8JT00Il8HHzf6"
        )
    );
    const [clientSecret, setClientSecret] = useState("");
    const [courseSlots, setCourseSlots] = useState([]);
    const [addCourse, setAddCourse] = useState(false);
    const [postalCode, setPostalCode] = useState('');
    const [address, setAddress] = useState('');
    const [addressLatLong, setAddressLatLong] = useState('');
    const [postalLatLong, setPostalLatLong] = useState('');
    const [startDate, setStartData] = useState('');
    const [endDate, setEndData] = useState('');
    const [helpModel, setHelpModel] = useState(false)
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${postalCode}&key=${apiKey}`;

    const fetchData = async () => {
        if (days !== '') {

            try {
                dispatch(uploadServices.getCalculatedCustom({ days })).then((response) => {

                    setCourseAmount(response.totalAmount);
                });

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };

    fetchData();

    const handleDaysChange = (e) => {
        setDays(e.target.value);
    }

    useEffect(() => {
        dispatch(getServices.getSponsorshipData())
            .then((response) => {
                setSponsorshipData(response?.data);
            })
            .catch((err) => {
                console.error(err);
            });
    }, [sponsoredCourse]);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: "none",
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    const handleWsqChange = (checked) => {
        setWsqChecked(checked);
        if (checked) {
            setGstChecked(false);
        }
    };

    const handleGstChange = (checked) => {
        setGstChecked(checked);
        if (checked) {
            setWsqChecked(false);
        }
    };

    const onFinish = (value) => {
        console.log(value , "value ")
        setAddCourse(true)
        const formData = new FormData();
        const files = fileInputRef.current.fileList;
        files.forEach((file, index) => {
            formData.append(`school_images[${index}]`, file.originFileObj);
        });
        formData.append(
            "vendor_id",
            vendordata?.vendor?.id
                ? vendordata?.vendor?.id
                : vendordata?.vendor_id
                    ? vendordata?.vendor_id
                    : vendordata?.Vendor?.id
        );
        formData.append("title", value?.title);
        if (value?.child_categories) {
            formData.append("category_id", value?.child_categories);
        }

        if (value?.sub_categories) {
            formData.append("sub_category_id", value?.sub_categories);
        }


        formData.append("course_status", wsqChecked === true ? "wsq" : "gst");
        formData.append("class_level", selectedClassLevel);
        formData.append("min_age", parseInt(value?.min_age));
        formData.append("max_age", parseInt(value?.max_age));
        formData.append("about_course", value?.about_this_course);
        formData.append("fee", value?.fee_include);
        formData.append("special_instruction", value?.special_instruction);
        if (file !== null) {
            formData.append("image", file);
        }
        if (document !== null) {
            formData.append("document", document);
        }
        formData.append("instructor_name", value?.instructor_name);
        formData.append("work_experience", parseInt(value?.work_experience));
        formData.append("profile_photo", profilePhoto);
        formData.append("about", value?.about);
        formData.append("course_start", startDate);
        formData.append("course_end", endDate);
        formData.append("visibility", value?.visibility);
        if (video !== null) {
            formData.append("video", video);
        }
        if (videoThumbnail !== null) {
            formData.append("thumbnail", videoThumbnail);
        }
        formData.append("refundable", addCancellationPlicy);
        formData.append("trial", addTrialDays);
        formData.append("geolocation", JSON.stringify({
            postal_code: {
                latitude: postalLatLong.latitude,
                longitude: postalLatLong.longitude,
            },
            address: {
                latitude: addressLatLong.latitude,
                longitude: addressLatLong.longitude,
            }
        }));
        formData.append("address", address);
        formData.append("postal_code", postalCode);
        // formData.append("trial_days", videoThumbnail);
        // formData.append("school_images",value?.school_images?.originFileObj)
        if (faqs.length > 0) {
            formData.append("faq", JSON.stringify(faqs));
        }

        if (isFreCourse) {
            formData.append("is_premium", "free");
        }

        if (value?.refund_policy !== undefined && value?.refund_policy) {
            formData.append("refund_policy", value?.refund_policy);
        }
console.log(formData , "formData")
        dispatch(uploadServices.addNewCourse(formData))
            .then((response) => {
                setAddedCourseData(response);

                if (sponsoredCourse) {
                    localStorage.setItem("paymentType", "sponsoredCourse");
                    setIsModalVisible(true);
                } else {
                    Swal.fire(
                        "Course Added Successful!",
                        "You have successfully added course!",
                        "success"
                    ).then((result) => {
                        navigate("/vendor/my-courses");
                    });
                }
            })
            .catch((error) => {
                setAddCourse(false)

                Swal.fire({
                    title: error.response.data.error,
                    icon: "error"
                }

                );
            });
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showModalThumbnail = () => {
        setIsModalOpenThumbnail(true);
    };

    const handleOkThumbnail = () => {
        setIsModalOpenThumbnail(false);
    };

    const handleCancelThumbnail = () => {
        setIsModalOpenThumbnail(false);
    };

    useEffect(() => {
        dispatch(
            uploadServices.PaymentIntent({
                amount: 90,
                currency: "SGD",
            })
        ).then((response) => {
            setClientSecret(response?.clientSecret);
        });
    }, []);

    useEffect(() => {
        dispatch(getServices.getCategories())
            .then((response) => {
                console.log(response?.categories , "res of catego")  
                setCategories(response?.categories);
            })
            .catch((err) => {
        
            });
        dispatch(getServices.getSubCategories())
            .then((response) => {
                setSubCategories(response?.subcategories);
            })
            .catch((err) => {
            });

        dispatch(getServices.getClasses())
            .then((response) => {
                setClasses(response?.classes);
            })
            .catch((err) => {
                // console.log({ err });
            });
    }, [dispatch]);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const handleFileChangeDoc = (e) => {
        const selectedFile = e.target.files[0];
        setDocument(selectedFile);
    };

    const handleProfilePhoto = (e) => {
        const selectedFile = e.target.files[0];
        setProfilePhoto(selectedFile);
    };

    const handleVideo = ({ fileList }) => {
        const selectedFile = fileList[0]?.originFileObj;
        setVideo(selectedFile);
    };

    const handleThumbnail = ({ fileList }) => {
        const selectedFile = fileList[0]?.originFileObj;
        setVideoThumbnail(selectedFile);
    };

    const onFinishFAQ = (e) => {
        let question_answer = [...faqs];
        setFAQs([]);

        question_answer.push(e);

        setFAQs(question_answer);
        setIsModalOpen(false);
    };

    const onFinishFailedFAQ = (e) => { };

    const hangleVideoModal = () => {
        showModalThumbnail(true);
    };

    const handleUploadButton = () => {
        Swal.fire(
            " Added Successful!",
            "Video and thumbnail added successfully!",
            "success"
        ).then((result) => {
            setIsModalOpenThumbnail(false);
            // setUpload(true);
        });
    };

    const onChange = (date, dateString) => {
        setStartData(dateString)
    };

    const onEndDataChange = (date, dateString) => {
        setEndData(dateString);
    };

    const handlecategory = (value) => {
        setSelectedCategory(value);
        setSelectedSubCategory(null);
        setFilteredSubCategories([]);
    };

    useEffect(() => {
        if (selectedCategory) {
            const filtered = categories.flatMap((category) =>
                category.child_category.filter(
                    (item) => item.parent_id === selectedCategory
                )
            );

            const childCategoryOptions = filtered.map((data) => ({
                name: data.name,
                id: data.id,
            }));

            const subCategoryOptions = filtered.flatMap((child) => {
                console.log(child, 'child'); // Log the child object
                return child.sub_categories?.map((subData) => ({
                    name: subData.name,
                    id: subData.id,
                    category_id: subData.parent_id,
                })) || []; // Return the mapped array or an empty array
            });

            if (childCategoryOptions.length > 0) {
                setChildCategories(childCategoryOptions);
            }

            setFilteredSubCategories(
                subCategoryOptions.filter(
                    (item) => item.category_id === selectedChildCategory
                )
            );
            setSelectedSubCategory(null);
        }
    }, [categories, selectedCategory, selectedChildCategory]);
    console.log(childCategories, "child Categkores")
    console.log(categories, "categories")
    // console.log(selected, "filteredSubCategories")
    const beforeUpload = (file) => {
        const isImage = file.type.startsWith("image/");
        if (!isImage) {
            alert("You can only upload image files!");
        }
        return isImage || Upload.LIST_IGNORE;
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const handleClass = (e) => {
        setSelectedCLassLevel(e);
    };

    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setSponsoredCourse(true);
        }
    };

    const handleModalOk = () => {
        setIsModalVisible(false);
        setIsPlanModalVisible(true);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    const handlePlanModalOk = () => {
        setIsPlanModalVisible(false);
    };

    const handlePlanModalCancel = () => {
        setIsPlanModalVisible(false);
    };

    const handleDurationModalOk = () => {
        setPaymentForm(true);
        setIsDurationModal(false);
    };

    const handleDurationModalCancel = () => {
        setIsDurationModal(false);
        setIsPlanModalVisible(true);
    };

    const openDurationModal = () => {
        setIsDurationModal(true);
        setIsPlanModalVisible(false);
    };

    const handlePayment = (value) => {
        setSponsorshipPlan(value);
        setPaymentForm(true);
        setIsPlanModalVisible(false);
    };

    const handlePaymentFormCancle = () => {
        setPaymentForm(false);
    };

    const handlePaymentForm = () => {
        setPaymentForm(false);
    };


    const TimeFormater = (value) => {
        let dateObject = new Date(value);
        let time = dateObject.toTimeString().split(' ')[0];
        return time;
    }

    const handleSlotChange = (day, startTime, endTime) => {
        const updatedSlots = courseSlots.filter(slot => slot.day !== day);
        if (startTime && endTime) {
            updatedSlots.push({ day, start_time: startTime.format('HH:mm:ss'), end_time: endTime.format('HH:mm:ss') });
        }
        setCourseSlots(updatedSlots);


    };


    const getGeolocationAddress = async () => {
        try {
            dispatch(getServices.getLatLongAddress({ full_address: address })).then((response) => {
                setAddressLatLong(response)
                Swal.fire(
                    "Address Added Successful!",
                    "You have successfully added address!",
                    "success"
                )
            }).catch((error) => {
                // console.info({ error })
            })
        } catch {
            console.error('Error getting geolocation data from postal code.')
        }
    };


    const handleAddress = () => {
        getGeolocationAddress()
    }

    const getGeolocationPostalCode = async () => {
        try {
            dispatch(getServices.getLatLongPostalCode({ postalCode: postalCode })).then((response) => {
                setPostalLatLong(response)
                Swal.fire(
                    "Postal Code Added Successful!",
                    "You have successfully added postal code!",
                    "success"
                )
            }).catch((error) => {
                // console.info({ error })
            })
        } catch {
            console.error('Error getting geolocation data from postal code.')
        }
    };



    const handlePostalCode = async () => {
        getGeolocationPostalCode()
    }

    const handlehelp = () => {
        setHelpModel(true)
    }
    const handleHelpCancle = () => {
        setHelpModel(false)
    }
    const handlehelpOk = () => {
        setHelpModel(false)
    }
    return (
        <>
            <TopNavBar />
            <Row className="add-course-main-row">
                <Col xl={4} className="d-xl-block d-md-none">
                    <Sidebar />
                </Col>
                <Col xl={20} md={24}>
                    <div className="mainContent add-new-course">
                        <div className="new-course-container">
                            <div className="new-course-inner-container container">
                                <div className="header-div">
                                    <p className="add-new-course">Add New Item</p>
                                    <Button
                                        className="go-back-button"
                                        onClick={() => navigate("/vendor/my-courses")}
                                    >
                                        <span>
                                            <GoArrowLeft className="left-arrow-icon" />
                                        </span>
                                        Go Back
                                    </Button>
                                </div>

                                <div className="form-container">
                                    <Form
                                        name="basic"
                                        initialValues={{
                                            remember: true,
                                        }}
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                        autoComplete="off"
                                        className="add-new-course-form"
                                        layout="vertical"
                                    >
                                        <Row className="form-row">
                                            <Col md={24} className="custom-padding laptop-padd">
                                                <Form.Item
                                                    className="form-input-class"
                                                    label="Title"
                                                    name="title"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please enter title.",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        className="input-box-login"
                                                        placeholder="Type here....."
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8} className="input-new-course">
                                                <Form.Item
                                                    className="form-input-class"
                                                    name="category"
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //         message: "Please select category.",
                                                    //     },
                                                    // ]}
                                                >
                                                    <Select
                                                        className="input-box-login select-category"
                                                        placeholder="Category"
                                                        prefix={<MdOutlineCategory />}
                                                        onChange={(value) => handlecategory(value)}
                                                    >
                                                        {categories &&
                                                            categories.map((category) => (
                                                                <Select.Option
                                                                    key={category.id}
                                                                    value={category.id}
                                                                >
                                                                    {category.name}
                                                                </Select.Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col md={8} className="input-new-course">
                                                {/* as per the data this id child Categories */}
                                                <Form.Item
                                                    className="form-input-class"
                                                    name="child_categories"
                                                    label="Select Sub-Categories"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: "Please select sub category.",
                                                //     },
                                                // ]}
                                                >
                                                    <Select
                                                        className="input-box-login sub-select-category"
                                                        placeholder="Sub-Categories"
                                                        prefix={<MdOutlineCategory />}
                                                        onChange={(value) => setSelectedChildCategory(value)}
                                                        disabled={!selectedCategory}
                                                    >
                                                        {childCategories &&
                                                            childCategories.map((childCategory) => (
                                                                <Select.Option
                                                                    key={childCategory.id}
                                                                    value={childCategory.id}
                                                                // disabled={selectedSubCategory === childCategory.id}
                                                                >
                                                                    {childCategory.name}
                                                                </Select.Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col md={8} className="input-new-course">
                                                {/* as per the data this id sub Categories */}
                                                <Form.Item
                                                    className="form-input-class"
                                                    name="sub_categories"
                                                    label="Select Child Categories"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: "Please select child category.",
                                                //     },
                                                // ]}
                                                >
                                                    <Select
                                                        className="input-box-login sub-select-category"
                                                        placeholder="Child-Categories"
                                                        prefix={<MdOutlineCategory />}
                                                        value={selectedSubCategory}
                                                        onChange={(value) => setSelectedSubCategory(value)}
                                                        disabled={!selectedCategory}
                                                    >
                                                        {filteredSubCategories &&
                                                            filteredSubCategories.map((subCategory) => (
                                                                <Select.Option
                                                                    key={subCategory.id}
                                                                    value={subCategory.id}
                                                                    disabled={selectedSubCategory === subCategory.id}
                                                                >
                                                                    {subCategory.name}
                                                                </Select.Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                md={8}
                                                className="custom-padding fee-instruction-upload-col special-instruction ps-0"
                                            >

                                                <Form.Item
                                                    className="form-input-class"
                                                    name="visibility"
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //         message: "Please select visibility.",
                                                    //     },
                                                    // ]}
                                                >
                                                    <Select
                                                        className="input-box-login select-category"
                                                        placeholder="Choose Visibility"
                                                        prefix={<MdOutlineCategory />}
                                                    >
                                                        <Select.Option value={"public"} key={"public"}>
                                                            Public
                                                        </Select.Option>
                                                        <Select.Option value={"private"} key={"private"}>
                                                            Private
                                                        </Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col md={8} className="input-new-course">
                                                <Form.Item
                                                    className="form-input-class"
                                                    name="course_class"
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //         message: "Please select class.",
                                                    //     },
                                                    // ]}
                                                >
                                                    <Select
                                                        className="input-box-login select-class"
                                                        placeholder="Class"
                                                        prefix={<MdOutlineCategory />}
                                                        onChange={(value) => handleClass(value)}
                                                    >
                                                        {classes &&
                                                            classes.map((classe) => (
                                                                <Select.Option key={classe.id} value={classe.id}>
                                                                    {classe.name}
                                                                </Select.Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row className="form-row">
                                            <Col md={24} className="custom-padding laptop-padd">
                                                <Form.Item
                                                    className="form-input-class"
                                                    name="about_this_course"
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //         message: "Please enter brief about the course.",
                                                    //     },
                                                    // ]}
                                                >
                                                    <TextArea
                                                        className="leave-comment-text-area"
                                                        rows={4}
                                                        placeholder="Type here....."
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className="form-row">
                                            <Col md={24} className="custom-padding laptop-padd">
                                                <Form.Item
                                                    className="form-input-class"
                                                    name="school_images"
                                                    valuePropName="file"
                                                    label="Upload Organisation Image"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please upload images.",
                                                        },
                                                    ]}
                                                >
                                                    <div className="upload-school-images-continer">
                                                        <Upload
                                                            ref={fileInputRef}
                                                            listType="picture-card"
                                                            multiple
                                                            getValueFromEvent={normFile}
                                                            beforeUpload={beforeUpload}
                                                        >
                                                            {fileList.length >= 8 ? null : uploadButton}
                                                        </Upload>
                                                        {previewImage && (
                                                            <Image
                                                                wrapperStyle={{
                                                                    display: "none",
                                                                }}
                                                                preview={{
                                                                    visible: previewOpen,
                                                                    onVisibleChange: (visible) =>
                                                                        setPreviewOpen(visible),
                                                                    afterOpenChange: (visible) =>
                                                                        !visible && setPreviewImage(""),
                                                                }}
                                                                src={previewImage}
                                                            />
                                                        )}
                                                    </div>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row className="form-row fee-instruction-upload-row">
                                            <Col
                                                md={8}
                                                className="custom-padding fee-instruction-upload-col fee-include"
                                            >
                                                <Form.Item
                                                    className="form-input-class"
                                                    name="fee_include"
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //         message: "Please enter fee.",
                                                    //     },
                                                    // ]}
                                                >
                                                    <Input
                                                        type="number"
                                                        className="input-box-login"
                                                        placeholder="Type here....."
                                                        prefix={<CiDollar />}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                md={8}
                                                className="custom-padding fee-instruction-upload-col special-instruction"
                                            >
                                                <p
                                                    style={{
                                                        textAlign: "start",
                                                        fontFamily: "POPPINS",
                                                    }}
                                                >
                                                    Special Instruction
                                                    <span
                                                        className="optional"
                                                        style={{
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                            color: "#AFB5C0",
                                                            fontFamily: "Poppins",
                                                        }}
                                                    >
                                                        (Optional)
                                                    </span>
                                                </p>

                                                <Form.Item
                                                    className="form-input-class"
                                                    name="special_instruction"
                                                >
                                                    <Input
                                                        className="input-box-login"
                                                        placeholder="Enter Instructions If Any?"
                                                        prefix={<BsFileCode />}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                md={8}
                                                className="input-new-course fee-instruction-upload-col upload-image"
                                            >
                                                <p
                                                    style={{
                                                        textAlign: "start",
                                                        fontFamily: "POPPINS",
                                                    }}
                                                >
                                                    Upload Item Image
                                                </p>

                                                <Form.Item
                                                    className="form-input-class"
                                                    name="course_image"
                                                // rules={[
                                                //   {
                                                //     required: true,
                                                //     message: "Please upload course image.",
                                                //   },
                                                // ]}
                                                >
                                                    <label
                                                        for="logo-upload"
                                                        className="custom-upload-logo d-flex justify-content-between"
                                                    >
                                                        <span className="custom-input-logo-left d-flex align-items-center">
                                                            <LuUpload className="form-icons" />
                                                            {file ? file?.name : "Upload Item "}
                                                            Image
                                                        </span>
                                                    </label>
                                                    <Input
                                                        style={{ display: "none" }}
                                                        type="file"
                                                        id="logo-upload"
                                                        className="input-box-login upload-image_input"
                                                        placeholder="www.welloffun.com"
                                                        onChange={handleFileChange}
                                                        hidden
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row className="form-row fee-instruction-upload-row">
                                            <Col
                                                md={12}
                                                className="custom-padding fee-instruction-upload-col fee-include"
                                            >
                                                <Form.Item
                                                    name="course_start"
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //         message: "Please select start date!",
                                                    //     },
                                                    // ]}
                                                >
                                                    <DatePicker onChange={onChange} />
                                                </Form.Item>
                                            </Col>

                                            <Col
                                                md={12}
                                                className="custom-padding fee-instruction-upload-col fee-include"
                                            >
                                                <Form.Item
                                                    name=" course_end"
                                                  
                                                >
                                                    <DatePicker onChange={onEndDataChange} />
                                                </Form.Item>
                                            </Col>


                                        </Row>

                                        <Row className="form-row">
                                            <Col md={24} className="input-new-course upload-documents">
                                                <p
                                                    style={{
                                                        textAlign: "start",
                                                        fontFamily: "POPPINS",
                                                    }}
                                                >
                                                    Upload Documents
                                                </p>

                                                <Form.Item
                                                    className="form-input-class"
                                                    name="upload-document"
                                                // rules={[
                                                //   {
                                                //     required: true,
                                                //     message: "Please upload document.",
                                                //   },
                                                // ]}
                                                >
                                                    <label
                                                        for="logo-document"
                                                        className="custom-upload-logo d-flex justify-content-between upload-document-label"
                                                    >
                                                        <span className="custom-input-logo-left d-flex align-items-center">
                                                            <LuUpload className="form-icons" />
                                                            {document
                                                                ? document?.name
                                                                : "Drop or Upload Document and Photos"}
                                                        </span>
                                                    </label>
                                                    <Input
                                                        style={{ display: "none !important" }}
                                                        type="file"
                                                        id="logo-document"
                                                        className="input-box-login upload-document-input"
                                                        placeholder="www.welloffun.com"
                                                        hidden
                                                        onChange={handleFileChangeDoc}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className="form-row">
                                            <Col md={24} className="input-new-course">
                                                <p
                                                    style={{
                                                        textAlign: "start",
                                                        fontFamily: "POPPINS",
                                                    }}
                                                >
                                                    Upload Video and Thumbnail
                                                </p>

                                                <Form.Item
                                                    className="form-input-class"
                                                    name="upload-document"
                                                >
                                                    {/* <Upload {...props}>
                        <Button>Click to Upload</Button>
                      </Upload> */}
                                                    <Button
                                                        className="upload-video-thumbnail"
                                                        onClick={hangleVideoModal}
                                                    >
                                                        Click to Upload Video and thumbnail
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <p className="instructor-detains">
                                            Instructor’s Detail (Optional)
                                        </p>

                                        <Row className="form-row instructor-details-container">
                                            <Col
                                                md={12}
                                                className="input-new-course fee-instruction-upload-col upload-image"
                                            >
                                                <p
                                                    style={{
                                                        textAlign: "start",
                                                        fontFamily: "POPPINS",
                                                    }}
                                                >
                                                    Instructor Name
                                                </p>

                                                <Form.Item
                                                    className="form-input-class"
                                                    name="instructor_name"
                                                // rules={[
                                                //   {
                                                //     required: true,
                                                //     message: "Please enter instructor name.",
                                                //   },
                                                // ]}
                                                >
                                                    <Input
                                                        className="input-box-login"
                                                        placeholder="Enter name here"
                                                        prefix={<CiUser color="#7c8597" />}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} className="input-new-course">
                                                <p
                                                    style={{
                                                        textAlign: "start",
                                                        fontFamily: "POPPINS",
                                                    }}
                                                >
                                                    Work Experience
                                                </p>

                                                <Form.Item
                                                    className="form-input-class"
                                                    name="work_experience"
                                                // rules={[
                                                //   {
                                                //     required: true,
                                                //     message: "Please enter work experience.",
                                                //   },
                                                // ]}
                                                >
                                                    <Input
                                                        type="number"
                                                        className="input-box-login"
                                                        placeholder="Enter Expirence"
                                                        prefix={<LiaCalendarAltSolid color="#7c8597" />}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row className="form-row">
                                            <Col md={12}
                                                className="input-new-course fee-instruction-upload-col upload-image"
                                            >
                                                <p
                                                    style={{
                                                        textAlign: "start",
                                                        fontFamily: "POPPINS",
                                                    }}
                                                >
                                                    Profile Photo
                                                </p>

                                                <Form.Item
                                                    className="form-input-class"
                                                    name="profile_photo"
                                                // rules={[
                                                //   {
                                                //     required: true,
                                                //     message: "Please upload profile photo.",
                                                //   },
                                                // ]}
                                                >
                                                    <label
                                                        for="profile_photo"
                                                        className="custom-upload-logo d-flex justify-content-between"
                                                    >
                                                        <span className="custom-input-logo-left d-flex align-items-center">
                                                            <LuUpload className="form-icons" />
                                                            {profilePhoto ? profilePhoto?.name : "Upload Photo"}
                                                        </span>
                                                    </label>
                                                    <Input
                                                        style={{ display: "none" }}
                                                        type="file"
                                                        id="profile_photo"
                                                        className="input-box-login profile-photo-input"
                                                        placeholder="www.welloffun.com"
                                                        hidden
                                                        onChange={handleProfilePhoto}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} className="input-new-course">
                                                <p
                                                    style={{
                                                        textAlign: "start",
                                                        fontFamily: "POPPINS",
                                                    }}
                                                >
                                                    About
                                                </p>

                                                <Form.Item
                                                    className="form-input-class"
                                                    name="about"
                                                // rules={[
                                                //   {
                                                //     required: true,
                                                //     message: "Please enter about the instructor .",
                                                //   },
                                                // ]}
                                                >
                                                    <Input
                                                        className="input-box-login"
                                                        placeholder="Type something"
                                                        prefix={<BsInfoCircle color="#7c8597" />}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        {/* <div className="course-slot-container mt-5">
                                    <p className="course-slotheading">Course Availability</p>
                                    <div className="row">
                                        {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
                                            <div className="col-md-12 mt-4" key={day}>
                                                <div className="particular-slot-container">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input class-slot-checkbox"
                                                            type="checkbox"
                                                            value={day}
                                                            id={`flexCheck${day}`}
                                                            onChange={(e) => {
                                                                const isChecked = e.target.checked;
                                                                if (!isChecked) {
                                                                    setCourseSlots(courseSlots.filter(slot => slot.day !== day));
                                                                }
                                                            }}
                                                        />
                                                        <label className="form-check-label class-slot-day" htmlFor={`flexCheck${day}`}>
                                                            {day}
                                                        </label>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="start-time-container w-100 mw-50 pe-3">
                                                            <p>Start Time</p>
                                                            <TimePicker
                                                                className="w-100"
                                                                format={'HH:mm'}
                                                                onChange={(time) => handleSlotChange(day, time, courseSlots.find(slot => slot.day === day)?.end_time)}
                                                                disabled={courseSlots.find(slot => slot.day === day)}
                                                            />
                                                        </div>
                                                        <div className="end-time-container w-100 mw-50 ps-3">
                                                            <p>End Time</p>
                                                            <TimePicker
                                                                className="w-100"
                                                                format={'HH:mm'}
                                                                onChange={(time) => handleSlotChange(day, courseSlots.find(slot => slot.day === day)?.start_time, time)}
                                                                disabled={courseSlots.find(slot => slot.day === day)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div> */}



                                        <div className="course-faq-container">
                                            <p className="frequently-asked-que">
                                                Frequently Asked Questions (FAQ’s)❓
                                            </p>
                                            <Button onClick={showModal}>
                                                Add More <FiPlusCircle width={20} height={20} />
                                            </Button>
                                        </div>
                                        {faqs && faqs.length > 0 ? (
                                            <>
                                                <Row className="faq-box-shadow-none">
                                                    {faqs.map((item) => (
                                                        <Col md={12}>
                                                            <Accordion>
                                                                <AccordionSummary
                                                                    expandIcon={<FaArrowRightLong />}
                                                                    aria-controls="panel1-content"
                                                                    id={item.key}
                                                                >
                                                                    {item.question}
                                                                </AccordionSummary>
                                                                <AccordionDetails>{item.answer}</AccordionDetails>
                                                            </Accordion>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </>
                                        ) : (
                                            ""
                                        )}

                                        <div className="cancellation-pollicy-checkbox-container">
                                            <Checkbox
                                                className="cancellation-pollicy-checkbox"
                                                onChange={(e) => setAddCancellationPolicy(e.target.checked)}
                                            >
                                                <span className="cancellation-policy-text">
                                                    Add Refund Policy
                                                </span>
                                            </Checkbox>
                                        </div>
                                        {addCancellationPlicy ? (
                                            <>
                                                <Row className="form-row">
                                                    <Col md={24} className="custom-padding">
                                                        <p
                                                            style={{
                                                                textAlign: "start",
                                                                fontFamily: "POPPINS",
                                                                marginTop: "1rem",
                                                            }}
                                                        >
                                                            Refund Policy
                                                        </p>

                                                        <IoIosHelpCircleOutline onClick={handlehelp} />
                                                        <Form.Item
                                                            className="form-input-class"
                                                            name="refund_policy"
                                                        >
                                                            <TextArea
                                                                className="leave-comment-text-area"
                                                                rows={4}
                                                                placeholder="Add cancellation policy"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </>
                                        ) : (
                                            ""
                                        )}

                                        <div className="free-course-checkbox-container">
                                            <Checkbox
                                                className="free-course-checkbox"
                                                onChange={(e) => setIsFreeCourse(e.target.checked)}
                                            >
                                                <span className="mark-as-free-course-text">
                                                    Mark as Free
                                                </span>
                                            </Checkbox>
                                        </div>
                                        <div className="cancellation-pollicy-checkbox-container">
                                            <Checkbox
                                                className="cancellation-pollicy-checkbox"
                                                onChange={(e) => SetAddTrialDays(e.target.checked)}
                                            >
                                                <span className="cancellation-policy-text">
                                                    Add available for trial
                                                </span>
                                            </Checkbox>
                                        </div>
                                        {addTrialDays ? (
                                            <Col md={12} className="input-new-course">
                                                <Form.Item
                                                    className="form-input-class"
                                                    name="trial_days"
                                                    label="trial days"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please select trial days.",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        className="input-box-login select-max-age"
                                                        placeholder="Select trial days"
                                                    >
                                                        <Select.Option value="10">7 days</Select.Option>
                                                        <Select.Option value="41">30 days</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        ) : (
                                            ""
                                        )}
                                        <div className="cancellation-pollicy-checkbox-container">
                                            {/* <p className="instructor-detains">Mark as Sponsored Course</p> */}
                                            <Checkbox
                                                className="cancellation-pollicy-checkbox"
                                                onChange={handleCheckboxChange}
                                            >
                                                <span className="cancellation-policy-text">
                                                    Is this items is sponsored items ?
                                                </span>
                                            </Checkbox>
                                        </div>
                                        <div className="postal-code-address-container">
                                            <p className="postal-code-address-heading">Add venue address</p>
                                            <div className="row">
                                                <div className="postal-code-container col-md-6 pe-4">
                                                    <input
                                                        type="text"
                                                        className="postal_code"
                                                        value={postalCode}
                                                        onChange={(e) => setPostalCode(e.target.value)}
                                                        placeholder="Enter Postal Code"
                                                    />
                                                    <button className="postal-code-button" type="button" onClick={handlePostalCode}>Add postal Code</button>
                                                </div>
                                                <div className="add-address-container col-md-6 ps-4 pe-4">
                                                    <input
                                                        type="text"
                                                        className="address"
                                                        value={address}
                                                        onChange={(e) => setAddress(e.target.value)}
                                                        placeholder="Enter address"
                                                    />
                                                    <button className="address-button" type="button" onClick={handleAddress}>Add Address</button>
                                                </div>
                                            </div>
                                        </div>
                                        <Form.Item
                                            className="mb-0 add-course-form-item"
                                            wrapperCol={{
                                                offset: 8,
                                                span: 24,
                                            }}
                                        >
                                            <Button htmlType="submit" className="register-button" disabled={addCourse}>
                                                Add Items
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            <Modal
                className="faq_modal"
                title="Basic Modal"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                style={{ backgroundColor: "#fff", margin: "5rem auto" }}
            >
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    onFinish={onFinishFAQ}
                    onFinishFailed={onFinishFailedFAQ}
                    autoComplete="off"
                    className="add-new-course-form"
                >
                    <Row className="form-row instructor-details-container">
                        <Col
                            md={24}
                            className="input-new-courses fee-instruction-upload-col upload-image model-input-p24"
                        >
                            <p
                                style={{
                                    textAlign: "start",
                                    fontFamily: "POPPINS",
                                }}
                            >
                                Question
                            </p>

                            <Form.Item
                                className="form-input-class"
                                name="question"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter question.",
                                    },
                                ]}
                            >
                                <Input
                                    className="input-box-login"
                                    placeholder="Enter name here"
                                    prefix={<FaClipboardQuestion color="#7c8597" />}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={24} className="input-new-courses model-input-p24">
                            <p
                                style={{
                                    textAlign: "start",
                                    fontFamily: "POPPINS",
                                }}
                            >
                                Answer
                            </p>

                            <Form.Item
                                className="form-input-class"
                                name="answer"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter answer .",
                                    },
                                ]}
                            >
                                <Input
                                    className="input-box-login"
                                    placeholder="Enter Expirence"
                                    prefix={<MdOutlineQuestionAnswer color="#7c8597" />}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        className="mb-0"
                        wrapperCol={{
                            offset: 8,
                            span: 24,
                        }}
                    >
                        <Button className="register-button" htmlType="submit">
                            Add FAQ
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>


            <Modal
                style={{ backgroundColor: "#fff" }}
                title="Basic Modal"
                open={isModalOpenThumbnail}
                onOk={handleOkThumbnail}
                onCancel={handleCancelThumbnail}
            >
                <h3 className="video-thumbnail-hear">Upload Video and Thumbnail</h3>
                <div className="upload-video-yhumbnail-container">
                    <Upload
                        listType="text"
                        maxCount={1}
                        accept="video/*"
                        onChange={handleVideo}
                    >
                        <Button className="video-upload">Click to Upload Video</Button>
                    </Upload>
                    <Upload
                        listType="picture"
                        maxCount={1}
                        accept="image/*"
                        onChange={handleThumbnail}
                    >
                        <Button className="thumbnail-upload">
                            Click to Upload Thumbnail
                        </Button>
                    </Upload>
                </div>
                <div className="video-thumbnail-upload-container">
                    <Button
                        className="video-thumbnail-upload-button"
                        onClick={() => handleUploadButton()}
                    >
                        Upload
                    </Button>
                </div>
            </Modal>

            {/* Plan Select Modal */}
            <Modal
                title="Unlock premium exposure by subscribing to our exclusive plan!"
                open={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                centered
                className="planModal"
                okText="Yes"
            >
                <div className="planBody">
                    <p className="upperText">
                        With a subscription, you can list your items in the high-visibility
                        Sponsorship section and reach more students than ever before.
                    </p>
                    <p className="mainText">
                        Would you like to proceed with marking this items as sponsored?
                    </p>
                </div>
            </Modal>

            {/*PaymentForm */}

            <Modal
                title="Payment"
                open={paymentForm}
                onOk={handlePaymentForm}
                onCancel={handlePaymentFormCancle}
                centered
                className="paymentModal"
                okText="Done"
                width={980}
            >
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <StripePaymentForm
                        paymentCourseData={addedCourseData}
                        sponrshipPlan={sponrshipPlan}
                    />
                </Elements>
            </Modal>

            {/* Custom Duration Modal */}
            <Modal
                title="Add Custom Duration"
                open={isDurationModal}
                onOk={handleDurationModalOk}
                onCancel={handleDurationModalCancel}
                centered
                className="planModal"
                okText="Pay"
            >
                <div className="planBody">
                    <p className="upperText text-center">
                        Set a custom item duration to tailor the learning experience to
                        your students' needs. Adjust start and end dates now.
                    </p>
                </div>

                <Row>
                    <Col span={12} >
                        <Input
                            placeholder="Enter days count"
                            type='number'
                            value={days}
                            onChange={(e) => setDays(e.target.value)}
                            onKeyUp={handleDaysChange}
                            style={{ marginBottom: '1rem', width: "90%" }}
                        />
                    </Col>
                    <Col span={12}>
                        <Input
                            placeholder="item amount"
                            value={courseAmount}
                            onChange={(e) => setCourseAmount(e.target.value)}
                            style={{ width: "90%" }}
                        />
                    </Col>
                </Row>
            </Modal>

            {/* Plan Pricing Modal */}
            <Modal
                open={isPlanModalVisible}
                onOk={handlePlanModalOk}
                onCancel={handlePlanModalCancel}
                centered
                className="planPriceModal"
                footer={false}
                width={1140}
            >
                <div className="modalHeader">
                    <div className="textSection">
                        <h4 className="modalTitle">
                            Unlock premium exposure by subscribing to our exclusive plan!
                        </h4>
                        <p className="modalSubTItle">Boost Your item with Sponsorship</p>
                    </div>
                    <div className="btnSection">
                        <Button
                            className="durationButton"
                            onClick={() => openDurationModal()}
                        >
                            Custom Duration
                        </Button>
                    </div>
                </div>
                <div className="modalBody">
                    <div className="row d-flex justify-content-center">
                        {sponsorshipData &&
                            sponsorshipData?.map((sponsorData, index) => {
                                return (
                                    <div className="col-xl-4 col-md-6 mb-4">
                                        <div
                                            className={
                                                index === 0
                                                    ? "cards priceCard starterCard"
                                                    : index === 1
                                                        ? "cards priceCard proCard"
                                                        : "cards priceCard eliteCard"
                                            }
                                        >
                                            <div className="cardBody">
                                                <div className="cardTopSection">
                                                    <h4 className="cardTitle">{sponsorData?.name}</h4>
                                                    <p className="cardSubText">
                                                        {sponsorData?.desciption
                                                            ?.replace(/<\/?p>/g, "")
                                                            .replace(/&nbsp;/g, "\n")}
                                                    </p>
                                                    <div className="cardDetailList">
                                                        {sponsorData?.benefits?.map((benefit) => (
                                                            <div className="cardDetailListItem">
                                                                <span className="icon">
                                                                    <HiMiniCheckBadge />
                                                                </span>
                                                                <span className="dtlText">{benefit}</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="cardBottomSection">
                                                    <button
                                                        type="button"
                                                        className="btn buyNowButton"
                                                        onClick={(e) => handlePayment(sponsorData)}
                                                    >
                                                        Buy Now
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        {/* <div className="col-md-4">
                            <div className="cards priceCard starterCard">
                                <div className="cardBody">
                                    <div className="cardTopSection">
                                        <h4 className="cardTitle">
                                            Starter Sponsor
                                        </h4>
                                        <p className="cardSubText">
                                            Starter Sponsor Plan boosts your course's visibility with priority listing and basic promotional features.
                                        </p>
                                        <div className="cardDetailList">
                                            <div className="cardDetailListItem">
                                                <span className="icon">
                                                    <HiMiniCheckBadge />
                                                </span>
                                                <span className="dtlText">Lorem ipsum dolor sit amet</span>
                                            </div>
                                            <div className="cardDetailListItem">
                                                <span className="icon">
                                                    <HiMiniCheckBadge />
                                                </span>
                                                <span className="dtlText">Lorem ipsum dolor sit amet</span>
                                            </div>
                                            <div className="cardDetailListItem">
                                                <span className="icon">
                                                    <HiMiniCheckBadge />
                                                </span>
                                                <span className="dtlText">Lorem ipsum dolor sit amet</span>
                                            </div>
                                            <div className="cardDetailListItem">
                                                <span className="icon">
                                                    <HiMiniCheckBadge />
                                                </span>
                                                <span className="dtlText">Lorem ipsum dolor sit amet</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cardBottomSection">
                                        <button type="button" className="btn buyNowButton" onClick={(e) => handlePayment(1)}>Buy Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="cards priceCard proCard">
                                <div className="cardBody">
                                    <div className="cardTopSection">
                                        <h4 className="cardTitle">
                                            Pro Sponsor
                                        </h4>
                                        <p className="cardSubText">
                                            Increased visibility and access to advanced promotional tools.
                                        </p>
                                        <div className="cardDetailList">
                                            <div className="cardDetailListItem">
                                                <span className="icon">
                                                    <HiMiniCheckBadge />
                                                </span>
                                                <span className="dtlText">Lorem ipsum dolor sit amet</span>
                                            </div>
                                            <div className="cardDetailListItem">
                                                <span className="icon">
                                                    <HiMiniCheckBadge />
                                                </span>
                                                <span className="dtlText">Lorem ipsum dolor sit amet</span>
                                            </div>
                                            <div className="cardDetailListItem">
                                                <span className="icon">
                                                    <HiMiniCheckBadge />
                                                </span>
                                                <span className="dtlText">Lorem ipsum dolor sit amet</span>
                                            </div>
                                            <div className="cardDetailListItem">
                                                <span className="icon">
                                                    <HiMiniCheckBadge />
                                                </span>
                                                <span className="dtlText">Lorem ipsum dolor sit amet</span>
                                            </div>
                                            <div className="cardDetailListItem">
                                                <span className="icon">
                                                    <HiMiniCheckBadge />
                                                </span>
                                                <span className="dtlText">Lorem ipsum dolor sit amet</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cardBottomSection">
                                        <button type="button" className="btn buyNowButton" onClick={(e) => handlePayment(2)}>Buy Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="cards priceCard eliteCard">
                                <div className="cardBody">
                                    <div className="cardTopSection">
                                        <h4 className="cardTitle">
                                            Elite Sponsor
                                        </h4>
                                        <p className="cardSubText">
                                            Maximum exposure with top-tier promotional benefits and exclusive features.
                                        </p>
                                        <div className="cardDetailList">
                                            <div className="cardDetailListItem">
                                                <span className="icon">
                                                    <HiMiniCheckBadge />
                                                </span>
                                                <span className="dtlText">Lorem ipsum dolor sit amet</span>
                                            </div>
                                            <div className="cardDetailListItem">
                                                <span className="icon">
                                                    <HiMiniCheckBadge />
                                                </span>
                                                <span className="dtlText">Lorem ipsum dolor sit amet</span>
                                            </div>
                                            <div className="cardDetailListItem">
                                                <span className="icon">
                                                    <HiMiniCheckBadge />
                                                </span>
                                                <span className="dtlText">Lorem ipsum dolor sit amet</span>
                                            </div>
                                            <div className="cardDetailListItem">
                                                <span className="icon">
                                                    <HiMiniCheckBadge />
                                                </span>
                                                <span className="dtlText">Lorem ipsum dolor sit amet</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cardBottomSection">
                                        <button type="button" className="btn buyNowButton" onClick={(e) => handlePayment(3)}>Buy Now</button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </Modal>
            <Modal
                title="Standard list"
                open={helpModel}
                onOk={handlehelpOk}
                onCancel={handleHelpCancle}
                centered

            >
                <div>
                    <h3>Eligibility for Refund</h3>
                    <p><strong>Timeframe:</strong> Refunds are available if the request is made within [X] days of purchase or before the course starts.</p>
                    <p><strong>Course Access:</strong> No refunds will be issued after the course has started or if access to course materials has been granted.</p>

                    <h3>Refund Request Process</h3>
                    <p><strong>How to Request:</strong> To request a refund, please contact our support team at [support email/phone number] with your order details and reason for the refund.</p>
                    <p><strong>Required Information:</strong> Include your order number, the date of purchase, and any other relevant information to process your request.</p>

                    <h3>Refund Amount</h3>
                    <p><strong>Full Refund:</strong> A full refund is available if the request is made within [X] days of purchase and before accessing the course materials.</p>
                    <p><strong>Partial Refund:</strong> A partial refund may be available if the request is made after accessing the course but before [specific time or condition].</p>
                    <p><strong>Non-Refundable:</strong> Certain fees, such as administrative or processing fees, may be non-refundable.</p>

                    <h3>Processing Time</h3>
                    <p><strong>Refund Processing:</strong> Refunds will be processed within [X] business days of approval. The time it takes for the refund to appear in your account may vary depending on your payment method and financial institution.</p>

                    <h3>Non-Eligibility for Refund</h3>
                    <p><strong>No Show:</strong> No refunds will be issued for failure to attend the course without prior notice.</p>
                    <p><strong>Content Downloads:</strong> Once downloadable content has been accessed or downloaded, it may not be eligible for a refund.</p>

                    <h3>Cancellation by Provider</h3>
                    <p><strong>Course Cancellation:</strong> If we cancel the course for any reason, a full refund will be issued, or you will be given the option to transfer to a different course.</p>
                    <p><strong>Rescheduling:</strong> If the course is rescheduled, you will be offered the option to attend the rescheduled course or request a refund.</p>

                    <h3>Refund Policy Changes</h3>
                    <p><strong>Policy Updates:</strong> We reserve the right to change this refund policy at any time. Any changes will be communicated to you via email or posted on our website.</p>

                    <h3>Contact Information</h3>
                    <p><strong>Support Contact:</strong> For any questions or concerns regarding the refund policy, please contact us at [support email/phone number].</p>
                </div>

            </Modal>
        </>
    );
}