import React,{useState ,useEffect} from "react";
import * as getServices from "../../services/getServices"
import { useDispatch } from "react-redux";
const TermCondition = () => {
    const dispatch = useDispatch();
    const [pageData, setPageData] = useState();

    useEffect(() => {
        dispatch(getServices?.getPageDetails('term-condition'))
            .then((response) => {
      
                setPageData(response?.page);
            })
            .catch((err) => {
                console.error({ err });
            });
    }, []);
    return (
        <div>
            <div>
                <h1>{pageData?.title}</h1>
            </div>
            <div>
                <h3>{pageData?.summary}</h3>
            </div>

            <p>{pageData?.description}</p>
          
        </div>
    );
}

export default TermCondition;