import React from "react";

const CopyWriteFooter = () => {

    return (
        <div className="footerCopywrite">
            <div className="container">
                <div className="col-md-12">
                    <p className="copyrightText text-center m-0">© Copyright 2024, All Rights Reserved</p>
                </div>
            </div>
        </div>
    );
};

export default CopyWriteFooter;
