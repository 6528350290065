import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAE6EiVn-EahG_yjcsAYXV1EqkPFNHIrPY",
  authDomain: "well-of-fun-6554d.firebaseapp.com",
  projectId: "well-of-fun-6554d",
  storageBucket: "well-of-fun-6554d.appspot.com",
  messagingSenderId: "360051754539",
  appId: "1:360051754539:web:61b9d10e897ee04b4d4c6e",
  measurementId: "G-LDJZ37XMNB"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };
