import React, { useState } from "react";
import { Row, Col, Input, Form, Select, Image, Spin, Button } from "antd";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { CiGlobe } from "react-icons/ci";
import { IoLocationOutline } from "react-icons/io5";
import { BsCloudUpload } from "react-icons/bs";
import { CiImageOn } from "react-icons/ci";
import { BsSuitcaseLg } from "react-icons/bs";
import { FaRegAddressCard } from "react-icons/fa";
import { MdOutlineCategory } from "react-icons/md";
import { CiMail } from "react-icons/ci";
import { DollarSack, HashBox } from "../../../constant/images";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link,
} from "suneditor/src/plugins";

export default function SchoolInformation({
  onData,
  categories,
  subCategories,
  vendorDetails,
  goBack,
}) {
  const [editorContent, setEditorContent] = useState("");
  const SunEditorOpts = {
    showPathLabel: false,
    imageMultipleFile: true,
    imageWidth: "150px",
    imageHeight: "150px",
    height: "auto",
    minHeight: "200px",
    requestHeaders: {
      "X-Sample": "sample",
    },
    plugins: [
      align,
      font,
      fontColor,
      fontSize,
      formatBlock,
      hiliteColor,
      horizontalRule,
      lineHeight,
      list,
      paragraphStyle,
      table,
      template,
      textStyle,
      image,
      link,
    ],
    buttonList: [
      ["undo", "redo"],
      ["font", "fontSize", "formatBlock"],
      ["paragraphStyle"],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor"],
      ["removeFormat"],
      ["align", "horizontalRule", "lineHeight"],
      ["outdent", "indent"],
      ["list"],
      ["table", "link", "image"],
      ["fullScreen", "showBlocks", "preview"],
    ],
    formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
    font: [
      "Arial",
      "Calibri",
      "Comic Sans",
      "Courier",
      "Garamond",
      "Georgia",
      "Impact",
      "Lucida Console",
      "Palatino Linotype",
      "Segoe UI",
      "Tahoma",
      "Times New Roman",
      "Trebuchet MS",
      "Roboto",
    ],
  };

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const onFinish = (values) => {
    onData(values, editorContent);
  };

  const Category = categories.find(
    (category) => (category?.id === vendorDetails?.Vendor?.category) || (category?.id === vendorDetails?.vendor?.category)
  );

  const SubCategory = subCategories.find((subcategory) =>
    subCategories.map(
      (nestSubcategory) => subcategory.id === nestSubcategory.id
    )
  );



  const onFinishFailed = () => { };
  return (
    <Row className="step-one-container">
      <h2 className="vendor-register-step-one-head">
        Organisation Details
      </h2>
      {vendorDetails === undefined ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Form
            className="step-one-form"
            name="basic"
            initialValues={{
              school_name: vendorDetails?.Vendor?.school,
              website_url: vendorDetails?.Vendor?.website_url,
              address: vendorDetails?.Vendor?.address,
              business_type: vendorDetails?.Vendor?.business_type,
              business_identification_type:
                vendorDetails?.Vendor?.business_identification_type,
              business_identification_no:
                vendorDetails?.Vendor?.business_identification_no,
              gst: vendorDetails?.Vendor?.gst,
              category: Category?.id,
              sub_categories: SubCategory?.id,
              email_for_notification: vendorDetails?.Vendor?.email_notification,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Row>
              <Col md={12} className="basic-padding px-3">
                <Form.Item
                  className="form-input-class"
                  name="school_name"
                  label="Organisation Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter organisation name.",
                    },
                  ]}
                >
                  <Input
                    className="input-box-login"
                    placeholder="Enter school name here"
                    prefix={<HiOutlineBuildingOffice className="form-icons" />}
                  />
                </Form.Item>
              </Col>
              <Col md={12} className="basic-padding px-3" >
                <Form.Item
                  className="form-input-class"
                  name="website_url"
                  label="Website URL"
                  rules={[
                    {
                      required: true,
                      message: "Please enter website url.",
                    },
                  ]}
                >
                  <Input
                    className="input-box-login"
                    placeholder="www.welloffun.com"
                    prefix={<CiGlobe className="form-icons" />}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="basic-padding px-3">
                <Form.Item
                  className="form-input-class"
                  name="address"
                  label="Address"
                  rules={[
                    {
                      required: true,
                      message: "Please enter address.",
                    },
                  ]}
                >
                  <Input
                    className="input-box-login"
                    placeholder="Enter address here"
                    prefix={<IoLocationOutline className="form-icons" />}
                  />
                </Form.Item>
              </Col>
              <Col md={12} className="basic-padding px-3">
                <Form.Item
                  className="form-input-class"
                  name="Logo"
                  label="Logo"
                  rules={[
                    {
                      required: true,
                      message: "Please add logo",
                    },
                  ]}
                >
                  <label
                    for="logo-upload"
                    className="custom-upload-logo d-flex justify-content-between"
                  >
                    <span className="custom-input-logo-left d-flex align-items-center">
                      {" "}
                      <CiImageOn className="form-icons" /> Upload Logo
                    </span>{" "}
                    <span className="custom-input-logo-right">
                      <BsCloudUpload className="form-icons" />
                    </span>
                  </label>
                  {/* <Input type='file' id='logo-upload' className="input-box-login" placeholder='www.welloffun.com' prefix={<CiImageOn className='form-icons'/>} suffix={<BsCloudUpload className='form-icons' />} hidden  /> */}
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="basic-padding px-3">
                <Form.Item
                  className="form-input-class"
                  name="business_type"
                  label="Business Type"
                  rules={[
                    {
                      required: true,
                      message: "Please enter business type.",
                    },
                  ]}
                >
                  <Input
                    className="input-box-login"
                    placeholder="Enter business type"
                    prefix={<BsSuitcaseLg className="form-icons" />}
                  />
                </Form.Item>
              </Col>
              <Col md={12} className="basic-padding px-3">
                <Form.Item
                  className="form-input-class"
                  name="business_identification_type"
                  label="Business Identification Type"
                  rules={[
                    {
                      required: true,
                      message: "Please enter business identification type.",
                    },
                  ]}
                >
                  <Input
                    className="input-box-login"
                    placeholder="Enter Identification Type"
                    prefix={<FaRegAddressCard className="form-icons" />}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="basic-padding px-3">
                <Form.Item
                  className="form-input-class"
                  name="business_identification_no"
                  label="Business Identification No."
                  rules={[
                    {
                      required: true,
                      message: "Please enter business type.",
                    },
                  ]}
                >
                  <Input
                    className="input-box-login"
                    placeholder="Enter Identification no."
                    // prefix={<PiHashFill className="form-icons" />}
                    prefix={<Image preview={false} width={24} height={24} src={HashBox} />}
                  />
                </Form.Item>
              </Col>
              <Col md={12} className="basic-padding px-3">
                <Form.Item
                  className="form-input-class"
                  name="gst"
                  label="GST No."
                  rules={[
                    {
                      required: true,
                      message: "Please enter business identification type.",
                    },
                  ]}
                >
                  <Input
                    className="input-box-login"
                    placeholder="Enter GST No."
                    // prefix={<FaSackDollar className="form-icons" />}
                    prefix={<Image preview={false} width={26} height={26} src={DollarSack} />}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="basic-padding px-3">
                <Form.Item
                  className="form-input-class"
                  name="category"
                  label="Select Categories"
                  rules={[
                    {
                      required: true,
                      message: "Please select category.",
                    },
                  ]}
                >
                  <Select
                    className="input-box-login select-category"
                    placeholder="Category"
                    prefix={<MdOutlineCategory />}
                  >
                    {categories &&
                      categories.map((category) => (
                        <Select.Option key={category?.id} value={category?.id}>
                          {category?.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={12} className="basic-padding px-3">
                <Form.Item
                  className="form-input-class"
                  name="sub_categories"
                  label="Select Sub-Categories"
                  rules={[
                    {
                      required: true,
                      message: "Please select Sub-category.",
                    },
                  ]}
                >
                  <Select
                    className="input-box-login sub-select-category"
                    placeholder="Sub-Categories"
                    prefix={<MdOutlineCategory />}
                  >
                    {subCategories &&
                      subCategories.map((subCategory) => (
                        <Select.Option value={subCategory?.id}>
                          {subCategory?.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col md={24} className="basic-padding px-3">
                <Form.Item
                  className="form-input-class"
                  name="email_for_notification"
                  label="Email for Notification"
                  rules={[
                    {
                      required: true,
                      message: "Please enter email.",
                    },
                  ]}
                >
                  <Input
                    className="input-box-login"
                    placeholder="Enter Email to Get Notifications"
                    prefix={<CiMail className="form-icons" />}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col md={24} className="basic-padding px-3">
                <Form.Item
                  className="form-input-class"
                  name="business_identification_type"
                  label="About us"
                  rules={[
                    {
                      required: true,
                      message: "Please enter business identification type.",
                    },
                  ]}
                >
                  <div className="suneditor-outer-new px-0">
                    <SunEditor
                      setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                      setOptions={SunEditorOpts}
                      value={vendorDetails?.Vendor?.about_school}
                      onChange={handleEditorChange}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row className="form-back-next-button">
              <Col md={12} className="text-center">
                <Form.Item
                  className="mb-0"
                  wrapperCol={{
                    offset: 8,
                    span: 24,
                  }}
                >
                  <Button
                    className="go-back-vendor"
                    htmlType="button"
                    onClick={goBack}
                  >
                    Back
                  </Button>
                </Form.Item>
              </Col>
              <Col md={12} className="text-center">
                <Form.Item
                  className="mb-0"
                  wrapperCol={{
                    offset: 8,
                    span: 24,
                  }}
                >
                  <Button className="go-next-vendor" htmlType="submit">
                    Next
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </Row>
  );
}
