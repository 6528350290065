import React, { useEffect, useState } from "react";
import { Image, Tabs, Select, Input, Space, Button } from "antd";
import { BlogNewsLetterCover } from "../../constant/images";
import {
    AllBlogs,
    SpecificBlogs
} from "./blogDetails";
import { CiMail } from "react-icons/ci";
import Header from "../../components/Header";
import Footer from "../../components/Footer/footer";
import * as getServices from "../../services/getServices";
import { useDispatch } from "react-redux";

export default function Blogs() {
    const dispatch = useDispatch()

    const [blogs, setBlogs] = useState([])

    useEffect(() => {
        const fetchBlogLength = async () => {
            try {
                const response = await dispatch(getServices?.getBlogs());
                setBlogs(response?.blogs);
            } catch (error) {
                console.error({ error });
            }
        };

        fetchBlogLength()
    }, [])

    const [order, setOrder] = useState("asc")
    const uniqueCategories = [...new Set(blogs.map(blog => blog.category.name))];

    const items = uniqueCategories.map((category, index) => ({
        key: (index + 2).toString(),
        label: category,
        children: <SpecificBlogs order={order} blogs={blogs} category={category} />,
    }));

    items.unshift({
        key: "1",
        label: "All Blogs",
        children: <AllBlogs order={order} />,
    });



    const dropDownMenu = [
        {
            value: "asc",
            label: "Latest",
        },
        {
            value: "desc",
            label: "Older",
        },
    ];

    const onChange = (key) => {
        // console.log(key);
    };

    const handleChange = (value) => {
        setOrder(value)
    };



    return (
        <>
            <Header />
            <div className="blog-container">
                <div className="blog-banner-container">
                    <div className="blog-cover-text">
                        <h2 className="blog-cover-text-heading">Blogs</h2>
                        <p className="blog-cover-text-subhead">
                            Explore Our Comprehensive Blogs
                        </p>
                    </div>
                </div>
                <div className="blogs-tab-container">
                    <div className="tabsRow">
                        <Tabs
                            defaultActiveKey="1"
                            className="blogs-tab"
                            items={items}
                            onChange={onChange}
                        />
                        <div className="dropdownSection">
                            <Select
                                defaultValue="Sort by"
                                style={{
                                    width: 120,
                                }}
                                onChange={handleChange}
                                options={dropDownMenu}
                            />
                        </div>
                    </div>
                </div>
                <div className="blog-news-letter-container">
                    <Image
                        preview={false}
                        className="blog-news-letter-image"
                        src={BlogNewsLetterCover}
                    />
                    <div className="news-letter-text-input">
                        <h2 className="blog-news-join">Join Our Newsletter</h2>
                        <p className="blog-news-sing-up">Sign up for latest lessions!</p>
                        <div className="blogs-input-news-letter-container">
                            <Space.Compact className="news-letter-space">
                                <Input
                                    className="news-letter-input"
                                    placeholder="Email address"
                                    prefix={<CiMail className="blog-news-letter-mail-icon" />}
                                />
                                <Button
                                    className="blogs-news-letter-sign-up-button"
                                    type="primary"
                                >
                                    Sign Up
                                </Button>
                            </Space.Compact>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
