import React,{useState,useEffect} from "react";
import { Row, Col, Input, Form, Checkbox, Image, Spin, Button} from "antd";
import "suneditor/dist/css/suneditor.min.css";
import { CiBank } from "react-icons/ci";
import { PiNoteLight } from "react-icons/pi";
import { ImUsers } from "react-icons/im";
import { HashBox } from "../../../constant/images";
import * as getServices from "../../../services/getServices";
import {useDispatch} from "react-redux";

export default function BankingInformations({ onData, vendorDetails, goBack }) {


  const dispatch = useDispatch()

  const [redirectLink, setRedirectLink] = useState("")
  sessionStorage.setItem("user_id",   vendorDetails?.user_id
    ? vendorDetails?.user_id
    : vendorDetails?.User?.id
    ? vendorDetails?.User?.id
    : vendorDetails?.user?.id);


  useEffect(()=>{
    dispatch(getServices.getStripeOnboardLink()).then((response)=>{
      setRedirectLink(response?.data)
     }).catch((err)=>{
       console.log({err})
     })
  },[])

  const handleClick = () =>{
    window.location.href = redirectLink;
  }
  return (
    <div className="stripe-payment-register-container">
    <div className="stripe-payment-text-container">
      <p className="stripe-payment-heading">Get Started with Stripe: Connect & Receive Payments Directly!</p>
      <p className="stripe-payment-text">Ready to streamline your payment process and receive funds directly in your bank account? Click 'Get Started with Stripe' to connect with Stripe now and enjoy seamless transactions and direct deposits</p>
    </div>
    <div className="connect-button-container">
      <button onClick={handleClick} disabled={redirectLink === "" ? true : false} className="connect-button">Connect</button>
    </div>
  </div>
  );
}
