import Http from "../Http";
import { DEMO_URL } from "../Helper";

export function deleteCourse(value) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.delete(DEMO_URL + `/api/course-delete/${value}`, value)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }

  export function updateCourse(courseId,value) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.post(DEMO_URL + `/api/course/${courseId}`, value)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }

  export function updateProfileImage(value) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.post(DEMO_URL + "/api/update-profile", value)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }

  export function deleteUserReview(value) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.delete(DEMO_URL + `/api/reviews/${value}`)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }

  export function editUserReview(reviewID,value) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.put(DEMO_URL + `/api/reviews/${reviewID}`,value)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }

  export function deleteCourseUser(value) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.delete(DEMO_URL + `/api/delete-user-booking/${value}`)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }

  export function changePassword(value) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.post(DEMO_URL + "/api/change-password", value)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }


  export function updateClass(class_id,values) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.post(DEMO_URL + `/api/edit-classes/${class_id}`, values)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }


  export function deleteClass(value) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.delete(DEMO_URL + `/api/delete-classes/${value}`)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }
  export function cloneCourse(value) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.post(DEMO_URL + `/api/clone-course `,value)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }
  export function customFilter(value) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.post(DEMO_URL + `/api/course-custom-filters `,value)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }