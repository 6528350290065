import React, { useState, useEffect } from "react";
import { Row, Col, Input, Button, Space, Image } from "antd";
import Sidebar from "../../../components/Sidebar/sidebar";
import { RewardTrophy } from "../../../constant/images";
import TopNavBar from "../../../components/topNavbar";
import * as getServices from "../../../services/getServices";
import { useDispatch, useSelector } from "react-redux"
import CopyWriteFooter from "../../../components/Footer/copywriteFooter"

export default function MyRewards() {

  const userData = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();

  const [paymentDetails, setPaymentDetails] = useState([]);
  const [totalPoints, setTotalPoints] = useState(0);
  const [latestReward, setLatestReward] = useState(null);

  useEffect(() => {
    dispatch(
      getServices.getPaymentDetails(
        userData?.user_id ? userData?.user_id : userData?.id
      )
    )
      .then((response) => {
        setPaymentDetails(response?.payments);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dispatch, userData])



  useEffect(() => {
    // Calculate total points
    const points = paymentDetails.reduce((sum, payment) => {
      return payment?.reward ? sum + payment?.reward?.points : sum;
    }, 0);
    setTotalPoints(points);

    // Find the latest reward
    const latest = paymentDetails.reduce((latest, payment) => {
      if (!payment?.reward) return latest;
      return new Date(payment?.reward?.created_at) > new Date(latest?.reward?.created_at) ? payment : latest;
    }, paymentDetails[0]);
    setLatestReward(latest?.reward);
  }, [paymentDetails]);

  // const columns = [
  //   {
  //     title: "S.NO",
  //     dataIndex: "S.NO",
  //     key: "S.NO",
  //     render: (text) => <a className="s-no" href="/">{text}</a>,
  //   },
  //   {
  //     title: "Date",
  //     dataIndex: "Date",
  //     key: "Date",
  //   },
  //   {
  //     title: "Description",
  //     dataIndex: "Description",
  //     key: "Description",
  //   },
  //   {
  //     title: "Points",
  //     dataIndex: "Points",
  //     key: "Points",
  //   },
  //   {
  //     title: "Remarks",
  //     dataIndex: "Remarks",
  //     key: "Remarks",
  //   },
  // ];


  // const data = [
  //   {
  //     "S.NO": "01",
  //     Date: "02 Apr 2024",
  //     Description: "Desc",
  //     Points: "30 Pts",
  //     Remarks: <p style={{padding:"5px 20px",backgroundColor:"#DA073433", borderRadius:"30px", color:"#DA0734", width:"43%"}}>Failed</p>,
  //   },
  //   {
  //     "S.NO": "02",
  //     Date: "05 Apr 2024",
  //     Description: "Desc",
  //     Points: "170 Pts",
  //     Remarks: <p style={{padding:"5px 20px",backgroundColor:"#1EAA3933", borderRadius:"30px", color:"#1EAA39", width:"40%"}}>Done</p>,
  //   },

  // ];

  return (
    <>
      <TopNavBar />
      <Row>
        <Col xl={4} className="d-xl-block d-md-none">
          <Sidebar />
        </Col>
        <Col xl={20} md={24}>
          <div className="mainContent">
            <Row className="my-rewards-container-main">
              <Col className="rewards-good" md={12}>
                <p className="reward-my-reward">My Rewards</p>
                <p className="good-morning-name">Good Morning {userData?.name
                  ? userData?.name
                  : userData?.name
                    ? userData?.name
                    : userData?.User?.name}!</p>
              </Col>
              <Col className="redeem-button-top" md={12}>
                <button type="button" className="btn btn-success redeem-button">
                  Redeem Now
                </button>
              </Col>
            </Row>
            <Row className="my-reward-content">
              <Col className="input-container" md={24}>
                <p className="voucher-code">Have an voucher code?</p>
                <Space.Compact className="input-my-reward">
                  <Input defaultValue="Enter voucher/Coupon code here" />
                  <Button className="input-button-my-reward" type="primary">Apply Coupon</Button>
                </Space.Compact>
              </Col>
              <Row className="reward-point-section-earned">
                <Col className="reward-col-section" md={8}>
                  <p className="two-hund">{latestReward?.points}</p>
                  <p className="rew-point">Reward Points Earned</p>
                  <button type="button" class="btn btn-success redeem-button">
                    Redeem Now
                  </button>
                </Col>
                <Col className="total-reward-trphy-image" md={8}>
                  <p className="total">You have total</p>
                  <p className="reward-points">{totalPoints} Reward Points</p>
                </Col>
                <Col className="total-reward-trphy-image" md={8}>
                  <Image
                    src={RewardTrophy}
                    alt="Not Found"
                    className="img-fluid"
                    preview={false}
                  />
                </Col>
              </Row>
              {/* <Row>
            <Col className="table-transaction-history" md={24}>
            <p className="transaction-history">Transition History</p>
              <Table
              className="table"
                pagination={{ pageSize: 2 }}
                columns={columns}
                dataSource={data}
              />
            </Col>
          </Row> */}
            </Row>
          </div>
          <CopyWriteFooter />
        </Col>
      </Row>
    </>
  );
}
