import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Input, Form, Select, Button } from "antd";
import { MdOutlineCategory } from "react-icons/md";
import { CiMail } from "react-icons/ci";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link,
} from "suneditor/src/plugins";

export default function RegistrationStepThree({
  onData,
  additionalInfo,
  categories,
  subCategories,
  goBack,
}) {
  const SunEditorOpts = {
    showPathLabel: false,
    imageMultipleFile: true,
    imageWidth: "150px",
    imageHeight: "150px",
    height: "auto",
    minHeight: "200px",
    requestHeaders: {
      "X-Sample": "sample",
    },
    plugins: [
      align,
      font,
      fontColor,
      fontSize,
      formatBlock,
      hiliteColor,
      horizontalRule,
      lineHeight,
      list,
      paragraphStyle,
      table,
      template,
      textStyle,
      image,
      link,
    ],
    buttonList: [
      ["undo", "redo"],
      ["font", "fontSize", "formatBlock"],
      ["paragraphStyle"],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor"],
      ["removeFormat"],
      ["align", "horizontalRule", "lineHeight"],
      ["outdent", "indent"],
      ["list"],
      ["table", "link", "image"],
      ["fullScreen", "showBlocks", "preview"],
    ],
    formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
    font: [
      "Arial",
      "Calibri",
      "Comic Sans",
      "Courier",
      "Garamond",
      "Georgia",
      "Impact",
      "Lucida Console",
      "Palatino Linotype",
      "Segoe UI",
      "Tahoma",
      "Times New Roman",
      "Trebuchet MS",
      "Roboto",
    ],
  };

  const editorRef = useRef(null);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [selectedChildCategory, setSelectedChildCategory] = useState(null);
  const [childCategories, setChildCategories] = useState();
  const [editorContent, setEditorContent] = useState("");
  const onFinish = (values) => {
    onData(values, editorContent);
  };

  const onFinishFailed = () => {
    // console.info("finish failed");
  };

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  useEffect(() => {
    if (additionalInfo?.refund_policy && editorRef.current) {
      editorRef.current.setContents(additionalInfo.refund_policy);
    }
  }, [additionalInfo?.refund_policy]);

  const Category = (category_id) => {
    const category = categories?.find(
      (category) => category?.id === category_id
    );
    return category?.name;
  };

  const SubCategory = (subcategory_id) => {
    const subcategory = subCategories?.find(
      (subCategories) => subCategories?.id === subcategory_id
    );
    return subcategory?.name;
  };

  const handlecategory = (value) => {
    setSelectedCategory(value);
    setSelectedSubCategory(null);
    setFilteredSubCategories([]);
  };

  useEffect(() => {
    if (selectedCategory) {
      const filtered = categories.flatMap((category) =>
        category.child_category.filter(
          (item) => item.parent_id === selectedCategory
        )
      );

      const childCategoryOptions = filtered.map((data) => ({
        name: data.name,
        id: data.id,
      }));

      const subCategoryOptions = filtered.flatMap(
        (child) =>
          child.sub_categories?.map((subData) => ({
            name: subData.name,
            id: subData.id,
            category_id: subData.category_id,
          })) || []
      );

      if (childCategoryOptions.length > 0) {
        setChildCategories(childCategoryOptions);
      }

      setFilteredSubCategories(
        subCategoryOptions.filter(
          (item) => item.category_id === selectedChildCategory
        )
      );
      setSelectedSubCategory(null);
    }
  }, [categories, selectedCategory, selectedChildCategory]);

  return (
    <Row className="step-one-container">
      <h2 className="vendor-register-step-one-head">Additional Information</h2>
      <Form
        className="step-one-form"
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          category: Category(additionalInfo?.category),
          sub_categories: SubCategory(additionalInfo?.sub_categories),
          email_for_notification: additionalInfo?.email_for_notification,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row>
          <Col md={12} className="custom-padding">
            <p
              style={{
                textAlign: "start",
                fontFamily: "POPPINS",
              }}
            >
              Select Category
            </p>

            <Form.Item
              className="form-input-class"
              rules={[
                {
                  required: true,
                  message: "Please select category.",
                },
              ]}
            >
              <Select
                className="input-box-login select-category"
                placeholder="Category"
                prefix={<MdOutlineCategory />}
                onChange={(value) => handlecategory(value)}
              >
                {categories &&
                  categories.map((category) => (
                    <Select.Option key={category.id} value={category.id}>
                      {category.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} className="custom-padding">
            <p
              style={{
                textAlign: "start",
                fontFamily: "POPPINS",
              }}
            >
              Select Sub Categories
            </p>

            <Form.Item
              className="form-input-class"
              name="child_categories"
              rules={[
                {
                  required: true,
                  message: "Please select  child category.",
                },
              ]}
            >
              <Select
                className="input-box-login sub-select-category"
                placeholder="Sub-Categories"
                prefix={<MdOutlineCategory />}
                onChange={(value) => setSelectedChildCategory(value)}
                disabled={!selectedCategory}
              >
                {childCategories &&
                  childCategories.map((childCategory) => (
                    <Select.Option
                      key={childCategory.id}
                      value={childCategory.id}
                    >
                      {childCategory.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} className="custom-padding">
            <p
              style={{
                textAlign: "start",
                fontFamily: "POPPINS",
              }}
            >
              Select Child Categories
            </p>

            <Form.Item
              className="form-input-class"
              name="sub_categories"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please select sub-category.",
              //   },
              // ]}
            >
              {/* as per the data this is child-Categories */}
              <Select
                className="input-box-login sub-select-category"
                placeholder="Child-Categories"
                prefix={<MdOutlineCategory />}
                value={selectedSubCategory}
                onChange={(value) => setSelectedSubCategory(value)}
                disabled={!selectedCategory}
              >
                {filteredSubCategories &&
                  filteredSubCategories.map((subCategory) => (
                    <Select.Option
                      key={subCategory.id}
                      value={subCategory.id}
                      disabled={selectedSubCategory === subCategory.id}
                    >
                      {subCategory.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col md={24} className="custom-padding">
            <p
              style={{
                textAlign: "start",
                fontFamily: "POPPINS",
              }}
            >
              Email for Notification
            </p>

            <Form.Item
              className="form-input-class"
              name="email_for_notification"
              rules={[
                {
                  required: true,
                  message: "Email is not valid",
                  type: "email",
                },
              ]}
            >
              <Input
                className="input-box-login"
                placeholder="Enter Email to Get Notifications"
                prefix={<CiMail className="form-icons" />}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col md={24} className="custom-padding">
            <p
              style={{
                textAlign: "start",
                fontFamily: "POPPINS",
              }}
            >
              Refund & Cancellation policy
            </p>

            <Form.Item
              className="form-input-class"
              name="refund_policy"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please enter business identification type.",
              //   },
              // ]}
            >
              <div className="suneditor-outer-new px-0">
                <SunEditor
                  getSunEditorInstance={(editor) => {
                    editorRef.current = editor;
                  }}
                  setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                  setOptions={SunEditorOpts}
                  onChange={handleEditorChange}
                />
              </div>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Form.Item className="mb-0" wrapperCol={{ offset: 8, span: 24 }}>
              <Button
                className="register-button button-register go-back-vendor"
                htmlType="button"
                onClick={goBack} // Call goBack function
              >
                Back
              </Button>
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item className="mb-0" wrapperCol={{ offset: 8, span: 24 }}>
              <Button
                className="register-button button-register next-button"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Row>
  );
}
