import { Col, Row, Image, Form, Input, Checkbox, Button, Spin } from "antd";
import React, { useState, useEffect } from "react";
import { Logo } from "../../constant/images";
import { CiMail, CiLock } from "react-icons/ci";
import * as UserServices from "../../services/userServices";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import {
    setUserData,
    setUserLoggedIn,
    setVendorLoggedIn,
    setToken,
} from "../../store/actions/user";
import { LoadingOutlined } from "@ant-design/icons";
import { getToken } from "firebase/messaging";
import {messaging} from "../../Utils/firebaseUtils";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/index";
// import {requestFCMToken} from "../../Utils/firebaseUtils"

export default function Login() {
    const [fcmToken, setFcmToken] = useState(null);
    const [form] = Form.useForm(); // Form reference
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState("");
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const [loader, setLoader] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    let previoursPage = localStorage.getItem("coursePage");

    useEffect(() => {
        const storedEmail = localStorage.getItem('email') || sessionStorage.getItem('email');
        const storedPassword = localStorage.getItem('password') || sessionStorage.getItem('password');

        if (storedEmail && storedPassword) {
            form.setFieldsValue({
                email: storedEmail,
                password: storedPassword,
            });
            setRememberMe(true);
        }
    }, [form]);

    const VAPID_KEY = process.env.REACT_APP_VAPID_KEY;

    async function requestPermission() {
        try {
            const permission = await Notification.requestPermission();
            if (permission === "granted") {
                const token = await getToken(messaging, {
                    vapidKey: VAPID_KEY,
                });
                return token;
            } else {
              
                return;
            }
        } catch (error) {
            console.error("Error requesting permission:", error);
            return;
        }
    }

    const onFinish = async (values) => {
        setLoader(true);
    
        // Store email and password based on "remember me" choice
        if (rememberMe) {
            localStorage.setItem('email', values.email);
            localStorage.setItem('password', values.password);
        } else {
            sessionStorage.setItem('email', values.email);
            sessionStorage.setItem('password', values.password);
        }
    
        try {
            // Request FCM token
            const token = await requestPermission();
    
            // Prepare form data with FCM token
            const formData = {
                email: values.email,
                password: values.password,
                fcm_token: token,
            };
            dispatch(UserServices.login(formData))
                .then((response) => {
                    dispatch(setToken(response?.token))
                    localStorage.setItem('token', response.token);
                    setLoader(false);
    
                    // Handle the different response statuses
                    if (response.status === 403) {
                        Swal.fire("Please verify your email.", "error");
                    } else if (response.status === 200) {
                        // Dispatch user data and show success alert
                        dispatch(setUserData(response));
                        Swal.fire(
                            "Login Successful!",
                            "You have successfully logged in with Well of Fun!",
                            "success"
                        ).then(() => {
                            // Handle navigation based on user or vendor status
                            if (response?.vendor_id) {
                                localStorage.setItem("activeRoute", "/vendor/dashboard");
                                dispatch(setVendorLoggedIn(true));
                                if (previoursPage) {
                                    localStorage.setItem("previoursPage", false);
                                    navigate(-1);
                                } else {
                                    navigate("/vendor/dashboard");
                                }
                            } else {
                                dispatch(setUserLoggedIn(true));
                                localStorage.setItem("activeRoute", "/user/dashboard");
                                if (previoursPage) {
                                    localStorage.setItem("previoursPage", false);
                                    navigate(-1);
                                } else {
                                    navigate("/user/dashboard");
                                }
                            }
                        });
                    } else {
                        Swal.fire("Verification!", "You need to verify your email.", "info");
                    }
                })
                .catch((err) => {
                    console.error({ err });
                    setLoader(false);
                    Swal.fire("Error", "Login failed, please try again.", "error");
                });
        } catch (error) {
            console.error("Error getting FCM token: ", error);
            setLoader(false);
            Swal.fire("Error", "Failed to retrieve FCM token.", "error");
        }
    };
    

    const onFinishFailed = () => {
     
    };

    const handleForgetPassword = () => {
        navigate("/forgot-password");
    };

    return (
        <>
        <Header/>
            <div className="login-container">
                <div className="login-div">
                    <Row className="d-flex align-items-center justify-content-center form-row-section">
                        <Col className="text-center left-section tab-none" md={12}>
                            <div className="login-banner">
                                <div className="login-image">
                                    <Image
                                        style={{ width: "150px", height: "122.73px" }}
                                        src={Logo}
                                        alt="Not Found"
                                        className="img-fluid"
                                        preview={false}
                                    />
                                    <div className="login-image-heading">
                                        <h3>Welcome to</h3>
                                        <h3>Well Of Fun</h3>
                                    </div>
                                    <div className="login-image-description">
                                        <p className="text-center">
                                            "Unlocking Knowledge, Empowering Minds: Welcome to WELLOFFUN - Your Gateway to Excellence in Learning!"
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={12} className="right-section">
                            <div className="login-page">
                                <div className="login-page-header">
                                    <h3
                                        style={{
                                            textAlign: "start",
                                            color: "white",
                                            fontFamily: "POPPINS",
                                        }}
                                    >
                                        Log In
                                    </h3>
                                    {errorMessage && (
                                        <div style={{ color: "red" }}>{errorMessage}</div>
                                    )}
                                </div>

                                <Form
                                    form={form}  // Pass the form reference here
                                    name="basic"
                                    labelCol={{
                                        span: 8,
                                    }}
                                    wrapperCol={{
                                        span: 24,
                                    }}
                                    style={{
                                        maxWidth: 600,
                                    }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                >
                                    <p
                                        style={{
                                            textAlign: "start",
                                            color: "white",
                                            fontFamily: "POPPINS",
                                        }}
                                    >
                                        Email address
                                    </p>
                                    <Form.Item
                                        className="form-input-class"
                                        name="email"
                                        rules={[
                                            {
                                                validator: (_, value) => {
                                                    if (!value) {
                                                        return Promise.reject(
                                                            new Error("Email is required")
                                                        );
                                                    }
                                                    const trimmedValue = value.trim();
                                                    if (trimmedValue === "") {
                                                        return Promise.reject(
                                                            new Error("Spaces are not allowed")
                                                        );
                                                    }
                                                    if (!emailRegex.test(trimmedValue)) {
                                                        return Promise.reject(
                                                            new Error("Please enter a valid email address")
                                                        );
                                                    }
                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                    >
                                        <Input
                                            prefix={<CiMail color="#667085" />}
                                            placeholder="Johndoe@gmail.com"
                                            className="input-box-login"
                                        />
                                    </Form.Item>
                                    <p
                                        style={{
                                            textAlign: "start",
                                            color: "white",
                                            fontFamily: "POPPINS",
                                        }}
                                    >
                                        Password
                                    </p>
                                    <Form.Item
                                        className="form-input-class mb-1"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Password is required",
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            prefix={<CiLock color="#667085" />}
                                            placeholder="johndoe123"
                                            className="input-box-login"
                                        />
                                    </Form.Item>
                                    <div className="remember-forget">
                                    <Form.Item
                                        className="mb-1"
                                        name="remember"
                                        valuePropName="checked"
                                        wrapperCol={{
                                            offset: 0,
                                            span: 24,
                                        }}
                                    >
                                        <Checkbox
                                            className="form-input-class"
                                            style={{ color: "white" }}
                                            onChange={(e) => setRememberMe(e.target.checked)}
                                            checked={rememberMe}
                                        >
                                            Remember me
                                        </Checkbox>
                                    </Form.Item>
                                    <p className="forgetPassword"
                                            onClick={handleForgetPassword}
                                            style={{
                                                textAlign: "start",
                                                color: "white",
                                                fontFamily: "poppins",
                                                cursor: "pointer",
                                            }}
                                        >
                                            Forgot password?
                                        </p>
                                        </div>

                                    <Form.Item>
                                        <Button
                                            htmlType="submit"
                                            className="login-button"
                                            disabled={loader}
                                        >
                                            {loader ? (
                                                <Spin
                                                    indicator={
                                                        <LoadingOutlined
                                                            style={{
                                                                fontSize: 24,
                                                                color: "white",
                                                            }}
                                                            spin
                                                        />
                                                    }
                                                />
                                            ) : (
                                                "Log In"
                                            )}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <Footer/>
        </>
    );
}
