import React, { useState, useEffect, useRef } from "react";
import {
    Button,
    Table,
    Popover,
    Modal,
    Form,
    Row,
    Col,
    Select,
    Switch,
    Input,
    Image,
    Rate,
    Checkbox,
    Tabs,
    DatePicker,
    Spin,
    TimePicker,
    Radio,
    Pagination,
    Upload
} from "antd";
import moment from 'moment';
import { LiaClone } from "react-icons/lia";
import { LoadingOutlined } from "@ant-design/icons";
import Sidebar from "../../components/Sidebar/sidebar";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoEyeOutline } from "react-icons/io5";
import { FaRegEyeSlash } from "react-icons/fa6";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import TopNavBar from "../../components/topNavbar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as getServices from "../../services/getServices";
import * as updateServices from "../../services/updateServices";
import { LuUpload } from "react-icons/lu";
import { CiUser } from "react-icons/ci";
import { LiaCalendarAltSolid } from "react-icons/lia";
import { BsInfoCircle } from "react-icons/bs";
import { FaArrowRightLong } from "react-icons/fa6";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { MdOutlineCategory } from "react-icons/md";
import { BsBarChart } from "react-icons/bs";
import { CiDollar } from "react-icons/ci";
import { BsFileCode } from "react-icons/bs";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { FaCopy } from "react-icons/fa";
import { RiGraduationCapFill } from "react-icons/ri";
import { BsFillBarChartFill } from "react-icons/bs";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import * as uploadService from "../../services/uploadServices";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { SampleCourseCSV } from "../../constant/files"
import CopyWriteFooter from "../../components/Footer/copywriteFooter";

export default function MyCourses() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const vendorData = useSelector((state) => state.user.userData);
    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const [nestedForm] = Form.useForm();
    const [courses, setCourses] = useState([]);
    const [categories, setCategories] = useState();
    const [subCategories, setSubCategories] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isModalOpenPreview, setIsModalOpenPrevies] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [selectedUpdateRecord, setSelectedUpdateRecord] = useState(null);
    const [addCancellationPlicy, setAddCancellationPolicy] = useState(false);
    const [wsqChecked, setWsqChecked] = useState(true);
    const [filterData, setFilterData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 5;
    const [gstChecked, setGstChecked] = useState(false);
    const [updateButton, setUpdateButton] = useState(false);
    const [file, setFile] = useState(null);
    const [document, setDocument] = useState(null);
    const [classes, setClasses] = useState([]);
    const [loader, setLoader] = useState(false);
    const [disabledValue, setDisabledValue] = useState(null);
    const timePickerRef = useRef();
    const [disabledHours, setDisabledHours] = useState([]);
    const [bulkuploadfile, setbulkuploadFile] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const [selectedChildCategory, setSelectedChildCategory] = useState('');
    const [filteredSubCategories, setFilteredSubCategories] = useState([]);
    const [childCategories, setChildCategories] = useState('');
    const totalItems = (filterData && filterData.length > 0) ? filterData.length : courses.length;
    const sortedCourses = (filterData?.length > 0 ? filterData : courses).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, totalItems);
    const displayedItems = sortedCourses.slice(startIndex, endIndex);


    const handleChangePage = (page) => {
        setCurrentPage(page);
    };
    const [courseFormValue, setCourseFormValue] = useState({
        title: "",
        category: "",
        sub_category: "",
        courseStatus: "",
        classLevel: "",
        classSize: "",
        minAge: "",
        maxAge: "",
        aboutCourse: "",
        feeIncluded: "",
        specialInstruction: "",
        uploadImage: file,
        uploadDocument: document,
        instructorName: "",
        workExperience: "",
        profilePhoto: "",
        about: "",
        faqs: selectedRecord?.vendor_faq,
    });
    const { TextArea } = Input;
    let selectedHourData = [];
    let selectedDay;

    const showModal = (record) => {
        const data = courses.filter((id) => id.id === record.id)
        data.map((item) => {
            form.setFieldsValue({
                title: item.title,
                category: item.category_id,
                sub_categories: item.sub_category_id,
                class_level: item.class_level,
                class_size: item.class_size,
                min_age: item.min_age,
                max_age: item.max_age,
                about_this_course: item.about_course,
                fee_include: item.fee,
                special_instruction: item.special_instruction,
                instructor_name: item.instructor_name,
                work_experience: item.work_experience,
                profile_photo: item.profile_photo,
                about: item.about,
            });
        })
        setUpdateButton(false);
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setSelectedRecord(null);
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setSelectedRecord(null);
        setIsModalOpen(false);
    };
    const handleupdateCourseCancel = () => {
        setIsUpdateModalOpen(false);
    }
    const showModalPreview = () => {
        setUpdateButton(true);
        setSelectedRecord(selectedRecord);
        // setIsModalOpen(false);
        setIsModalOpenPrevies(true);
    };
    const handleOkPreview = () => {
        setSelectedRecord(null);
        setIsModalOpenPrevies(false);
    };
    const handleCancelPreview = () => {
        setSelectedRecord(null);
        setIsModalOpenPrevies(false);
    };

    const handleWsqChange = (checked) => {
        setWsqChecked(checked);
        if (checked) {
            setGstChecked(false);
        }
    };
    const handleGstChange = (checked) => {
        setGstChecked(checked);
        if (checked) {
            setWsqChecked(false);
        }
    };

    const getCourse = () => {
        dispatch(
            getServices?.getCourseByVendorID(
                vendorData?.vendor?.id
                    ? vendorData?.vendor?.id
                    : vendorData?.vendor_id
                        ? vendorData?.vendor_id
                        : vendorData?.Vendor?.id
            )
        ).then((response) => {

            setCourses(response?.courses);
            setLoader(false);
        }).catch((err) => {
     
            setLoader(false);
        });
    }
    useEffect(() => {

        setLoader(true);

        getCourse()

        dispatch(getServices.getCategories())
            .then((response) => {

                console.log(response?.categories,'response?.categories')
                const transformData = response?.categories.map((category) => {
                    return {
                        value: category.id,
                        label: category.name,
                    }
                })
                setCategories(response?.categories);
                // setCategories(transformData);
            })
            .catch((err) => {
                console.log({ err });
            });

        dispatch(getServices.getClasses())
            .then((response) => {
                setClasses(response?.classes);
            })
            .catch((err) => {
                console.log({ err });
            });
        dispatch(getServices.getSubCategories())
            .then((response) => {
                setSubCategories(response?.subcategories);
            })
            .catch((err) => {
                console.log({ err });
            });
    }, [dispatch, vendorData?.vendor?.id]);
console.log(categories,'subbbbbbbbbb')
    const handlecategory = (value) => {
        setSelectedCategory(value);
        setSelectedSubCategory(null);
        setFilteredSubCategories([]);
    };
    useEffect(() => {
        if (selectedCategory) {
            const filtered = categories.flatMap((category) =>
                category.child_category.filter(
                    (item) => item.parent_id === selectedCategory
                )
            );

            const childCategoryOptions = filtered.map((data) => ({
                name: data.name,
                id: data.id,
            }));

            const subCategoryOptions = filtered.flatMap((child) => {
                console.log(child, 'child'); // Log the child object
                return child.sub_categories?.map((subData) => ({
                    name: subData.name,
                    id: subData.id,
                    category_id: subData.parent_id,
                })) || []; // Return the mapped array or an empty array
            });

            if (childCategoryOptions.length > 0) {
                setChildCategories(childCategoryOptions);
            }

            setFilteredSubCategories(
                subCategoryOptions.filter(
                    (item) => item.category_id === selectedChildCategory
                )
            );
            setSelectedSubCategory(null);
        }
    }, [categories, selectedCategory, selectedChildCategory]);

    const deleteCourse = (record) => {

        // confirmation to delete course 
        Swal.fire({
            title: "Delete Item",
            text: "Are you sure you want to delete?",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
        })
            .then((result) => {
                if (result.isConfirmed) {
                    // if confirm to delete course  then run api 
                    dispatch(updateServices?.deleteCourse(record?.id))
                        .then((response) => {
                            Swal.fire(
                                " Deleted Successful!",
                                "Item deleted successfully!",
                                "success"
                            ).then((result) => {
                                // call get all course function after deletion of course 
                                getCourse()

                            });
                        })
                        .catch((err) => { });
                }
                // } else if (result.dismiss === Swal.DismissReason.cancel) {
                //   Swal.fire("", "success");
                // }
            })
            .catch((error) => {
                Swal.fire("Failed", "Unable to perform the action.", "error");
            });
        // Swal.fire("Delete", "Do you want to delete this course?", "info").then(
        //   (result) => {
        //     dispatch(updateServices?.deleteCourse(record?.id))
        //       .then((response) => {
        //         Swal.fire(
        //           " Deleted Successful!",
        //           "Course deleted successfully!",
        //           "success"
        //         ).then((result) => {
        //           navigate("/vendor/my-courses");
        //         });
        //       })
        //       .catch((err) => { });
        //   }
        // );
    };



    const editRecord = (record) => {
        getallclases()
        // window.scrollTo(0, 0);
        setUpdateButton(true);
        const data = courses.filter((id) => id.id === record.id)
        data.map((item) => {

            updateForm.setFieldsValue({
                title: item?.title,
                // about_course: item?.about_course == 'undefined' ? '' : item?.about_course,
                category: item?.category?.name,
                sub_categories: item?.sub_category_id,
                class_level: item?.class_level == "undefined" ? '' : item?.class_level ,
                about_this_course: item?.about_course == 'undefined' ? '' : item?.about_course,
                fee_include: item?.fee,
                class_name: item?.class_name,
                special_instruction: item?.special_instruction == "undefined" ?   '': item?.special_instruction ,
                instructor_name:   item?.instructor_name == "undefined" ? '' : item?.instructor_name,
                work_experience: item?.work_experience == "NaN"? '' : item?.work_experience,
                profile_photo: item?.profile_photo,
                about: item?.about == 'undefined' ? '' : item?.about,
                
                // course_start: item?.course_start == 'null' ? '' : moment(item?.course_start, "YYYY-MM-DD"),
                course_start:  item?.course_start == 'null' || !item?.course_start
                    ? ''
                    : moment(item.course_start, "YYYY-MM-DD").isValid()
                        ? moment(item.course_start, "YYYY-MM-DD")
                        : '', 
               
                course_end: item?.course_end == 'null' || !item?.course_end ? '' : moment(item.course_end, "YYYY-MM-DD").isValid()
                        ? moment(item.course_end, "YYYY-MM-DD"):'',
                
            });
            // setSelectedRecord(item)
        })
        setSelectedUpdateRecord(record)
        // setSelectedRecord(record);

        setIsUpdateModalOpen(true)
    };
    const cloneRecord = (record) => {
        const course_id = record?.id

        dispatch(updateServices?.cloneCourse({ course_id }))
            .then((response) => {
                Swal.fire({
                    title: response.message,
                    icon: "success",
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            getCourse()
                        }

                    });


            })
    }

    const convertCreatedData = (dateStr) => {
        const date = new Date(dateStr);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const year = date?.getUTCFullYear();
        const month = monthNames[date?.getUTCMonth()];
        const day = date?.getUTCDate();

        return `${month} ${day}, ${year}`;
    };

    const Category = (category_id) => {
        const category = categories?.find(
            (category) => category?.id === category_id
        );
        return category?.name;
    };

    const renderContent = (record) => (
        <div className="view-edit-delete-container">
            <p className="view">
                <IoEyeOutline
                    onClick={() => showModal(record)}
                    color="#106AB3"
                    className="view-icon"
                />
            </p>
            <p className="edit">
                <FaRegEdit
                    onClick={() => editRecord(record)}
                    color="#106AB3"
                    className="edit-icon"
                />
            </p>
            <p className="edit">
                <LiaClone
                    onClick={() => cloneRecord(record)}
                    color="#106AB3"
                    className="edit-icon"
                />
            </p>
            <p className="delete">
                <RiDeleteBin6Line
                    onClick={() => deleteCourse(record)}
                    color="#DA0734"
                    className="delete-icon"
                />
            </p>
        </div>
    );

    const Content = (record) => (
        <div>
            <p>{record ? `Content for ${record.rejection_reason}` : "Content"}</p>
        </div>
    );

    const columns = [
        {
            title: "S No.",
            dataIndex: 'serial',
            render: (_, record, index) => (currentPage - 1) * pageSize + index + 1,
        },
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            width: "16%",
            render: (text, record) => (
                <div className="course-title-container">
                    <p className="course-title">{record?.title}</p>
                </div>
            ),
        },
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
            render: (text, record) => (
                <p className="course-category">{record?.category?.name}</p>
            ),
        },
        {
            title: "Sub-Category",
            dataIndex: "subCategory",
            key: "subCategory",
            render: (text, record) => (
                <p className="course-subCategory">
                    {record?.sub_category?.name}
                </p>
            ),
        },
        {
            title: "Expire On",
            dataIndex: "expireOn",
            key: "expireOn",
            render: (text, record) => (
                <p className="course-expireOn">{record?.course_end}</p>
            ),
        },
        {
            title: "Visiblity",
            dataIndex: "visiblity",
            key: "visiblity",
            render: (text, record) => (
                <>
                    <div className="course-visibility-container">
                        {record?.visibility === "public" ? (
                            <>
                                <p className="public-icon" style={{ margin: "0" }}>
                                    <IoEyeOutline className="eye-icon" />
                                </p>
                                <p className="course-visiblity">Public</p>
                            </>
                        ) : (
                            <>
                                <p className="hidden-icon">
                                    <FaRegEyeSlash className="closed-eye-icon" />
                                </p>
                                <p className="course-visiblity">Private</p>
                            </>
                        )}
                    </div>
                </>
            ),
        },
        {
            title: "Created On",
            dataIndex: "createdOn",
            key: "createdOn",
            render: (text, record) => (
                <p className="course-createdOn">
                    {convertCreatedData(record?.created_at)}
                </p>
            ),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text, record) => (
                <div className="course-status-container">
                    {record?.status === "approved" ? (
                        <p className="approved">{record?.status}</p>
                    ) : (
                        <>
                            {record?.status === "rejected" ? (
                                <>
                                    <Popover
                                        content={Content(record)}
                                        title=""
                                        placement="bottom"
                                        className="popover-reject-reason"
                                    >
                                        <p style={{ cursor: "pointer" }} className="rejected">
                                            {record?.status}
                                        </p>
                                    </Popover>
                                </>
                            ) : (
                                <p className="pending">{record?.status}</p>
                            )}
                        </>
                    )}
                    <p className="dot-menu">
                        <Popover
                            content={renderContent(record)}
                            title="Action"
                            trigger="click"
                            placement="bottomLeft"
                        >
                            <HiOutlineDotsVertical
                                style={{ cursor: "pointer" }}
                                className="line-dot-icon"
                            />
                        </Popover>
                    </p>
                </div>
            ),
        },
    ];

    const onFinish = (value) => {
        console.log(value , "value")

        const formData = new FormData();
        formData.append(
            "vendor_id",
            vendorData?.vendor?.id ? vendorData?.vendor?.id : vendorData?.vendor_id
        );
        formData.append("title", value?.title);
        formData.append("category_id", value?.category);
        formData.append("sub_category_id", value?.sub_categories);
        formData.append(
            "course_status",
            wsqChecked === true || selectedRecord?.course_status === "wsq"
                ? "wsq"
                : "gst"
        );
        formData.append("class_level", value?.class_level);
        // formData.append("class_size", "");
        // formData.append("min_age", value?.min_age);
        // formData.append("max_age", value?.max_age);
        formData.append("about_course", value?.about_this_course);
        formData.append("fee", value?.fee_include);
        formData.append("special_instruction", value?.special_instruction);
        formData.append("instructor_name", value?.instructor_name);
        formData.append("work_experience", 3);
        formData.append("about", value?.about);
        formData.append("expire_date", "April 24, 2023");
        formData.append("course_start", start_date);
        formData.append("course_end", end_date);
        formData.append("refundable", addCancellationPlicy);
        formData.append("visibility", "public");

        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }
        dispatch(updateServices.updateCourse(selectedUpdateRecord?.id, formData))
            .then((response) => {
                Swal.fire(
                    "Item Updated Successful!",

                    "success"
                ).then((result) => {
                    setIsUpdateModalOpen(false);
                    getCourse()
                    // window.location.reload();
                });
            })
            .catch((error) => {
                console.log({ error });
            });
    };

    const multipleTabsArray = [
        {
            key: "1",
            label: "About",
            children: (
                <div className="tabs-description-container">
                    <p className="description">{selectedRecord?.about_course}</p>
                </div>
            ),
        },
        {
            key: "2",
            label: "Instructor",
            children: (
                <>
                    <div className="tabs-description-container instructor-container">
                        <div>
                            <Image
                                preview={false}
                                src={
                                    courseFormValue?.profilePhoto
                                        ? courseFormValue?.profilePhoto
                                        : selectedRecord?.profile_photo
                                }
                            />
                        </div>
                        <div>
                            <p className="description">
                                Name -{" "}
                                {courseFormValue?.instructorName
                                    ? courseFormValue?.instructorName
                                    : selectedRecord?.instructor_name}
                            </p>
                            <p className="description">
                                About -{" "}
                                {courseFormValue?.about
                                    ? courseFormValue?.about
                                    : selectedRecord?.about}
                            </p>
                            <p className="description">Experience - { } years</p>
                        </div>
                    </div>
                </>
            ),
        },

        {
            key: "3",
            label: "Cancellation Policy",
            children: (
                <div className="tabs-description-container">
                    <p className="description">
                        LearnPress is a comprehensive WordPress LMS Plugin for WordPress.
                        This is one of the best WordPress LMS Plugins which can be used to
                        easily create & sell items online. You can create a item
                        curriculum with lessons & quizzes included which is managed with an
                        easy-to-use interface for users. Having this WordPress LMS Plugin,
                        now you have a chance to quickly and easily create education, online
                        school, online-item websites with no coding knowledge required.
                    </p>
                    <p className="description">
                        LearnPress is free and always will be, but it is still a premium
                        high-quality WordPress Plugin that definitely helps you with making
                        money from your WordPress Based LMS. Just try and see how amazing it
                        is. LearnPress WordPress Online item plugin is lightweight and
                        super powerful with lots of Add-Ons to empower its core system.How
                        to use WPML Add-on for LearnPress?
                        <br />
                        Add more comments! You be the first to comment.
                    </p>
                </div>
            ),
        },
        {
            key: "4",
            label: "FAQ",
            children: (
                <Row
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        padding: "1rem 1rem",
                    }}
                    className="faq-box-shadow-none"
                >
                    {selectedRecord?.vendor_faq?.map((item) => (
                        <Col style={{ width: "100%", maxWidth: "87%" }} md={24}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<FaArrowRightLong />}
                                    aria-controls="panel1-content"
                                    id={item.key}
                                >
                                    {item.question}
                                </AccordionSummary>
                                <AccordionDetails>{item.answer}</AccordionDetails>
                            </Accordion>
                        </Col>
                    ))}
                </Row>
            ),
        },
    ];

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const handleFileChangeDoc = (e) => {
        const selectedFile = e.target.files[0];
        setDocument(selectedFile);
    };

    const onClassFinish = (values) => {

        let FormData;

        FormData = {
            course_id: selectedRecord?.id,
            time_slots: values,
        };
        dispatch(uploadService?.addCourseSlots(FormData))
            .then((response) => {
                Swal.fire({
                    title:
                        values.length > 1
                            ? "Slots Added Successfully!"
                            : "Slot Added Successfully!",
                    text:
                        values.length > 1
                            ? "Slots has been added!"
                            : "Slot has been added!",
                    icon: "success",
                }).then(() => {
                    window.location.reload();
                });
            })
            .catch((error) => {
                // console.warn({ error: error });
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const DateFormatter = (date) => {
        const inputDate = date;

        const dateObj = new Date(inputDate);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj?.getDate();
        const month = monthNames[dateObj?.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };

    const ClassSize = (classsize) => {
        const classS = classes?.find(
            (classess) => parseInt(classess?.id) === parseInt(classsize)
        );
        return classS !== undefined ? classS : classsize;
    };

    const exportToExcel = (data, fileName) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(blob, `${fileName}.xlsx`);
    };

    const transformData = (data) => {
        return data.map((item, index) => ({
            "S.NO": index + 1,
            "Course Name": item.title,
            Category: item?.category?.name,
            "Sub Category": item?.sub_category?.name,

            "Class Level":
                ClassSize(item?.class_level)?.name !== undefined
                    ? ClassSize(item?.class_level)?.name
                    : ClassSize(item?.class_level),
            "Class Size":
                ClassSize(item.class_level).max_size !== undefined &&
                    ClassSize(item.class_level).min_size !== undefined
                    ? `${ClassSize(item.class_level).min_size}-${ClassSize(item.class_level).max_size
                    }`
                    : item.class_size,
            "Min Age": item.min_age,
            "Max Age": item.max_age,
            "About Course": item.about_course,
            "Course Fee": item.fee,
            "Special Instruction": item.special_instruction,
            "Expire On": DateFormatter(item?.expire_date),
            Status: item.status,
            Visibility: item.visibility,
            "Instructor Name": item.instructor_name,
            "Work Experience": `${item.work_experience} years`,
            "About Instructor": item.about,
            "FAQ's":
                item.vendor_faq !== null || item.vendor_faq?.length === 0
                    ? JSON.stringify(item.vendor_faq)
                    : "",
        }));
    };



    const checkDisabledHours = () => {
        if (selectedHourData !== undefined && selectedDay !== null) {
            let disabledStartHours = selectedHourData
                .filter((item) => item.day === selectedDay)
                .map((item) => parseInt(item.start_hour));

            let disabledEndHours = selectedHourData
                .filter((item) => item.day === selectedDay)
                .map((item) => parseInt(item.end_hour));

            disabledStartHours = Array.from(new Set(disabledStartHours));
            disabledEndHours = Array.from(new Set(disabledEndHours));

            return { disabledStartHours, disabledEndHours };
        }
        return [];
    };

    const onFieldsChange = (changedFields, allFields) => {
        allFields.forEach((field) => {
            if (field?.name.length && Array.isArray(field.value)) {
                field.value.forEach((record) => {
                    if (record && record.week_day && record.start_time && record.end_time) {
                        const formData = {
                            day: record.week_day,
                            start_hour: record.start_time.hour(),
                            end_hour: record.end_time.hour(),
                        };

                        selectedHourData.push(formData);
                    }
                });
            }
        });
    };

    const selectedDayValue = (e) => {
        selectedDay = e.target.value;
        const updatedDisabledHours = checkDisabledHours();
        setDisabledHours({ disabledStartHours: updatedDisabledHours.disabledStartHours, disabledEndHours: updatedDisabledHours.disabledEndHours });
    };

    let start_date = '';
    let end_date = '';
    let filter_Data = ''
    const onChange = (date, dateString) => {

        start_date = dateString;
    };
    const onEndDataChange = (date, dateString) => {
        end_date = dateString

    }

    const onFilter = (date, dateString) => {
        filter_Data = dateString
    }
    const handleFilterDate = () => {
        dispatch(uploadService?.getCoursesByDate({ filter_Data })).then((response) => {


            if (response.data.length > 0) {
                setFilterData(response.data)
            } else {
                Swal.fire({
                    title: response.data,
                    icon: "error",
                }).then((result) => {
                    if (result.isConfirmed) {
                        filter_Data = ''
                    }
                })
            }

        })
            .catch(() => {
                Swal.fire({
                    title: "No item found for selected date",
                    icon: "error",
                })
            })
    }
    const handleReset = () => {
        setFilterData([])
    }

    const beforeUpload = (file) => {
        const isCsvOrXls = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel';
        if (!isCsvOrXls) {
            Swal.fire({
                title: "Invalid File Format",
                text: "Please upload a valid CSV or XLS file.",
                icon: "error",
            });
        }
        return isCsvOrXls;
    };


    const handleCourseCSVUpload = () => {
        if (bulkuploadfile) {
            const formData = new FormData();
            formData.append("csv_file", bulkuploadfile);
            dispatch(uploadService?.uploadCoursesCSV(formData)).then((response) => {
                Swal.fire({
                    title: response.message,
                    icon: "success",
                })
            })
                .catch(() => {
                    Swal.fire({
                        title: "Failed to upload item",
                        icon: "error",
                    })
                })
        } else {
            Swal.fire({
                title: "Please select a file",
                icon: "error",
            })
        }
        setbulkuploadFile(null)
        getCourse()
    }
    const getallclases = () => {
        dispatch(getServices.getClasses())
            .then((response) => {
                
                setClasses(response?.classes);
            })
            .catch((err) => {
                
            });

    }
    return (
        <>
            <TopNavBar />
            <Row>
                <Col xl={4} className="d-xl-block d-md-none">
                    <Sidebar />
                </Col>
                <Col xl={20} md={24}>
                    <div className="mainContent my-courses">
                        <div className="my-courses-container">
                            <div className="download-courses-container">
                                <Button
                                    className="download-courses-button"
                                    onClick={() => exportToExcel(transformData(courses), "Courses")}
                                >
                                    {loader ? (
                                        <Spin
                                            indicator={<LoadingOutlined style={{ color: "#fff" }} spin />}
                                            size="small"
                                        />
                                    ) : (
                                        "Download item in xlsx/csv"
                                    )}
                                </Button>
                            </div>
                            <div className="courses-head-container">
                                <p className="my-course-heading">My items</p>
                                <div className="sample-course-container">
                                    <Button className="download-sample-button">
                                        <a className="download-file-link" href={SampleCourseCSV} download="Sample Course">Download Sample</a>
                                    </Button>
                                    <div className="upload-file-container">
                                        <Upload onChange={(e) => setbulkuploadFile(e?.file?.originFileObj)} className="file-upload" beforeUpload={beforeUpload} showUploadList={false}>
                                            <Button className="select-file-button">{bulkuploadfile ? bulkuploadfile?.name : "Select File"}</Button>
                                        </Upload>
                                        <Button onClick={handleCourseCSVUpload} className="upload-file-button">Upload File</Button>
                                    </div>
                                </div>
                                <Button
                                    onClick={() => navigate("/vendor/add-new-course")}
                                    className="add-new-course-button"
                                >
                                    Add New Item
                                </Button>
                            </div>
                            <div className="courses-head-filter">
                                <DatePicker onChange={onFilter} />
                                <Button onClick={handleFilterDate}> filter</Button>
                                <Button onClick={handleReset} type="primary">Reset</Button>
                            </div>
                            <div className="my-course-table-container table-responsive">
                                <Table
                                    className="my-course-table"
                                    columns={columns}
                                    dataSource={displayedItems}
                                    pagination={false}
                                />
                                <Pagination
                                    className="mt-4"
                                    current={currentPage}
                                    pageSize={pageSize}
                                    total={totalItems}
                                    onChange={handleChangePage}
                                />
                            </div>
                        </div>
                    </div>
                    <CopyWriteFooter />
                </Col>
            </Row>

            <Modal
                title="View item Detail"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                style={{ marginTop: "4rem" }}
            >
                <div
                    style={{ background: "#fff" }}
                    className="form-container form-container-view"
                >
                    <Form
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}

                        autoComplete="off"
                        className="add-new-course-form view-course-form"
                        // onFinish={onFinish}
                        form={form}
                    >
                        <Row className="form-row">
                            <Col md={24} className="custom-padding">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Title
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="title"
                                >
                                    <Input
                                        className="input-box-login"

                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8} className="input-new-course">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Select Categories
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="category"
                                >
                                    <Select
                                        className="input-box-login select-category"
                                        placeholder="Select Category"
                                        prefix={<MdOutlineCategory />}
                                        disabled
                                    >
                                        
                                        {categories &&
                                            categories.map((category) => (
                                                <Select.Option value={category?.id} key={category?.id}>
                                                    {category?.name}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={8} className="input-new-course">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Select Sub-Categories
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="sub_categories"
                                >
                                    <Select
                                        className="input-box-login sub-select-category"
                                        placeholder="Select Sub-Categories"
                                        prefix={<MdOutlineCategory />}
                                        disabled
                                    >
                                        {subCategories &&
                                            subCategories.map((subCategory) => (
                                                <Select.Option
                                                    value={subCategory?.id}
                                                    key={subCategory?.id}
                                                >
                                                    {subCategory?.name}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                        </Row>

                        <Row>
                            <Col md={8} className="input-new-course">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Level
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="class_level"
                                >
                                    <Select
                                        className="input-box-login select-class-level"
                                        placeholder="Select  Level"
                                        prefix={<BsBarChart />}
                                        disabled
                                    >
                                        <Select.Option value="1">1</Select.Option>
                                        <Select.Option value="2">2</Select.Option>
                                        <Select.Option value="3">3</Select.Option>
                                        <Select.Option value="4">4</Select.Option>
                                        <Select.Option value="5">5</Select.Option>
                                        <Select.Option value="6">6</Select.Option>
                                        <Select.Option value="7">7</Select.Option>
                                        <Select.Option value="8">8</Select.Option>
                                        <Select.Option value="9">9</Select.Option>
                                        <Select.Option value="10">10</Select.Option>
                                        <Select.Option value="11">11</Select.Option>
                                        <Select.Option value="12">12</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                        </Row>

                        <Row className="form-row">
                            <Col md={24} className="custom-padding">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    About This item
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="about_this_course"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: "Please enter title.",
                                    //     },
                                    // ]}
                                >
                                    <TextArea
                                        className="leave-comment-text-area"
                                        rows={4}
                                        placeholder="Type here....."
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className="form-row fee-instruction-upload-row">
                            <Col
                                md={8}
                                className="custom-padding fee-instruction-upload-col fee-include"
                            >
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Fee
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="fee_include"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter fee.",
                                        },
                                    ]}
                                >
                                    <Input
                                        type="number"
                                        className="input-box-login"
                                        placeholder="Type here....."
                                        prefix={<CiDollar />}
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                md={8}
                                className="custom-padding fee-instruction-upload-col special-instruction"
                            >
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Special Instruction*{" "}
                                    <span
                                        className="optional"
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            color: "#AFB5C0",
                                            fontFamily: "Poppins",
                                        }}
                                    >
                                        (Optional)
                                    </span>
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="special_instruction"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter title.",
                                        },
                                    ]}
                                >
                                    <Input
                                        className="input-box-login"
                                        placeholder="Enter Instructions If Any?"
                                        prefix={<BsFileCode />}
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                md={8}
                                className="input-new-course fee-instruction-upload-col upload-image"
                            >
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Upload Images
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="website_url"
                                >
                                    <label
                                        for="logo-upload"
                                        className="custom-upload-logo d-flex justify-content-between"
                                    >
                                        <span className="custom-input-logo-left d-flex align-items-center">
                                            <LuUpload className="form-icons" /> Upload item Images
                                        </span>
                                    </label>
                                    <Input
                                        style={{ display: "none" }}
                                        type="file"
                                        id="logo-upload"
                                        className="input-box-login"
                                        placeholder="www.welloffun.com"
                                        hidden
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className="form-row">
                            <Col md={24} className="input-new-course upload-documents">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Upload Documents
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="upload-document"
                                >
                                    <label
                                        for="logo-document"
                                        className="custom-upload-logo d-flex justify-content-between upload-document-label"
                                    >
                                        <span className="custom-input-logo-left d-flex align-items-center">
                                            {" "}
                                            <LuUpload className="form-icons" /> Drop or Upload
                                            Document and Photos
                                        </span>{" "}
                                    </label>
                                    <Input
                                        style={{ display: "none" }}
                                        type="file"
                                        id="logo-document"
                                        className="input-box-login"
                                        placeholder="www.welloffun.com"
                                        hidden
                                        // onChange={handleFileChangeDoc}
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <p className="instructor-detains">Instructor’s Detail (Optional)</p>

                        <Row className="form-row instructor-details-container">
                            <Col
                                md={12}
                                className="input-new-course fee-instruction-upload-col upload-image"
                            >
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Instructor Name
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="instructor_name"

                                >
                                    <Input
                                        className="input-box-login"
                                        placeholder="Enter name here"
                                        prefix={<CiUser color="#7c8597" />}
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                            <Col md={12} className="input-new-course">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Work Experience
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="work_experience"


                                >
                                    <Input
                                        className="input-box-login"
                                        placeholder="Enter Expirence"
                                        prefix={<LiaCalendarAltSolid color="#7c8597" />}
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className="form-row">
                            <Col
                                md={12}
                                className="input-new-course fee-instruction-upload-col upload-image"
                            >
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Profile Photo
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="profile_photo"
                                >
                                    <label
                                        for="logo-upload"
                                        className="custom-upload-logo d-flex justify-content-between"
                                    >
                                        <span className="custom-input-logo-left d-flex align-items-center">
                                            {" "}
                                            <LuUpload className="form-icons" /> Upload Photo
                                        </span>{" "}
                                    </label>
                                </Form.Item>
                            </Col>
                            <Col md={12} className="input-new-course">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    About
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="about"

                                >
                                    <Input
                                        className="input-box-login"
                                        placeholder="Type something"
                                        prefix={<BsInfoCircle color="#7c8597" />}
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className="faq-box-shadow-none">
                            {selectedRecord?.vendor_faq?.map((item) => (
                                <>
                                    <div className="course-faq-container">
                                        <p className="frequently-asked-que">
                                            Frequently Asked Questions (FAQ’s)❓
                                        </p>
                                    </div>
                                    <Col md={12}>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<FaArrowRightLong />}
                                                aria-controls="panel1-content"
                                                id={item.key}
                                            >
                                                {item.question}
                                            </AccordionSummary>
                                            <AccordionDetails>{item.answer}</AccordionDetails>
                                        </Accordion>
                                    </Col>
                                </>
                            ))}
                        </Row>

                    </Form>
                </div>
            </Modal>
            <Modal
                title="Edit item"
                open={isUpdateModalOpen}
                onOk={handleupdateCourseCancel}
                onCancel={handleupdateCourseCancel}
                style={{
                    marginTop: "4rem", width: "100%",
                    maxWidth: "60%",
                }}
            >
                <div
                    style={{ background: "#fff" }}
                    className="form-container form-container-view"
                >
                    <Form
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        autoComplete="off"
                        className="add-new-course-form view-course-form"
                        onFinish={onFinish}
                        form={updateForm}
                    >
                        <Row className="form-row">
                            <Col md={24} className="custom-padding">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Title
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="title"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please enter title.",
                                      },
                                    ]}
                                >
                                    <Input
                                        className="input-box-login"
                                        placeholder="Type here....."
                                        onChange={(e) =>
                                            setCourseFormValue((prevState) => ({
                                                ...prevState,
                                                title: e.target.value,
                                            }))
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            {/* <Col md={12} className="input-new-course">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Select Categories
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="category"
                                >
                                    <Select
                                        className="input-box-login select-category"
                                        placeholder="Select Category"
                                        prefix={<MdOutlineCategory />}
                                        onChange={(e) =>
                                            setCourseFormValue((prevState) => ({
                                                ...prevState,
                                                category: e,
                                            }))
                                        }
                                        options={categories}
                                    >
                                      
                                    </Select>
                                </Form.Item>
                            </Col> */}
                            {/* <Col md={12} className="input-new-course">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Select Sub-Categories
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="sub_categories"
                                >
                                    <Select
                                        className="input-box-login sub-select-category"
                                        placeholder="Select Sub-Categories"
                                        prefix={<MdOutlineCategory />}
                                        onChange={(e) =>
                                            setCourseFormValue((prevState) => ({
                                                ...prevState,
                                                sub_category: e,
                                            }))
                                        }
                                    >
                                        {subCategories &&
                                            subCategories.map((subCategory) => (
                                                <Select.Option
                                                    value={subCategory?.id}
                                                    key={subCategory?.id}
                                                >
                                                    {subCategory?.name}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col> */}

                            <Col md={12} className="input-new-course">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Select Categories
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="category"
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: "Please select category.",
                                //     },
                                // ]}
                                >
                                    <Select
                                        className="input-box-login select-category"
                                        placeholder="Category"
                                        prefix={<MdOutlineCategory />}
                                        onChange={(value) => handlecategory(value)}
                                        // onChange={(e) =>
                                        //     setCourseFormValue((prevState) => ({
                                        //         ...prevState,
                                        //         category: e,
                                        //     }))
                                        // }
                                       
                                        
                                    >
                                        {categories &&
                                            categories.map((category) => (
                                                <Select.Option
                                                    key={category.id}
                                                    value={category.id}
                                                >
                                                    {category.name}
                                                </Select.Option>
                                            ))}   
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={12} className="input-new-course">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Select Sub-Categories
                                </p>
                                {/* as per the data this id child Categories */}
                                <Form.Item
                                    className="form-input-class"
                                    name="sub_categories"
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: "Please select sub category.",
                                //     },
                                    // // ]}
                                    // onChange={(e) =>
                                    //     setCourseFormValue((prevState) => ({
                                    //         ...prevState,
                                    //         sub_category: e,
                                    //     }))
                                    // }
                                >
                                    <Select
                                        className="input-box-login sub-select-category"
                                        placeholder="Sub-Categories"
                                        prefix={<MdOutlineCategory />}
                                        onChange={(value) => setSelectedChildCategory(value)}
                                        // disabled={!selectedCategory}
                                    >
                                        {childCategories &&
                                            childCategories.map((childCategory) => (
                                                <Select.Option
                                                    key={childCategory.id}
                                                    value={childCategory.id}
                                                // disabled={selectedSubCategory === childCategory.id}
                                                >
                                                    {childCategory.name}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            

                        </Row>

                        <Row>
                            <Col md={12} className="input-new-course">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Level
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="class_level"
                                    placeholder="please select class level"
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "Please select category.",
                                //   },
                                // ]}
                                >
                                    <Select
                                        className="input-box-login select-class-level"
                                        placeholder="Select  Level"
                                        prefix={<BsBarChart />}
                                        onChange={(e) =>
                                            setCourseFormValue((prevState) => ({
                                                ...prevState,
                                                classLevel: e,
                                            }))
                                        }
                                    >
                                        <Select.Option value="1">1</Select.Option>
                                        <Select.Option value="2">2</Select.Option>
                                        <Select.Option value="3">3</Select.Option>
                                        <Select.Option value="4">4</Select.Option>
                                        <Select.Option value="5">5</Select.Option>
                                        <Select.Option value="6">6</Select.Option>
                                        <Select.Option value="7">7</Select.Option>
                                        <Select.Option value="8">8</Select.Option>
                                        <Select.Option value="9">9</Select.Option>
                                        <Select.Option value="10">10</Select.Option>
                                        <Select.Option value="11">11</Select.Option>
                                        <Select.Option value="12">12</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={12} className="input-new-course">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Select Class
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="class_name"
                                >
                                    <Select
                                        className="input-box-login select-category"
                                        placeholder="Select Category"
                                        prefix={<MdOutlineCategory />}
                                        onChange={(e) =>
                                            setCourseFormValue((prevState) => ({
                                                ...prevState,
                                                class_name: e,
                                            }))
                                        }
                                    >
                                        {classes &&
                                            classes.map((category) => (
                                                <Select.Option value={category?.id} key={category?.id}>
                                                    {category?.name}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className="form-row">
                            <Col md={24} className="custom-padding">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Description
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="about_this_course"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: "Please enter title.",
                                    //     },
                                    // ]}
                                >
                                    <TextArea
                                        className="leave-comment-text-area"
                                        rows={4}
                                        placeholder="Type here....."
                                        onChange={(e) =>
                                            setCourseFormValue((prevState) => ({
                                                ...prevState,
                                                aboutCourse: e.target.value,
                                            }))
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className="form-row fee-instruction-upload-row">
                            <Col
                                md={8}
                                className="custom-padding fee-instruction-upload-col fee-include"
                            >
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Fee
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="fee_include"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: "Please enter fee.",
                                    //     },
                                    // ]}
                                >
                                    <Input
                                        type="number"
                                        className="input-box-login"
                                        placeholder="Type here....."
                                        prefix={<CiDollar />}
                                        onChange={(e) =>
                                            setCourseFormValue((prevState) => ({
                                                ...prevState,
                                                feeIncluded: e.target.value,
                                            }))
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                md={8}
                                className="custom-padding fee-instruction-upload-col special-instruction"
                            >
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Special Instruction*{" "}
                                    <span
                                        className="optional"
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            color: "#AFB5C0",
                                            fontFamily: "Poppins",
                                        }}
                                    >
                                        (Optional)
                                    </span>
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="special_instruction"
                                
                                >
                                    <Input
                                        className="input-box-login"
                                        placeholder="Enter Instructions If Any?"
                                        prefix={<BsFileCode />}
                                        onChange={(e) =>
                                            setCourseFormValue((prevState) => ({
                                                ...prevState,
                                                specialInstruction: e.target.value,
                                            }))
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                md={8}
                                className="input-new-course fee-instruction-upload-col upload-image"
                            >
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Upload Images
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="website_url"
                                >
                                    <label
                                        for="logo-upload"
                                        className="custom-upload-logo d-flex justify-content-between"
                                    >
                                        <span className="custom-input-logo-left d-flex align-items-center">
                                            <LuUpload className="form-icons" /> Upload Course Images
                                        </span>
                                    </label>
                                    <Input
                                        style={{ display: "none" }}
                                        type="file"
                                        id="logo-upload"
                                        className="input-box-login"
                                        placeholder="www.welloffun.com"
                                        hidden
                                        onChange={handleFileChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                md={12}
                                className="custom-padding fee-instruction-upload-col fee-include"
                            >
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Start Date
                                </p>

                                <Form.Item
                                    name="course_start"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: "Please select start date!",
                                    //     },
                                    // ]}
                                >
                                    <DatePicker onChange={onChange} className="datePicker" />
                                </Form.Item>
                            </Col>

                            <Col
                                md={12}
                                className="custom-padding fee-instruction-upload-col fee-include"
                            >
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    End Date
                                </p>

                                <Form.Item
                                    name=" course_end"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: "Please select End date!",
                                    //     },
                                    // ]}
                                >
                                    <DatePicker onChange={onEndDataChange} />
                                </Form.Item>
                            </Col>
                        </Row>


                        <Row className="form-row">
                            <Col md={24} className="input-new-course upload-documents">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Upload Documents
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="upload-document"
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "Please upload document.",
                                //   },
                                // ]}
                                >
                                    <label
                                        for="logo-document"
                                        className="custom-upload-logo d-flex justify-content-between upload-document-label"
                                    >
                                        <span className="custom-input-logo-left d-flex align-items-center">
                                            {" "}
                                            <LuUpload className="form-icons" /> Drop or Upload
                                            Document and Photos
                                        </span>{" "}
                                    </label>
                                    <Input
                                        style={{ display: "none" }}
                                        type="file"
                                        id="logo-document"
                                        className="input-box-login"
                                        placeholder="www.welloffun.com"
                                        hidden
                                        onChange={handleFileChangeDoc}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <p className="instructor-detains">Instructor’s Detail</p>

                        <Row className="form-row instructor-details-container">
                            <Col
                                md={12}
                                className="input-new-course fee-instruction-upload-col upload-image"
                            >
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Instructor Name
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="instructor_name"

                                >
                                    <Input
                                        className="input-box-login"
                                        placeholder="Enter name here"
                                        prefix={<CiUser color="#7c8597" />}
                                        onChange={(e) =>
                                            setCourseFormValue((prevState) => ({
                                                ...prevState,
                                                instructorName: e.target.value,
                                            }))
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col md={12} className="input-new-course">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Work Experience
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="work_experience"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: "Please enter about the isntructor.",
                                    //     },
                                    // ]}
                                >
                                    <Input
                                        className="input-box-login"
                                        placeholder="Enter Expirence"
                                        prefix={<LiaCalendarAltSolid color="#7c8597" />}
                                        onChange={(e) =>
                                            setCourseFormValue((prevState) => ({
                                                ...prevState,
                                                workExperience: e.target.value,
                                            }))
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className="form-row">
                            <Col
                                md={12}
                                className="input-new-course fee-instruction-upload-col upload-image"
                            >
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    Profile Photo
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="profile_photo"
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "Please enter website url.",
                                //   },
                                // ]}
                                >
                                    <label
                                        for="logo-upload"
                                        className="custom-upload-logo d-flex justify-content-between"
                                    >
                                        <span className="custom-input-logo-left d-flex align-items-center">
                                            {" "}
                                            <LuUpload className="form-icons" /> Upload Photo
                                        </span>{" "}
                                    </label>
                                    {/* <Input
                        type="file"
                        id="logo-upload"
                        className="input-box-login"
                        placeholder="www.welloffun.com"
                        prefix={<CiImageOn className="form-icons" />}
                        suffix={<BsCloudUpload className="form-icons" />}
                        hidden
                      /> */}
                                </Form.Item>
                            </Col>
                            <Col md={12} className="input-new-course">
                                <p
                                    style={{
                                        textAlign: "start",
                                        fontFamily: "POPPINS",
                                    }}
                                >
                                    About
                                </p>

                                <Form.Item
                                    className="form-input-class"
                                    name="about"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: "Please enter about the isntructor.",
                                    //     },
                                    // ]}
                                >
                                    <Input
                                        className="input-box-login"
                                        placeholder="Type something"
                                        prefix={<BsInfoCircle color="#7c8597" />}
                                        onChange={(e) =>
                                            setCourseFormValue((prevState) => ({
                                                ...prevState,
                                                about: e.target.value,
                                            }))
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <div className="course-faq-container">
                            <p className="frequently-asked-que">
                                Frequently Asked Questions (FAQ’s)❓
                            </p>
                        </div>
                        <Row className="faq-box-shadow-none">
                            {selectedRecord?.vendor_faq?.map((item) => (
                                <Col md={12}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<FaArrowRightLong />}
                                            aria-controls="panel1-content"
                                            id={item.key}
                                        >
                                            {item.question}
                                        </AccordionSummary>
                                        <AccordionDetails>{item.answer}</AccordionDetails>
                                    </Accordion>
                                </Col>
                            ))}
                        </Row>
                        <div className="cancellation-pollicy-checkbox-container">
                            <Checkbox
                                className="cancellation-pollicy-checkbox"
                                onChange={(e) => setAddCancellationPolicy(e.target.checked)}
                            >
                                <span className="cancellation-policy-text">
                                    Add Refund Policy
                                </span>
                            </Checkbox>
                        </div>
                        {addCancellationPlicy ? (
                            <>
                                <Row className="form-row">
                                    <Col md={24} className="custom-padding">
                                        <p
                                            style={{
                                                textAlign: "start",
                                                fontFamily: "POPPINS",
                                                marginTop: "1rem",
                                            }}
                                        >
                                            Refund Policy
                                        </p>

                                        <Form.Item
                                            className="form-input-class"
                                            name="refund_policy"
                                        >
                                            <TextArea
                                                className="leave-comment-text-area"
                                                rows={4}
                                                placeholder="Add cancellation policy"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            ""
                        )}
                        <p
                            style={{
                                fontSize: "18px",
                                lineHeight: "27px",
                                fontWeight: "500",
                                fontFamily: "Poppins",
                                color: "#434343",
                            }}
                        >
                            Add Slots
                        </p>
                        <Row className="class-add-row">
                            <Form
                                name="dynamic_form_nest_item"
                                form={nestedForm}
                                onFinish={(values) => {
                                    const updatedData = values.class_info.map((item, index) => ({
                                        ...item,
                                    }));
                                    onClassFinish(updatedData);
                                }}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                onFieldsChange={onFieldsChange}
                            >
                                <Form.List name="class_info">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, index, name, ...restField }) => (
                                                <>
                                                    <Col md={24} className="add-week-col">
                                                        <Form.Item
                                                            className="week-days-form-item"
                                                            {...restField}
                                                            name={[name, "week_day"]}
                                                            // rules={[
                                                            //     {
                                                            //         required: true,
                                                            //         message: "Please Select week day",
                                                            //     },
                                                            // ]}
                                                        >
                                                            <Radio.Group
                                                                key={key}
                                                                onChange={(e) => selectedDayValue(e)}
                                                            >
                                                                <Radio value={"Sunday"}>SUN</Radio>
                                                                <Radio value={"Monday"}>MON</Radio>
                                                                <Radio value={"Tuesday"}>TUE</Radio>
                                                                <Radio value={"Wednesday"}>WED</Radio>
                                                                <Radio value={"Thursday"}>THU</Radio>
                                                                <Radio value={"Friday"}>FRI</Radio>
                                                                <Radio value={"Saturday"}>SAT</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                    <Row>
                                                        <Col md={7} className="add-class-col-three">
                                                            <p
                                                                style={{
                                                                    textAlign: "start",
                                                                    fontFamily: "POPPINS",
                                                                }}
                                                            >
                                                                Class Name
                                                            </p>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, "description"]}
                                                                // rules={[
                                                                //     {
                                                                //         required: true,
                                                                //         message: "",
                                                                //     },
                                                                // ]}
                                                            >
                                                                <Input placeholder="Description" />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col md={8} className="add-class-col-one">
                                                            <p
                                                                style={{
                                                                    textAlign: "start",
                                                                    fontFamily: "POPPINS",
                                                                }}
                                                            >
                                                                Start Time
                                                            </p>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, "start_time"]}
                                                                // rules={[
                                                                //     {
                                                                //         required: true,
                                                                //         message: "Enter start time",
                                                                //     },
                                                                // ]}
                                                                key={key}
                                                            >
                                                                <TimePicker
                                                                    ref={timePickerRef}
                                                                    key={index}
                                                                    disabledHours={() => disabledHours.disabledStartHours}
                                                                    format="HH:mm"
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col md={8} className="add-class-col-two">
                                                            <p
                                                                style={{
                                                                    textAlign: "start",
                                                                    fontFamily: "POPPINS",
                                                                }}
                                                            >
                                                                End Time
                                                            </p>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, "end_time"]}
                                                                // rules={[
                                                                //     {
                                                                //         required: true,
                                                                //         message: "Enter end sate",
                                                                //     },
                                                                // ]}
                                                            >
                                                                <TimePicker
                                                                    ref={timePickerRef}
                                                                    key={index}
                                                                    disabledHours={() => disabledHours.disabledEndHours}
                                                                    format="HH:mm"
                                                                />
                                                            </Form.Item>
                                                        </Col>

                                                        <Col md={1} className="remove-icon-container">
                                                            <MinusCircleOutlined
                                                                style={{ color: "#106AB3" }}
                                                                onClick={() => remove(name)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </>
                                            ))}

                                            <Form.Item className="add-field-form-item">
                                                <Button
                                                    className="add-class-field-button"
                                                    type="dashed"
                                                    style={{
                                                        width: "100%",
                                                        maxWidth: "10%",
                                                        background: "#106AB3",
                                                        color: "#fff",
                                                        borderStyle: "solid",
                                                    }}
                                                    onClick={() => add()}
                                                    block
                                                    icon={<PlusOutlined />}
                                                ></Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            nestedForm.submit();
                                        }}
                                    >
                                        Upload Slots
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Row>
                        <Form.Item
                            className="mb-0"
                            wrapperCol={{
                                offset: 8,
                                span: 24,
                            }}
                        >
                            <Button
                                style={updateButton === false ? { display: "none" } : ""}
                                className="register-button"
                                htmlType="submit"
                            >
                                Update Items
                            </Button>
                        </Form.Item>
                        <Button
                            onClick={() => showModalPreview()}
                            style={updateButton === false ? { display: "none" } : ""}
                            className="register-button"
                            htmlType="button"
                        >
                            Preview
                        </Button>
                    </Form>
                </div>
            </Modal>
            <Modal
                style={{
                    backgroundColor: "#fff",
                    width: "100%",
                    maxWidth: "95%",
                }}
                title="Update Items"
                open={isModalOpenPreview}
                onOk={handleOkPreview}
                onCancel={handleCancelPreview}
                className="preview-modal"
            >
                <div className="course-details-container">
                    <div className="course-details-banner-container">
                        <Row className="course-details-banner-row">
                            <Col className="course-details-banner-col-one" md={12}>
                                <Button className="course-banner-button">
                                    {Category(
                                        courseFormValue?.category || selectedRecord?.category_id
                                    )}
                                </Button>
                                <p className="course-details-heading">
                                    {courseFormValue?.title || selectedRecord?.title}
                                </p>
                                <Row className="students-level-lesson-row">
                                    <Col className="students-with-icon" md={8}>
                                        <RiGraduationCapFill className="graduation-cap-icon" />
                                        <p className="no-of-students" style={{ fontSize: "12px" }}>
                                            156 Students
                                        </p>
                                    </Col>
                                    <Col className="levels-with-icon" md={8}>
                                        <BsFillBarChartFill className="bar-chart-icon" />
                                        <p className="all-levels" style={{ fontSize: "12px" }}>
                                            All levels
                                        </p>
                                    </Col>
                                    <Col className="lessons-with-icon" md={8}>
                                        <FaCopy className="copy-icon" />
                                        <p className="no-of-lessons" style={{ fontSize: "12px" }}>
                                            20 Lessons
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="banner-rating-container">
                                    <Col className="banner-rating-col-one" md={2}>
                                        <p className="rating-text">4.8</p>
                                    </Col>
                                    <Col className="banner-rating-col-two" md={22}>
                                        <Rate className="rating-star" defaultValue={4.8} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="course-details-banner-col-two" md={12}>
                                <Row className="course-img-video-with-enrol-row">
                                    <Col className="course-img-video-with-enrol-col-one" md={5}>
                                    </Col>
                                    <Col
                                        className="course-img-video-with-enrol-col-two course-card-container"
                                        md={19}
                                    >
                                        <div className="enroll-container">
                                            <div className="enroll-banner-image-div">
                                                <div className="image-div"></div>
                                            </div>
                                            <div className="price-with-enroll-outer-div">
                                                <Row className="price-with-enroll-row">
                                                    <Col className="price-with-enroll-col-one" md={12}>

                                                        <p className="latest-price">
                                                            $
                                                            {courseFormValue?.feeIncluded ||
                                                                selectedRecord?.fee}
                                                        </p>
                                                    </Col>
                                                    <Col className="price-with-enroll-col-two" md={12}>
                                                        <Button className="enroll-button">
                                                            Purchase Now
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <div className="multiple-tabs-container container">
                        <Tabs
                            className="multiple-tabs"
                            defaultActiveKey="1"
                            items={multipleTabsArray}
                        />
                    </div>
                    <div className="comments-container container">
                        <div className="comments-head-container">
                            <p className="comments-heading border-bottom-heading">Comments</p>
                            <p className="no-of-comments">00 Comments</p>
                        </div>

                    </div>
                    <div className="leave-comment-form-container container">
                        <Row className="leave-comment-form-row">
                            <Col className="leave-comment-form-col-one" md={15}>
                                <p className="leave-comment-heading border-bottom-heading">
                                    Leave a comment
                                </p>
                                <p className="leave-comment-not-publish">
                                    Your email address will not be published. Required fields are
                                    marked *
                                </p>
                                <Form
                                    name="basic"
                                    labelCol={{
                                        span: 8,
                                    }}
                                    wrapperCol={{
                                        span: 24,
                                    }}
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    className="leave-comment-form"
                                >
                                    <Row className="leave-comment-form-row">
                                        <Col md={10} className="leave-comment-form-col pe-2">
                                            <Form.Item
                                                className="form-input-class"
                                                name="fullName"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: "Please enter full name.",
                                                //     },
                                                // ]}
                                            >
                                                <Input
                                                    className="leave-comment-input"
                                                    placeholder="Name*"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col md={14} className="leave-comment-form-col ps-2">
                                            <Form.Item
                                                className="form-input-class"
                                                name="email"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: "Email is not valid",
                                                //     },
                                                // ]}
                                            >
                                                <Input
                                                    className="leave-comment-input"
                                                    placeholder="Email*"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row className="leave-comment-form-row">
                                        <Col md={24} className="leave-comment-form-col">
                                            <Form.Item
                                                className="form-input-class"
                                                name="comment"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: "Please enter your comment.",
                                                //     },
                                                // ]}
                                            >
                                                <TextArea
                                                    className="leave-comment-text-area"
                                                    rows={4}
                                                    placeholder="Add Comment here..."
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row className="leave-comment-form-row">
                                        <Col md={24} className="leave-comment-form-col">
                                            <Form.Item
                                                className="form-input-class mb-0"
                                                wrapperCol={{
                                                    offset: 8,
                                                    span: 24,
                                                }}
                                            >
                                                <Button
                                                    className="leave-comment-button"
                                                    htmlType="submit"
                                                    type="submit"
                                                >
                                                    Post Comment
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <Col className="leave-comment-form-col-two" md={9}>
                                <p className="review-rating-heading">Review & Ratings</p>
                                <div className="ratings-rate">
                                    <div className="rate-with-text-div">
                                        <Rate className="review-rating-star" defaultValue={5} />
                                        <p className="review-rating-text">5.0</p>
                                    </div>
                                    <div className="rate-with-text-div">
                                        <Rate className="review-rating-star" defaultValue={4} />
                                        <p className="review-rating-text">4.0</p>
                                    </div>
                                    <div className="rate-with-text-div">
                                        <Rate className="review-rating-star" defaultValue={3} />
                                        <p className="review-rating-text">3.0</p>
                                    </div>
                                    <div className="rate-with-text-div">
                                        <Rate className="review-rating-star" defaultValue={2} />
                                        <p className="review-rating-text">2.0</p>
                                    </div>
                                    <div className="rate-with-text-div">
                                        <Rate className="review-rating-star" defaultValue={1} />
                                        <p className="review-rating-text">1.0</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                </div>
            </Modal>
        </>
    );
}
