import React from 'react'
const CookiePolicy = () => {
    return (
      <div>
        <h1>Cookie Policy</h1>
        <p>This is the Cookie Policy</p>
      </div>
  
  )  
}
export default CookiePolicy;