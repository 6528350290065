import React, { useEffect, useState } from 'react'
import { Button, Row, Col } from "antd";
import { useNavigate } from 'react-router-dom';
// import  useNavigate  from 'react-router';
import { setCookie, hasCookie } from './cookieutils';
export default function Cookie() {
  const [isVisible, setIsVisible] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {

    setIsVisible(!hasCookie());

  }, []);
  const handleaccept = () => {
    setCookie();
    setIsVisible(false);
  }
  const handlecookie = () => {
    navigate('/cookie-policy')
    // setIsVisible(true);
  }
  return (

    isVisible ? (
      <div className='cookies-section' >
        <div className='container-fluid'>
          <Row>
            <Col xl={24} md={24}>
              <div className='cookies-left-content-title'>
                <h3> We value your privacy </h3>
              </div>
            </Col>
            <Col xl={18} md={12} className='bg-none'>
              <div className='cookies-left-content'>
                We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies .
                <a href="#" className="cky-policy" target="_blank">Cookie Policy</a></div>
            </Col>
            <Col xl={6} md={12} className='bg-none'>
              <div className='cookies-right d-flex justify-content-end align-items-center'>
                <Button type='button' className='cookie-btn bg-none' onClick={handlecookie}>Learn More</Button>
                <Button type='button' className='cookie-btn bg' onClick={handleaccept}>Accept All</Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    ) : ("")


  )
}
