import React from 'react'
import { Col, Row, Form, Input, Spin, Button } from 'antd'
import { CiUser } from "react-icons/ci";
import { CiMail } from "react-icons/ci";
import { FiPhone } from "react-icons/fi";

export default function BasicInformation({ onData, vendorDetails }) {
  const onFinish = (values) => {
    onData(values);
  };


  const onFinishFailed = () => { };
  return (
    <div className="vendor-register-basic-container">
      <h3 className='vendor-register-basic-heading'>Basic Details</h3>
      {vendorDetails === undefined ? (
        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Form
            initialValues={{ fullName: vendorDetails?.User?.name ? vendorDetails?.User?.name : vendorDetails?.user?.name, email: vendorDetails?.User?.email, phone_number: vendorDetails?.User?.phone }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className='basic-info-form'
            layout='vertical'
          >
            <Row>
              <Col md={12} className="basic-padding px-3">
                <Form.Item
                  className="form-input-class"
                  name="fullName"
                  label="Full Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter full name.",
                    },
                  ]}
                >
                  <Input className="input-box-login fullname" placeholder='Enter your name here' prefix={<CiUser color='#667085' />} />
                </Form.Item>
              </Col>
              <Col md={12} className="basic-padding px-3">
                <Form.Item
                  className="form-input-class"
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: "Email is not valid",
                    },
                  ]}
                >
                  <Input className="input-box-login email" placeholder='Enter  your email' prefix={<CiMail color='#667085' />} />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="basic-padding px-3">
                <Form.Item
                  className="form-input-class"
                  name="phone_number"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter phone number.",
                    },
                  ]}
                >
                  <Input className="input-box-login phoneNumber" placeholder='please input phone number' prefix={<FiPhone color='#667085' />} />
                </Form.Item>
              </Col>

            </Row>
            <Form.Item
              className="mb-0"
              wrapperCol={{
                offset: 8,
                span: 24,
              }}
            >
              <Button
                className="register-button button-register"
                htmlType="submit"
              >
                Next
              </Button>
            </Form.Item>

          </Form>
        </>
      )}

    </div>
  )
}
