import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer/footer";
import {
    Row,
    Col,
    Input,
    Card,
    Pagination,
    Button,
    Flex,
    Spin,
    Modal
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
// import { FaChevronDown } from "react-icons/fa";
// import { IoFilterOutline } from "react-icons/io5";
import { ArrowUpOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import * as getServices from "../../services/getServices";
import { useDispatch } from "react-redux";
import { FaLock } from "react-icons/fa";
import { FaUnlock } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Payment from "../paymentPrcess/index";
import * as uploadService from "../../services/uploadServices";
import Swal from "sweetalert2"
import { IoIosArrowRoundBack } from "react-icons/io";

import {
    featureImg1,
    featureImg2,
    featureImg3,
    arrowUpRight,
    RecentCourseImageOne,
    RecentCourseImageThree,
    RecentCourseImageTwo,
} from "../../constant/images";
export default function SearchPage() {
    window.scrollTo(0, 0);
    const userData = useSelector((state) => state.user.userData);
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { state } = useLocation();
    const [current, setCurrent] = useState(1);
    const { Search } = Input;
    const pageSize = 6;
    const [courses, setCourses] = useState([]);
    const [paymentDetails, setPaymentDetails] = useState();
    const [categories, setCategories] = useState();
    const [searchTerm, setSearchTerm] = useState("");
    const [matchedCourses, setMatchedCourses] = useState([]);
    const [loader, setLoader] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [paymentCourseData, setPaymentCourseData] = useState();
    const [wishlistData, setWishlistData] = useState([])


    const getWishlistData = () => {
        dispatch(getServices.GetWistlistDataUser(userData?.user_id
            ? userData?.user_id
            : userData?.User?.id
                ? userData?.User?.id
                : userData?.user?.id)).then((response) => {
                    setWishlistData(response?.course_details)
                }).catch((error) => {
                    console.error({ error });
                })
    }




    useEffect(() => {
        dispatch(getServices?.getAllCourses())
            .then((response) => {
                setCourses(response?.courses);
            })
            .catch((err) => {
                console.error({ err });
            });
        dispatch(
            getServices?.getPaymentDetails(
                userData?.user_id
                    ? userData?.user_id
                    : userData?.User?.id
                        ? userData?.User?.id
                        : userData?.user?.id
            )
        )
            .then((response) => {
                setPaymentDetails(response?.payments);
            })
            .catch((err) => {
                console.error({ err });
            });
        dispatch(getServices.getCategories())
            .then((response) => {
                setCategories(response?.categories);
            })
            .catch((err) => {
                console.error({ err });
            });
        getWishlistData()
    }, []);

    useEffect(() => {
        setLoader(true);
        filterCourses(courses);
    }, [state, courses]);

    const filterCourses = (courseList) => {
        setLoader(true);
        let filteredCourses;

        if (state?.subCategory !== null && state?.childCategory === null && state?.courseName === null) {
            filteredCourses = courseList?.filter(
                (course) =>
                    course?.category_id === state?.subCategory?.id
            );
        } else if (state?.subCategory !== null && state?.childCategory !== null && state?.courseName === null) {
            filteredCourses = courseList?.filter(
                (course) =>
                    course?.category_id === state?.subCategory?.id && course?.sub_category_id === state?.childCategory?.id
            );
        } else if (state?.subCategory === null && state?.childCategory === null && state?.courseName !== null) {
            filteredCourses = courseList?.filter(
                (course) =>
                    course.title
                        ?.toLowerCase()
                        ?.includes(state?.courseName?.toLowerCase())
            );
        }
        setMatchedCourses(filteredCourses);
        setLoader(false);
    };

    const handleWishlistList = (course) => {

        let formData = new FormData();

        formData.append("user_id", userData?.user_id
            ? userData?.user_id
            : userData?.User?.id
                ? userData?.User?.id
                : userData?.user?.id);
        formData.append("course_id", course?.id)

        try {

            dispatch(uploadService.addRemoveCourseWishlist(formData)).then((response) => {
                getWishlistData()
                Swal.fire(
                    "Success!",
                    `${response?.message}`,
                    "success"
                )
            }).catch((error) => {
                getWishlistData()
                Swal.fire(
                    "Failed",
                    "Unable to add item to wishlist.",
                    "error"
                )
            })

        } catch (e) {
            Swal.fire(
                "Failed",
                "Unable to add course to wishlist.",
                "error"
            )
        }

    }

    const Category = (category_id) => {
        const category = categories?.find(
            (category) => category?.id === category_id
        );
        return category?.name;
    };

    const handleCourseData = (values) => {
        navigate(`/course/${values?.id}/${values?.title}`, {
            state: { courseData: values },
        });
    };

    const handlePurchase = (coursedata) => {
        setPaymentCourseData(coursedata)
        setIsModalOpen(true)
    };

    useEffect(() => {
        // let name = state?.courseName || state?.category?.name || "";
        // if (state?.subCategory?.name) {
        //     name += ` / ${state.subCategory.name}`;
        // }
        // if (state?.childCategory?.name) {
        //     name += ` / ${state.childCategory.name}`;
        // }
        // setSearchTerm(name); 
        const name = state?.courseName || "";
        setSearchTerm(name);
    }, [state]);
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);

        if (!event.target.value) {
            handleClear();
        }
    };

    const startIndex = (current - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const displayedCourses = matchedCourses?.slice(startIndex, endIndex);

    const onChange = (page) => {
        setCurrent(page);
    };

    const handleSearchClick = () => {
        setLoader(true);
        const filteredCourses = courses?.filter((course) =>
            course?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        );
        setMatchedCourses(filteredCourses);
        setLoader(false);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const DateFormatter = (date) => {
        const inputDate = date;

        const dateObj = new Date(inputDate);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };
    const handleBack = () => {
        navigate("/");
    }

    const handleClear = () => {
        setSearchTerm("");
        setMatchedCourses([]);


    };

    const handleFreeCourse = (courseData) => {
        try {
            const formData = new FormData();
            formData.append(
                "user_id",
                parseInt(
                    userData?.user_id
                        ? userData?.user_id
                        : userData?.id
                            ? userData?.id
                            : userData?.User?.id,
                )
            );
            formData.append(
                "course_id", courseData?.id
            );
            formData.append("email", userData?.email
                ? userData?.email
                : userData?.user?.email
                    ? userData?.user?.email
                    : userData?.User?.email ? userData?.User?.email : userData?.user_email);
            formData.append("amount", 0);
            formData.append("course_fee", 0);
            dispatch(uploadService.payment(formData)).then((response) => {
                Swal.fire(
                    "Items Enrolled Successfully",
                    "You have been successfully enrolled in the Items.",
                    "success"
                )

            }).catch((error) => {
                Swal.fire("Failed", error.message);
            })
        } catch (e) {
            console.error('Error in free item purchase', e)
            return
        }
    }

    return (
        <>
            <Header />
            <div className="search-page-outer-container">
                <Button className="back-to-button" onClick={handleBack}> <IoIosArrowRoundBack />Back to home</Button>
                <div className="search-page-inner-container container">
                    <div className="search-input-container">

                        <h5>
                            {state?.category?.name}
                            {state?.subCategory?.name && `/${state.subCategory.name}`}
                            {state?.childCategory?.name && `/${state.childCategory.name}`}
                        </h5>

                        <Search
                            value={searchTerm}
                            onChange={handleInputChange}
                            onSearch={() => handleSearchClick()}
                            className="search-input"
                            placeholder="Search"
                            allowClear
                        />

                        <p className="search-result-text">
                            {matchedCourses !== undefined ? matchedCourses?.filter((course) => course.status !== "pending")?.length : state?.cartegoryPageData?.filter((course) => course.status !== "pending")?.length} results found
                        </p>
                    </div>
                    <div className="filter-result-container">
                        <Row className="courses-with-filter-container customRowComponent">
                            <Col className="course-list-child-one" md={24}>
                                <Row justify={'center'}>
                                    {loader ? (
                                        <div className="search-loader-container">
                                            <Spin
                                                indicator={
                                                    <LoadingOutlined
                                                        style={{
                                                            fontSize: 48,
                                                        }}
                                                        spin
                                                    />
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            {(matchedCourses ? matchedCourses : state?.cartegoryPageData)?.filter((course) => course.status !== "pending")?.map((course) => {
                                                return (
                                                    <>
                                                        <Col
                                                            className=""
                                                            style={{ backgroundColor: "#FFF" }}
                                                            xl={8}
                                                            md={24}
                                                        >
                                                            <Card
                                                                className="feature-card"
                                                                hoverable
                                                                cover={
                                                                    <img
                                                                        style={{ height: "250px", cursor: "pointer" }}
                                                                        onClick={(e) => handleCourseData(course)}
                                                                        src={
                                                                            course?.image
                                                                                ? course?.image?.includes("https") ? course?.image : `https://welloffun-api.aleaspeaks.com/storage/${course?.image}`
                                                                                : featureImg2
                                                                        }
                                                                        alt="text"
                                                                    />
                                                                }
                                                            >
                                                                <div onClick={(e) => handleCourseData(course)}>
                                                                    <div className="card-position-div">
                                                                        <div
                                                                            style={{
                                                                                // position: "absolute",
                                                                                // left: "0",
                                                                                // top: "2rem",
                                                                                zIndex: "99",
                                                                                padding: "10px 15px",
                                                                                backgroundColor: "#106ab3",
                                                                                borderRadius: "0 10px 10px 0",
                                                                            }}
                                                                            className="expire-container"
                                                                        >
                                                                            {paymentDetails?.some(
                                                                                (item) => item.course_id === course?.id
                                                                            ) ? (
                                                                                <span className="Lock-icon">
                                                                                    <FaUnlock color="#fff" />
                                                                                </span>
                                                                            ) : (
                                                                                <span className="Lock-icon">
                                                                                    <FaLock color="#fff" />
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                        <div
                                                                            style={{

                                                                                zIndex: "99",
                                                                                padding: "10px 10px",
                                                                                backgroundColor: "#106ab3",
                                                                                borderRadius: "10px 0 0 10px",
                                                                            }}
                                                                            className="expire-container"
                                                                        >
                                                                            <div className="start-end-date-container">
                                                                                <p
                                                                                    style={{ color: "#fff", margin: 0 }}
                                                                                    className="start-enddate"
                                                                                >
                                                                                    {course?.course_start &&
                                                                                        course?.course_end
                                                                                        ? `${DateFormatter(
                                                                                            course?.course_start
                                                                                        )} - ${DateFormatter(
                                                                                            course?.course_end
                                                                                        )}`
                                                                                        : "12 July 2024 - 15 August 2025"}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <Flex
                                                                            gap="middle"
                                                                            className="feature-course-subhead-price"
                                                                        >
                                                                            <div>
                                                                                <p
                                                                                    style={{
                                                                                        color: "#106AB3",
                                                                                        fontWeight: 700,
                                                                                    }}
                                                                                >
                                                                                    {Category(course?.category_id) ? Category(course?.category_id) : "Web Development"}
                                                                                </p>
                                                                            </div>
                                                                            <div>
                                                                                <p
                                                                                    style={{
                                                                                        color: "#1EAA39",
                                                                                        fontWeight: 700,
                                                                                    }}
                                                                                >
                                                                                    ${course?.fee}
                                                                                </p>
                                                                            </div>
                                                                        </Flex>
                                                                        <Flex className="feature-course-subhead-price">
                                                                            <h4>{course?.title}</h4>

                                                                        </Flex>
                                                                    </div>
                                                                    <div>
                                                                        <p className="course-about">{course?.about_course}</p>
                                                                    </div>
                                                                </div>
                                                                <Flex className="feature-course-subhead-price">
                                                                    {paymentDetails?.some(
                                                                        (item) => item?.course_id == course?.id
                                                                    ) ? (
                                                                        <>
                                                                            <button
                                                                                type="button"
                                                                                class="btn feature-button view-course-button"
                                                                                onClick={(e) => handleCourseData(course)}
                                                                            >
                                                                                View Items
                                                                            </button>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <button
                                                                                type="button"
                                                                                class="btn feature-button add-to-wishlist"
                                                                                onClick={() => handleWishlistList(course)}
                                                                            >
                                                                                {wishlistData?.some((wish) => parseInt(wish?.id) === parseInt(course?.id))
                                                                                    ? "Remove Wishlist"
                                                                                    : "Add to Wishlist"
                                                                                }
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                class="btn feature-button enroll-button"
                                                                                onClick={() => course?.is_premium === "free" ? handleFreeCourse(course) : handlePurchase(course)}
                                                                            >
                                                                                Purchase Now
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                </Flex>
                                                            </Card>
                                                        </Col>
                                                    </>
                                                );
                                            })}
                                        </>
                                    )}
                                </Row>
                                {matchedCourses?.length > 0 ? (
                                    <Pagination
                                        className="pagination"
                                        current={current}
                                        onChange={onChange}
                                        total={displayedCourses?.length}
                                        pageSize={pageSize}
                                    />
                                ) : (
                                    ""
                                )}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                className="paymentModal"
                centered
                width={1140}
                footer={false}
            >
                <Payment paymentCourseData={paymentCourseData} />
            </Modal>

            <Footer />


        </>
    );
}
