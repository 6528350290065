import React, { useState, useEffect } from "react";
import Sidebar from "../../../components/Sidebar/sidebar";
import { Button, Tabs, Input, Card, Col, Row, Image, Spin } from "antd";
import { MyCOurseImg } from "../../../constant/images";
import { BsFillBarChartFill } from "react-icons/bs";
import { FaCopy } from "react-icons/fa";
import { RiGraduationCapFill } from "react-icons/ri";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEye } from "react-icons/fi";
import TopNavBar from "../../../components/topNavbar";
import { useDispatch, useSelector } from "react-redux";
import * as getServices from "../../../services/getServices";
import { LoadingOutlined } from '@ant-design/icons';
import * as updateServices from "../../../services/updateServices"
import Swal from "sweetalert2"
import { useNavigate } from "react-router";
import CopyWriteFooter from "../../../components/Footer/copywriteFooter"


export default function MyCourses() {
  const userData = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();
  const { Search } = Input;
  const [courses, setCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [inProgressCourses, setInProgressCourses] = useState([]);
  const [completedCourses, setCompletedCourses] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);

  const [loader, setLoader] = useState(true);
  const navigate = useNavigate()


  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesResponse = await dispatch(getServices.getCategories());
        setCategories(categoriesResponse?.categories);

        const paymentDetailsResponse = await dispatch(getServices.getPaymentDetails(userData?.user_id ? userData?.user_id : userData?.id));
        setPaymentDetails(paymentDetailsResponse?.payments);

        // Fetch course data only if payment details are available
        if (paymentDetailsResponse?.payments?.length > 0) {
          await getAllCourseData(paymentDetailsResponse?.payments);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoader(false); // Ensure loader is turned off at the end
      }
    };

    fetchData();
  }, [dispatch, userData]);

  const fetchCourseData = async (course_id) => {
    try {
      const response = await dispatch(getServices.getCourseByCourseID(course_id));
      return response?.course;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getAllCourseData = async (payments) => {
    const dataPromises = payments.map(async (payment) => {
      if (payment?.status !== "0") {
        const courseData = await fetchCourseData(payment?.course_id);
        if (courseData) {
          return { ...courseData, payment_id: payment.id };
        }
      }
      return null;
    });

    const allData = await Promise.all(dataPromises);
    const filteredData = allData.filter((data) => data !== null);
    setCourses((prevState) => [...prevState, ...filteredData]);
  };


  const Category = (category_id) => {
    const category = categories?.find(
      (category) => category?.id === category_id
    );
    return category?.name;
  };




  const handleBuyCourse = () => {
    navigate('/')
  }
  const SearchBar = (
    <Search
      className="search-input"
      placeholder="Search by item name or category"
    />
  );

  const handleDeleteCourse = (value) => {
    Swal.fire({
      title: "Delete items",
      text: "Are you sure you want to delete?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(updateServices.deleteCourseUser(value?.payment_id))
            .then((response) => {
              Swal.fire("Success", "items deleted successfully", "success");
            })
            .then(() => {
              window.location.reload();
            })
            .catch((error) => {
              Swal.fire("Failed", "Unable to delete item.", "error");
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your item is safe :)", "error");
        }
      })
      .catch((error) => {
        Swal.fire("Failed", "Unable to perform the action.", "error");
      });
  }




  const compareDates = (course) => {
    let currentDate = new Date().toJSON().slice(0, 10);
    let date1 = new Date(course?.[0]?.expire_date).getTime();
    let date2 = new Date(currentDate).getTime();

    if (date1 > date2 || date1 === date2) {
      setInProgressCourses((prevState) => [...prevState, course]);
    } else if (date1 < date2) {
      setCompletedCourses((prevState) => [...prevState, course]);
    }
  };

  useEffect(() => {
    courses.forEach((course) => {
      compareDates(course);
    });
  }, [courses]);



  const items = [
    {
      key: "1",
      label: "All Items",
      children: (
        <div className="tab-content-container">
          <Row className="tab-content-row">
            {loader ? (
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
              </div>
            ) : (
              courses.length > 0 ? (
                courses && courses.map((course) => {
                  return (
                    <Col className="tab-content-col" md={24}>
                      <Card className="tab-content-card" hoverable>
                        <Row className="card-row">
                          <Col className="card-col-one" md={6}>
                            <Image preview={false} src={course?.image !== null ? course?.image : MyCOurseImg} />
                          </Col>
                          <Col className="card-col-two" md={13}>
                            <p className="course-name">{course?.category?.name}</p>
                            <p className="sub-heading">{course?.title}</p>
                            <p className="description-course">
                              {course?.about_course}
                            </p>

                            <Row className="students-level-lesson-row">
                              <Col className="students-with-icon" md={8}>
                                <RiGraduationCapFill className="graguation-cap-icon" />
                                <p className="no-of-students">{course?.course_count} Students</p>
                              </Col>
                              <Col className="levels-with-icon" md={8}>
                                <BsFillBarChartFill className="bar-chart-icon" />
                                <p className="all-levels">All levels</p>
                              </Col>
                              {/* <Col className="lessons-with-icon" md={8}>
                        <FaCopy className="copy-icon" />
                        <p className="no-of-lessons">20 Lessons</p>
                      </Col> */}
                            </Row>
                          </Col>
                          <Col className="card-col-three" md={5}>
                            <span className="delete-span">
                              <RiDeleteBin6Line onClick={(e) => handleDeleteCourse(course)} className="delete-icon" />
                            </span>
                            <span className="view-span">
                              <FiEye className="view-icon" />
                            </span>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  )
                })
              ) : (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }} className="null-value-container">
                  <p className="no-data-text">No Data</p>
                </div>
              )
            )}



            {/* <Col className="tab-content-col" md={24}>
              <Card className="tab-content-card" hoverable>
                <Row className="card-row">
                  <Col className="card-col-one" md={6}>
                    <Image preview={false} src={MyCOurseImg} />
                  </Col>
                  <Col className="card-col-two" md={13}>
                    <p className="course-name">Web Development</p>
                    <p className="sub-heading">Learn Web Developemt</p>
                    <p className="description-course">
                      Web development refers to the process of building and
                      maintaining websites and web applications.
                    </p>

                    <Row className="students-level-lesson-row">
                      <Col className="students-with-icon" md={8}>
                        <RiGraduationCapFill className="graguation-cap-icon" />
                        <p className="no-of-students">156 Students</p>
                      </Col>
                      <Col className="levels-with-icon" md={8}>
                        <BsFillBarChartFill className="bar-chart-icon" />
                        <p className="all-levels">All levels</p>
                      </Col>
                      <Col className="lessons-with-icon" md={8}>
                        <FaCopy className="copy-icon" />
                        <p className="no-of-lessons">20 Lessons</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="card-col-three" md={5}>
                    <span className="delete-span">
                      <RiDeleteBin6Line className="delete-icon" />
                    </span>
                    <span className="view-span">
                      <FiEye className="view-icon" />
                    </span>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col className="tab-content-col" md={24}>
              <Card className="tab-content-card" hoverable>
                <Row className="card-row">
                  <Col className="card-col-one" md={6}>
                    <Image preview={false} src={MyCOurseImg} />
                  </Col>
                  <Col className="card-col-two" md={13}>
                    <p className="course-name">Web Development</p>
                    <p className="sub-heading">Learn Web Developemt</p>
                    <p className="description-course">
                      Web development refers to the process of building and
                      maintaining websites and web applications.
                    </p>

                    <Row className="students-level-lesson-row">
                      <Col className="students-with-icon" md={8}>
                        <RiGraduationCapFill className="graguation-cap-icon" />
                        <p className="no-of-students">156 Students</p>
                      </Col>
                      <Col className="levels-with-icon" md={8}>
                        <BsFillBarChartFill className="bar-chart-icon" />
                        <p className="all-levels">All levels</p>
                      </Col>
                      <Col className="lessons-with-icon" md={8}>
                        <FaCopy className="copy-icon" />
                        <p className="no-of-lessons">20 Lessons</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="card-col-three" md={5}>
                    <span className="delete-span">
                      <RiDeleteBin6Line className="delete-icon" />
                    </span>
                    <span className="view-span">
                      <FiEye className="view-icon" />
                    </span>
                  </Col>
                </Row>
              </Card>
            </Col> */}
          </Row>
        </div>
      ),
    },
  ];
  return (
    <>
      <TopNavBar />
      <Row>
        <Col xl={4} className="d-xl-block d-md-none">
          <Sidebar />
        </Col>
        <Col xl={20} md={24}>
          <div className="mainContent">
            <div className="head-container">
              <div className="heading-div">
                <p className="my-course-text">My Items</p>
                <p className="good-morning-text">Good Morning John!</p>
              </div>
              <div className="buy-course-container">
                <Button className="buy-course-button" type="primary" onClick={handleBuyCourse}>Buy Items</Button>
              </div>
              <div className="add-new-course-container">
                {/* <Button className="add-new-button">Add New</Button> */}
              </div>
            </div>
            <div className="tabs-container">
              <Tabs
                className="my-courses-tabs"
                defaultActiveKey="1"
                items={items}
                tabBarExtraContent={SearchBar}
              />
            </div>
          </div>
          <CopyWriteFooter />
        </Col>
      </Row>
    </>
  );
}
