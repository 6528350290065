import React, { useState, useEffect } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer/footer";
import { Modal, Button, Switch } from "antd";
import RecentCourse from "./RecentCourse";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import * as getServices from "../../services/getServices";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Payment from "../paymentPrcess/index";
import BannerSection from "./banner";
import CategorySlider from "./categorySlider";
import SponsoredCourses from "./sponsoredCourese";
import NewFeatureCourses from "./featureCourses"

export default function Home() {
    const userData = useSelector((state) => state.user.userData);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [categories, setCategories] = useState([]);
    const [courses, setCourses] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [paymentCourseData, setPaymentCourseData] = useState();
    const [freeCourses, setFreeCourses] = useState(false);

    useEffect(() => {
        dispatch(getServices?.getCategories())
            .then((response) => {
                setCategories(response?.categories);
            })
            .catch((err) => {
                console.error({ err });
            });

        dispatch(getServices?.getAllCourses())
            .then((response) => {
                setCourses(response?.courses);
            })
            .catch((err) => {
                console.error({ err });
            });
    }, []);

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleLoadMoreButton = () => {
        navigate("/course-listing")
    }


    const onChange = (checked) => {
        checked ===true ? setFreeCourses(true) : setFreeCourses(false)
    
    };


    return (
        <>
            <Header />
            <BannerSection />
            <div className="homePageCarousal">
                <CategorySlider />
                <div className="free-course-toggle-container">
                    <Switch className="free-course-toggle" checkedChildren="Free Items" unCheckedChildren="All Items" onChange={onChange} />
                </div>
                <SponsoredCourses freeCourses={freeCourses} courses={courses} categories={categories} isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen} setPaymentCourseData={setPaymentCourseData} />
                <div className="load-more-container">
                    <Button onClick={handleLoadMoreButton} className="load-more-button">View more...</Button>
                </div>

                {/* <NewFeatureCourses freeCourses={freeCourses} courses={courses} categories={categories} isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen} setPaymentCourseData={setPaymentCourseData} /> */}
            </div>
            <RecentCourse freeCourses={freeCourses} />
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                className="paymentModal"
                centered
                width={1140}
                footer={false}
            >
                <Payment paymentCourseData={paymentCourseData} />
            </Modal>
            <Footer />
        </>
    );
}
