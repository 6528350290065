import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { DEMO_URL } from "../../Helper";
import * as getServices from "../../services/getServices";
import * as uploadService from "../../services/uploadServices";
import { Carousel } from "antd";



export default function CategorySlider() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        dispatch(getServices?.getCategories())
            .then((response) => {
                setCategories(response?.categories);
            })
            .catch((err) => {
                console.error({ err });
            });
    }, []);

    const settings = {
        dots: false,
        speed: 500,
        arrow: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
        autoplay: false,
        autoplaySpeed: 1000,
        arrows: categories?.length >= 5,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    speed: 3000,
                    autoplaySpeed: 5000,
                    autoplay: true,
                    infinite: true,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    autoplay: true,
                    infinite: true,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplay: true,
                    infinite: true,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    autoplay: true,
                    infinite: true,
                    slidesToScroll: 1
                }
            }
        ]
    };


    const handleSliderOptions = (category) => {
        dispatch(uploadService.getCoursesByCategoryID({ parent_id: category?.id })).then((response) => {
            navigate("/search", {
                state: { cartegoryPageData: response?.data },
            });
        }).catch((err) => {
            console.error({ err });
        })
    };
    const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    };

    // Chunk the categories into groups of 5
    const categoryChunks = chunkArray(categories, 5)
    return (
        <div className="container">
            <Slider className="slider-outer-1" {...settings}>
                {categories?.map((category) => (
                    <div
                        onClick={() => handleSliderOptions(category)}
                        className="slide-item"
                    >
                        <img src={`${DEMO_URL}/storage/${category?.logo}`} alt="text" />
                        <h3 className="img-title">{category?.name}</h3>
                    </div>
                ))}
            </Slider>
            {/* <Carousel arrows infinite={false} > */}
            {/* {categories?.map((category) => (
                    <div
                        onClick={() => handleSliderOptions(category)}
                        className="slide-item"
                    >
                        <img src={`${DEMO_URL}/storage/${category?.logo}`} alt="text" />
                        <h3 className="img-title">{category?.name}</h3>
                    </div>
                ))} */}
            {/* <Carousel slidesToShow={1} arrows infinite={false} className="slider-outer-1">
                {categoryChunks.map((chunk, index) => (
                    <div key={index}
                        className="slide-item">

                        {chunk.map((category) => (
                            <div
                                key={category.id}
                                onClick={() => handleSliderOptions(category)}

                            >
                                <img src={`${DEMO_URL}/storage/${category?.logo}`} alt={category.name} />
                                <h3 className="img-title">{category?.name}</h3>
                            </div>
                        ))}

                    </div>
                ))}
            </Carousel> */}
            {/* </Carousel> */}
        </div>
    );
}
