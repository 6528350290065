import React, {  } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer/footer';



// import './Faq.css'; // Import custom CSS for styling

export default function TermsAndConditions() {

  




  return (
    <>
      <Header />
    <div className='TermsAndConditions-banner'>
       <div className='container'>
        <h1>terms & condition</h1>
        <p className='last-Updated'>Last Updated 6th august, 2024</p>
       </div>
    </div>
    <div className='TermsAndConditions-content-section'>
        <div className='container'>
            <h3 className='section-heading'>Acceptance of Terms</h3>
            <p className='section-contant'>Welcome to “WELLOFFUN”, an educational website operated by Company/Organization Name. By accessing or using our website, you agree to be bound by these Terms and Conditions, as well as our Privacy Policy. If you do not agree with any part of these terms, you must not use our platform.</p>
            <h3 className='section-heading'>Use of the Platform</h3>
            <p className='section-contant'>Eligibility: By using our platform, you confirm that you are at least 13 years old or have obtained parental consent to use our services. If you are under the age of 18, you must have your parent or guardian read and agree to these terms on your behalf.
            Account Registration: To access certain features of our platform, you may be required to create an account. You agree to provide accurate and complete information during registration and to keep your account information updated. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You must notify us immediately of any unauthorized use of your account.
            User Conduct: You agree to use the platform only for lawful purposes and in accordance with these terms. You must not engage in any activity that could harm, disrupt, or interfere with the platform or its users. Prohibited activities include, but are not limited to:
            
            <ul>
                <li>Uploading or sharing content that is unlawful, harmful, defamatory, or otherwise objectionable.</li>
                <li>Attempting to gain unauthorized access to the platform or its systems.</li>
                <li>Impersonating another person or entity.</li>
                <li>Engaging in any form of harassment or abusive behavior.</li>
            </ul>
            </p>
            <p className='section-contant'>Our platform may contain links to third-party websites or services that are not owned or controlled by Company/Organization Name. We are not responsible for the content, privacy policies, or practices of any third-party sites. Your use of such sites is at your own risk, and we encourage you to review the terms and policies of any third-party sites you visit.</p>
        </div>
    </div>
      <Footer />
    </>
  );
}
