import React from 'react';
import {Row,Col,Image,Form,Input,Button} from "antd";
import {ResetPasswordImage,Logo} from "../../constant/images";
import { useNavigate } from "react-router-dom";
import { CiLock } from "react-icons/ci";
import { FaTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { IoLogoInstagram } from "react-icons/io";
import { FaGithub } from "react-icons/fa";
import * as UploadService from "../../services/uploadServices";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import Swal from "sweetalert2";

export default function ResetPassword() {
    const userData = useSelector((state) => state.user.userData);
    const { token } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const forgetEmail = localStorage.getItem('forgetEmail');


    
    const onFinish = (values) => {
      const formData = new FormData();

      formData.append("email",forgetEmail?forgetEmail:userData?.user_email);
      formData.append("token",token);
      formData.append("password",values?.password);
      formData.append("password_confirmation",values?.confirm_password);


      dispatch(UploadService.resetPassword(formData)).then((response)=>{
        Swal.fire(
            "Success!",
            "Your password has been successfully updated.",
            "success"
          ).then(()=>{
            navigate('/login')
          });
      }).catch((err)=>{
        Swal.fire(
            "Something went wrong!",
            "Please try again later.",
            "error"
          );
      })
    };

    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };
  return (
    <div className='reset-password-container'>
      <div className='reset-password-inner-container'>
          <Row className="reset-password-row">
             <Col className='reset-password-col-one' md={12}>
             <Image preview={false} className='logo-image' src={Logo}/>
             <p className='enter-password-text'>Enter Your New Password</p>
             <p className='different-old-password-text'>Your new password must be different from old password. </p>
             <Form
                  name="basic"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  style={{
                    maxWidth: 600,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                 <p
                  style={{
                    textAlign: "start",
                    fontFamily: "POPPINS",
                  }}
                >
                  Enter New Password
                </p>
                  <Form.Item
                  className="form-input-class"
                    name="password"
                    rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    {
                      min: 6,
                      message: "Password must be at least 6 characters long!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || /[!@#$%^&*(),.?":{}|<>]/.test(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "Password must contain at least one special character!"
                          )
                        );
                      },
                    }),
                  ]}
                  >
                    <Input.Password type="password" prefix={<CiLock  color="#667085"/>} placeholder="Enter new password" className="input-box-login" />
                  </Form.Item>

                  <p
                  style={{
                    textAlign: "start",
                    fontFamily: "POPPINS",
                  }}
                >
                 Confirm New Password
                </p>
                  <Form.Item
                  className="form-input-class"
                    name="confirm_password"
                    rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },

                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The new password that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                  >
                    <Input.Password type="password" prefix={<CiLock  color="#667085"/>} placeholder="Confirm new password" className="input-box-login" />
                  </Form.Item>
                  
                 

                  <Form.Item
                 
                    wrapperCol={{
                      offset: 8,
                      span: 24,
                    }}
                  >
                    <Button className="reset-password-button" htmlType="submit">
                    Reset Password
                    </Button>
                  </Form.Item>
              <div className="have-account">
                <p style={{color:"white", fontFamily:'poppins',textAlign:"center", fontSize:"13px",fontWeight:"500",lineHeight:"21px"}}>
                Don't have an account? <span style={{cursor:"pointer"}} onClick={()=> navigate("/register")}>Sign Up</span>
                </p>
              </div>
                </Form>

                <div className='social-conatiner'>
                <p className='follow-us'>Follow Us :-</p>
                <div className='icon-container'>
                <p className='twitter-container'><FaTwitter className='twitter-icon' /></p>
                <p className='facebook-container'><FaFacebookF className='facebook-icon' /></p>
                <p className='instagram-comntainer'><IoLogoInstagram className='instagram-icon' /></p>
                <p className='github-container'><FaGithub className='github-icon' /></p>
                </div>
                </div>
             </Col>
             <Col className='reset-password-col-two' md={12}>
                <Image preview={false} className='reset-password-image' src={ResetPasswordImage}/>
             </Col>
          </Row>
      </div>
    </div>
  )
}
