import Http from "../Http";
import { DEMO_URL } from "../Helper";

export function vendorDetails(value) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.get(DEMO_URL + `/api/vendor-detail/${value}`)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }

  export function vendorUpdate(value) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.post(DEMO_URL + "/api/vendor-update", value)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }