import React from 'react';
import { Button, Result } from 'antd';
import { useLocation,useNavigate } from "react-router-dom";

export default function Thankyou() {
    const navigate = useNavigate();
    let { state } = useLocation();
  return (
    <div className='thankyou-container'>
      <Result
        status="success"
        title="Payment Successfully Done!"
        subTitle={`Transaction Id: ${state?.transaction_id}`}
        extra={[
          <Button onClick={()=>navigate("/")} type='primary' className='go-to-button' key="console">
           Go to Items
          </Button>
        ]}
      />
    </div>
  );
}
