import React, { useEffect, useState } from 'react'
import { Row, Col, Table, Button } from "antd";
import Sidebar from "../../../components/Sidebar/sidebar";
import { IoMdCopy } from "react-icons/io";
import TopNavBar from '../../../components/topNavbar';
import * as getServices from "../../../services/getServices";
import { useDispatch, useSelector } from 'react-redux';

export default function MyCoupons() {

    const userData = useSelector((state) => state.user.userData);
    const dispatch = useDispatch();

    const [coupons, setCoupons] = useState([]);
    const [activeCoupons, setActiveCoupons] = useState([]);
    const [expiredCoupons, setExpiredCoupons] = useState([]);

    useEffect(() => {
        dispatch(getServices.getCouponUser(
            userData?.user_id
                ? userData?.user_id
                : userData?.id
                    ? userData?.id
                    : userData?.User?.id
        )).then((response) => {
            setCoupons(response?.coupon)

            const activeCoupons = response?.coupon.filter(coupon => coupon.coupon_status === "active");
            setActiveCoupons(activeCoupons);

            const expiredCoupons = response?.coupon.filter(coupon => coupon.coupon_status === "expired");
            setExpiredCoupons(expiredCoupons);
        })
    }, [])

    const DateFormatter = (date) => {
        const inputDate = date;

        const dateObj = new Date(inputDate);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };

    const columns1 = [
        {
            title: "S.No.",
            dataIndex: "S.No.",
            key: "S.No.",
            render: (text, record, index) => (
                <p className="sno-text">
                    {String(index + 1).padStart(2, "0")}
                </p>
            ),

        },
        {
            title: "Code",
            dataIndex: "Code",
            key: "Code",
            render: (text, record) => (
                <>
                    <div className='coupon-code-copy'>
                        <p className='coupon-code'>{record?.coupon_code}</p>
                        <IoMdCopy color='#106AB3' />
                    </div>
                </>
            ),

        },
        {
            title: "Discount",
            dataIndex: "Discount",
            key: "Discount",
            render: (text, record) => (
                <>
                    <p className='coupon-code'>{record?.percentage ? `${record?.percentage}%` : `$${record?.max_discount}`}</p>
                </>
            ),

        },
        {
            title: "Exp. Date",
            dataIndex: "Exp. Date",
            key: "Exp. Date",
            render: (text, record) => (
                <>
                    <p className='coupon-code'>{DateFormatter(record?.end_date)}</p>
                </>
            ),

        },
        {
            title: "Coupon Status",
            dataIndex: "Coupon Status",
            key: "Coupon Status",
            render: (text, record) => (
                <div className='text-center d-flex justify-content-center'>
                    <Button className='copy-button'>Active</Button>
                </div>
            ),
        },
    ];

    const columns2 = [
        {
            title: "S.No.",
            dataIndex: "S.No.",
            key: "S.No.",
            render: (text, record, index) => (
                <p className="sno-text">
                    {String(index + 1).padStart(2, "0")}
                </p>
            ),

        },
        {
            title: "Code",
            dataIndex: "Code",
            key: "Code",
            render: (text, record) => (
                <>
                    <div className='coupon-code-copy'>
                        <p className='coupon-code'>{record?.coupon_code}</p>
                        <IoMdCopy color='#106AB3' />
                    </div>
                </>
            ),

        },
        {
            title: "Discount",
            dataIndex: "Discount",
            key: "Discount",
            render: (text, record) => (
                <>
                    <p className='coupon-code'>{record?.percentage ? `${record?.percentage}%` : `$${record?.max_discount}`}</p>
                </>
            ),

        },
        {
            title: "Exp. Date",
            dataIndex: "Exp. Date",
            key: "Exp. Date",
            render: (text, record) => (
                <>
                    <p className='coupon-code'>{DateFormatter(record?.end_date)}</p>
                </>
            ),

        },
        {
            title: "Coupon Status",
            dataIndex: "Coupon Status",
            key: "Coupon Status",
            render: (text, record) => (
                <div className='text-center d-flex justify-content-center'>
                    <Button style={{ backgroundColor: "#E50000" }} className='copy-button'>Expired</Button>
                </div>
            ),
        },
    ];

    return (
        <>
            <TopNavBar />
            <Row>
                <Col xl={4} className="d-xl-block d-md-none">
                    <Sidebar />
                </Col>
                <Col xl={20} md={24}>
                    <div className='coupons-container my-coupons'>
                        {activeCoupons.length > 0 ? (
                            <div className="coupons-container-first-child">
                                <h2 className="coupons-first-head">My Coupons</h2>
                                <Row>
                                    <Col md={24}>
                                        <Table
                                            className="table table-responsive"
                                            pagination={{ pageSize: 5 }}
                                            columns={columns1}
                                            dataSource={activeCoupons}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        ) : (<></>)}

                        {expiredCoupons.length > 0 ? (
                            <div className="coupons-container-first-child">
                                <h2 className="coupons-first-head">Expired Coupons</h2>
                                <Row>
                                    <Col md={24}>
                                        <Table
                                            className="table table-responsive expired-Coupons"
                                            pagination={{ pageSize: 5 }}
                                            columns={columns2}
                                            dataSource={expiredCoupons}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        ) : (<></>)}

                    </div>

                </Col>
            </Row>
        </>
    )
}
