import React, { useState } from "react";
import { Form, Input, Row, Col, Button, Select } from "antd";
import TopNavBar from "../../components/topNavbar";
import Sidebar from "../../components/Sidebar/sidebar";
import { MdOutlineCategory } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import * as uploadServices from "../../services/uploadServices";

export default function AddClasses() {
  const vendorData = useSelector((state) => state.user.userData);
  const [addClass, setAddClass] = useState(false);
  const { TextArea } = Input;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onFinish = (values) => {
    setAddClass(true)
    const formData = new FormData();
    formData.append("vender_id", vendorData?.vendor?.id
      ? vendorData?.vendor?.id
      : vendorData?.vendor_id
        ? vendorData?.vendor_id
        : vendorData?.Vendor?.id);
    formData.append("name", values?.class_name);
    formData.append("min_size", values?.min_student_age);
    formData.append("max_size", values?.max_student_age);
    formData.append("class_size", values?.class_size);
    formData.append("class_level", values?.class_level);
    formData.append("description", values?.class_description);
    formData.append("status", values?.status);
    dispatch(uploadServices.addClasses(formData))
      .then((response) => {
        Swal.fire({
          title: response.message,
          icon: "success",
        })

          .then((result) => {
            navigate("/vendor/my-classes");
          });
      })
      .catch((error) => {
        setAddClass(false)

        Swal.fire({

          icon: "error",
          title: error.response.data.error
        }

        );
      });
  };
  const onFinishFailed = () => { };
  return (
    <>
      <TopNavBar />
      <Row>
        <Col xl={4} className="d-xl-block d-md-none">
          <Sidebar />
        </Col>
        <Col xl={20} md={24}>
          <div className="mainContent  classes">
            <div className="add-classes-container">
              <div className="add-classes-head-container">
                <p style={{ fontSize: "26px", lineHeight: "31.2px", fontWeight: "600", fontFamily: "Poppins", margin: "2rem 0x" }} className="add-classes-heading">Add Session</p>
              </div>
              <div>
                <Form
                  name="basic"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  className="add-new-course-form"
                  layout="vertical"
                >
                  <Row className="form-row">
                    <Col md={12} className="custom-padding">
                      <Form.Item
                        className="form-input-class"
                        name="class_name"
                        label="Session Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter session name.",
                          },
                        ]}
                      >
                        <Input
                          className="input-box-login"
                          placeholder="Session Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} className="custom-padding">
                      <Form.Item
                        className="form-input-class"
                        name="status"
                        label="Status"
                        rules={[
                          {
                            required: true,
                            message: "Please select status",
                          },
                        ]}
                      >
                        <Select
                          className="input-box-login select-class-status"
                          placeholder="Select Status"
                          prefix={<MdOutlineCategory />}
                        >
                          <Select.Option value={1} key={"Active"}>
                            Active
                          </Select.Option>
                          <Select.Option value={0} key={"In-Active"}>
                            In Active
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={12} className="custom-padding">
                      <Form.Item
                        className="form-input-class"
                        name="class_size"
                        label="Avaiability"
                        rules={[
                          {
                            required: true,
                            message: "Please select avaiability ",
                          },
                        ]}
                      >
                        <Select
                          className="input-box-login select-class-size"
                          placeholder="Select avaiability"
                          prefix={<MdOutlineCategory />}
                        >
                          <Select.Option value={1} key={1}>
                            1
                          </Select.Option>
                          <Select.Option value={2} key={2}>
                            2
                          </Select.Option>
                          <Select.Option value={3} key={3}>
                            3
                          </Select.Option>
                          <Select.Option value={4} key={4}>
                            4
                          </Select.Option>
                          <Select.Option value={5} key={5}>
                            5
                          </Select.Option>
                          <Select.Option value={6} key={6}>
                            6
                          </Select.Option>
                          <Select.Option value={7} key={7}>
                            7
                          </Select.Option>
                          <Select.Option value={8} key={8}>
                            8
                          </Select.Option>
                          <Select.Option value={9} key={9}>
                            9
                          </Select.Option>
                          <Select.Option value={10} key={10}>
                            10
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={12} className="custom-padding">
                      <Form.Item
                        className="form-input-class"
                        name="class_level"
                        label="Level"
                        rules={[
                          {
                            required: true,
                            message: "Please select  level",
                          },
                        ]}
                      >
                        <Select
                          className="input-box-login select-class-level"
                          placeholder="Select  Level"
                          prefix={<MdOutlineCategory />}
                        >
                          <Select.Option value={"Basic"} key={"Basic"}>
                            Basic
                          </Select.Option>
                          <Select.Option value={"Elementary"} key={"Elementary"}>
                            Elementary
                          </Select.Option>
                          <Select.Option value={"Immediate"} key={"Immediate"}>
                            Immediate
                          </Select.Option>
                          <Select.Option value={"Advanced"} key={"Advanced"}>
                            Advanced
                          </Select.Option>
                          <Select.Option
                            value={"Professional"}
                            key={"Professional"}
                          >
                            Professional
                          </Select.Option>
                          <Select.Option value={"Pre school"} key={"Pre school"}>
                            Pre school
                          </Select.Option>
                          <Select.Option value={"K1"} key={"K1"}>
                            K1
                          </Select.Option>
                          <Select.Option value={"K2"} key={"K2"}>
                            K2
                          </Select.Option>
                          <Select.Option value={"P1"} key={"P1"}>
                            P1
                          </Select.Option>
                          <Select.Option value={"P2"} key={"P2"}>
                            P2
                          </Select.Option>
                          <Select.Option value={"P3"} key={"P3"}>
                            P3
                          </Select.Option>
                          <Select.Option value={"P4"} key={"P4"}>
                            P4
                          </Select.Option>
                          <Select.Option value={"P5"} key={"P5"}>
                            P5
                          </Select.Option>
                          <Select.Option value={"P6"} key={"P6"}>
                            P6
                          </Select.Option>
                          <Select.Option value={"Sec1"} key={"Sec1"}>
                            Sec1
                          </Select.Option>
                          <Select.Option value={"Sec2"} key={"Sec2"}>
                            Sec2
                          </Select.Option>
                          <Select.Option value={"Sec3"} key={"Sec3"}>
                            Sec3
                          </Select.Option>
                          <Select.Option value={"Sec4"} key={"Sec4"}>
                            Sec4
                          </Select.Option>
                          <Select.Option value={"Sec5"} key={"Sec5"}>
                            Sec5
                          </Select.Option>
                          <Select.Option value={"JC1"} key={"JC1"}>
                            JC1
                          </Select.Option>
                          <Select.Option value={"JC2"} key={"JC2"}>
                            JC2
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={12} className="custom-padding">
                      <Form.Item
                        className="form-input-class"
                        name="min_student_age"
                        label="Min Student Age"
                        rules={[
                          {
                            required: true,
                            message: "Please enter min age student.",
                          },
                        ]}
                      >
                        <Input
                          className="input-box-login"
                          placeholder="Min Student Age"
                          type="number"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} className="custom-padding">
                      <Form.Item
                        className="form-input-class"
                        name="max_student_age"
                        label="Max Student Age"
                        rules={[
                          {
                            required: true,
                            message: "Please enter max age student.",
                          },
                        ]}
                      >
                        <Input
                          className="input-box-login"
                          placeholder="Max Student Age"
                          type="number"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={24} className="custom-padding">
                      <Form.Item
                        className="form-input-class"
                        name="class_description"
                        label="Description"
                        rules={[
                          {
                            required: true,
                            message: "Please enter description.",
                          },
                        ]}
                      >
                        <TextArea
                          className="leave-comment-text-area"
                          rows={4}
                          placeholder="Type here....."
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    className="mb-0 add-course-form-item"
                    wrapperCol={{
                      offset: 8,
                      span: 24,
                    }}
                  >
                    <Button className="register-button" htmlType="submit" disabled={addClass}>
                      Add Session
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
