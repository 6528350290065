import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Home from "../pages/HomePage/home";
import Login from "../pages/User/userLogin";
import Register from "../pages/Register/register";
import Dashboard from "../pages/User/Dashboard/dashboard";
import MyRewards from "../pages/User/Dashboard/myRewards";
import MyReviews from "../pages/User/Dashboard/myReviews";
import Wishlist from "../pages/User/Dashboard/wishlist";
import MyCoupons from "../pages/User/Dashboard/myCoupons";
import ContactAdmin from "../pages/User/Dashboard/contactAdmin";
import Blogs from "../pages/blogs/index";
import ContactUs from "../pages/contactUs/index";
import VendorDashboard from "../pages/Vendor/dashboard";
import VendorMyProfile from "../pages/Vendor/myProfile";
import AboutUs from "../pages/aboutUs";
import Faq from "../pages/faq";
import CourseListing from "../pages/courseListing";
import CourseDetails from "../pages/courseDetails";
import MyCourses from "../pages/User/Dashboard/myCourses";
import AddNewCourse from "../pages/Vendor/addNewCourse";
import MyStudents from "../pages/Vendor/myStudents";
import MyCourse from "../pages/Vendor/myCourses";
import AllReviews from "../pages/Vendor/allReviews";
import ReferAFriend from "../pages/referAFriend";
import Categories from "../pages/categories";
import UserMyProfile from "../pages/User/Dashboard/myProfile";
import SearchPage from "../pages/searchPage";
import Vendors from "../pages/vendors";
import VendorDetails from "../pages/vendorDetails";
import ForgetPassword from "../pages/forgetPassword/index";
import ResetPassword from "../pages/resetPassword/index";
import PaymentProcess from "../pages/paymentPrcess/index";
import ChangePassword from "../pages/Vendor/changePassword";
import ViewCourseDetail from "../pages/Vendor/viewCourseDetail";
import Bookings from "../pages/Vendor/bookings";
import Classes from "../pages/Vendor/classes";
import AddClasses from "../pages/Vendor/addClasses";
import PrivacyPolicy from "../pages/privacy policy/Index";
import TermCondition from "../pages/term&condition/index";
import WishList from '../pages/wishlist/WishList'
import AddCustom from "../pages/Vendor/AddCustom";
import ThankyouPage from "../pages/thankyouPage/index"
// import Wishlist from "../pages/wishlist/WishList";
import UserOnBoarding from "../pages/User/userOnBoarding/index";
import ContactAdminVendor from "../pages/Vendor/contactAdmin";
import BlogDetails from "../pages/blogs/blogDetailPage"
import Testimonial from "../pages/testimonial/index";
import TermsAndConditions from "../pages/termsandconditions/index";

import CookiesPolicy from '../components/cookies/CookiePolicy'

const AppRoute = () => {
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);

    return (
        <>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/register" element={<Register />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/wishlist" element={<WishList />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/term-condition" element={< TermCondition/>} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/course-listing" element={<CourseListing />} />
                <Route path="/course/:id/:name" element={<CourseDetails />} />
                <Route path="/login" element={<Login />} />
                <Route path="/refer-a-friend" element={<ReferAFriend />} />
                <Route path="/categories" element={<Categories />} />
                <Route path="/search" element={<SearchPage />} />
                <Route path="/vendors" element={<Vendors />} />
                <Route path="/vendor-details" element={<VendorDetails />} />
                <Route path="/forgot-password" element={<ForgetPassword />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
                <Route path="/payment/:user_id/:course_id" element={<PaymentProcess />} />
                <Route path="/thankyou" element={<ThankyouPage />} />
                <Route path="/connect-stripe-account" element={<UserOnBoarding />}/>
                <Route path="/cookie-policy" element={<CookiesPolicy />}/>
                {/* <Route path="/cookie" element = {<Cookie/>}/> */}
               

                <Route path="/blog/:blog_id/:blog_title" element={<BlogDetails /> } />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />
                } />
                <Route path="/blog/:blog_id/:blog_title" element={<BlogDetails />
                } />
                <Route path="/testimonial" element={<Testimonial />
                } />

                {/*=======================User Routes========================== */}
                {isUserLoggedIn && !isVendorLoggedIn ? (
                    <>
                        <Route path="/user/dashboard" element={<Dashboard />} />
                        <Route path="/user/my-rewards" element={<MyRewards />} />
                        <Route path="/user/my-reviews" element={<MyReviews />} />
                        <Route path="/user/wishlist" element={<Wishlist />} />
                        <Route path="/user/my-coupons" element={<MyCoupons />} />
                        <Route path="/user/contact-admin" element={<ContactAdmin />} />
                        <Route path="/user/my-courses" element={<MyCourses />} />
                        <Route path="/user/my-profile" element={<UserMyProfile />} />
                    </>
                ) : isVendorLoggedIn && !isUserLoggedIn ? (
                    <>
                        {/*=======================Vendor Routes========================== */}
                        <Route path="/vendor/dashboard" element={<VendorDashboard />} />
                        <Route path="/vendor/view-course-detail" element={<ViewCourseDetail />} />
                        <Route path="/vendor/my-profile" element={<VendorMyProfile />} />
                        <Route path="/vendor/add-new-course" element={<AddNewCourse />} />
                        <Route path="/vendor/my-students" element={<MyStudents />} />
                        <Route path="/vendor/my-courses" element={<MyCourse />} />
                        <Route path="/vendor/all-reviews" element={<AllReviews />} />
                        <Route path="/vendor/sales-summary" element={<Bookings />} />
                        <Route path="/vendor/change-password" element={<ChangePassword />} />
                        <Route path="/vendor/my-classes" element={<Classes />} />
                        <Route path="/vendor/add-classes" element={<AddClasses />} />
                        <Route path="/vendor/addcustom" element={<AddCustom />} />
                        <Route path="/vendor/contact-admin" element={<ContactAdminVendor />} />

                    </>
                ) : (
                    <Route path="*" element={<Navigate to="/login" replace />} />
                )}
            </Routes>
        </>
    );
}

export default AppRoute;
