import React, { useState, useEffect } from "react";
import { Row, Col, Button, Table, Image, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import Sidebar from "../../components/Sidebar/sidebar";
import { CiFilter } from "react-icons/ci";
import TopNavBar from "../../components/topNavbar";
import { useDispatch } from "react-redux";
import * as getServices from "../../services/getServices";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { UserProfileDefault } from "../../constant/images"
import CopyWriteFooter from "../../components/Footer/copywriteFooter";

export default function MyStudents() {
  const dispatch = useDispatch();
  const vendordata = useSelector((state) => state.user.userData);
  const [currentPage, setCurrentPage] = useState(1);
  const [customers, setCustomers] = useState([]);
  const [courses, setCourses] = useState([]);
  const [vendorCourses, setVendorCourses] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [loader, setLoader] = useState(false)
  const pageSize = 5;

  useEffect(() => {
    setLoader(true);
    dispatch(
      getServices.getCourseByVendorID(
        vendordata?.user?.id
          ? vendordata?.vendor?.id
          : vendordata?.vendor_id
            ? vendordata?.vendor_id
            : vendordata?.Vendor?.id
      )
    )
      .then((response) => {
        setVendorCourses(response?.courses);
        setLoader(false);
        if (response.courses !== undefined) {
          response.courses?.forEach((item) => {
            dispatch(getServices.getUserDataByCourseID(item?.id)).then(
              (res) => {
                if (res?.payments !== undefined) {
                  setPaymentData(...paymentData, res?.payments);
                }
              }
            );
          });
        }
      })
      .catch((error) => {
        console.error({ error: error });
        setLoader(false);
      });
    dispatch(getServices.getAllCourses())
      .then((response) => {
        setCourses(response?.courses);
      })
      .catch((err) => {
        console.log({ err });
      });
  }, []);

  const DateFormatter = (date) => {
    const inputDate = date;

    const dateObj = new Date(inputDate);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = dateObj.getDate();
    const month = monthNames[dateObj.getMonth()];
    const year = dateObj.getFullYear();

    const formatDate = `${day} ${month} ${year}`;
    return formatDate;
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      render: (text, record, index) => (
        <p className="sno-text">
          {String(index + 1 + (currentPage - 1) * pageSize).padStart(2, "0")}
        </p>
      ),
    },
    {
      title: "Client Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <>
          <div className="customer-name-container">
            <div className="customer-image-container">
              <Image
                width={50}
                height={50}
                src={record?.user_profile ? record?.user_profile : UserProfileDefault}
                alt="Not Found"
                className="img-fluid customer-image"
                preview={false}
              />
            </div>
            <div className="customer-name-id">
              <p className="customer-name">{record?.user_name}</p>
              <p className="customer-id">{record?.user_id}</p>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Item",
      dataIndex: "course",
      key: "course",
      width: "35%",
      render: (text, record) => (
        <p className="course-name">{record?.course?.title}</p>
      ),
    },
    {
      title: "Enrolled Date",
      dataIndex: "enrolled_date",
      key: "enrolled_date",
      render: (text, record) => (
        <p className="enrolled-date">{DateFormatter(record?.created_at)}</p>
      ),
    },
  ];

  const exportToExcel = (data, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, `${fileName}.xlsx`);
  };

  const transformData = (data) => {
    return data.map((item, index) => ({
      "S.NO": (index + 1),
      Name: item.user_name,
      Email: item.user_email,
      Course: item.course.title,
      Amount: item.course_fee,
      "Purchase Date": DateFormatter(item.created_at),
    }));
  };

  return (
    <>
      <TopNavBar />
      <Row>
        <Col xl={4} className="d-xl-block d-md-none">
          <Sidebar />
        </Col>
        <Col xl={20} md={24}>
          <div className="mainContent">
            <div className="my-customers-container">
              <div className="download-students-container">
                <Button className="download-students-button" onClick={() => exportToExcel(transformData(paymentData), "studentsData")}>
                  {loader ? <Spin indicator={<LoadingOutlined style={{ color: "#fff" }} spin />} size="small" /> : "Download client in xlsx/csv"}
                </Button>
              </div>
              <Row className="my-customer-head-row">
                <Col className="my-customer-head-col-one" md={19}>
                  <p className="my-students">My Clients</p>
                </Col>
                {/* <Col className="my-customer-head-col-two" md={3}>
              <Button className="filter-button">
                <span className="filter-icon">
                  <CiFilter />
                </span>
                Filter
              </Button>
            </Col> */}
                <Col className="my-customer-head-col-three" md={5}>
                  <p className="total-customer-text">
                    Total Clients
                    <span className="total-customer-number">
                      ({paymentData.length})
                    </span>
                  </p>
                </Col>
              </Row>

              <div className="table-container">
                <Table
                  className="my-client-table"
                  columns={columns}
                  dataSource={paymentData}
                  pagination={{
                    pageSize: pageSize,
                    onChange: (page) => {
                      setCurrentPage(page);
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <CopyWriteFooter />
        </Col>
      </Row>
    </>
  );
}
