import { Card, Row, Col, Image, Collapse, theme } from "antd";
import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/sidebar";
import TopNavBar from "../../components/topNavbar";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as getServices from "../../services/getServices";
import { CaretRightOutlined } from "@ant-design/icons";
const ViewCourseDetail = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  useEffect(() => {
    dispatch(getServices.getCourseByCourseID(location?.state?.data))
      .then((response) => {
        setData(response.course);
      })
      .catch((err) => {
        console.error({ err });
      });
  }, [location?.state?.data]);

  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

  const itemsNest = [
    {
      key: "1",
      label: "This is panel nest panel",
      children: <p>{text}</p>,
    },
  ];

  const getItems = (panelStyle) => [
    {
      key: "1",
      label: "This is panel header 1",
      children: <p>{text}</p>,
      style: panelStyle,
    },
    {
      key: "2",
      label: "This is panel header 2",
      children: <p>{text}</p>,
      style: panelStyle,
    },
    {
      key: "3",
      label: "This is panel header 3",
      children: <p>{text}</p>,
      style: panelStyle,
    },
  ];

  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  return (
    <>
      <TopNavBar />
      <Sidebar />
      <div className="mainContent">
      <div className="course-details-container">
      
      
        <Row className="course-details-row">
          <Col className="course-details-col-one" md={12}>
          <div className="card-box-outer"> 
          <p className="card-title">Item Details</p>
          <Card hoverable>
            
          <div className="course-details-container-inner">       
                <div className="test-container">
                <img
                      style={{marginLeft:'40px'}}
                      alt="example"
                      src={data?.image}
                    />
              <div className="test">
                <p className="course-title-one">
                  <strong>Title:</strong>
                </p>
                <p className="course-title-two">{data?.title}</p>
              </div>
              <div className="test">
                <p className="course-title-one">
                  <strong>Category:</strong>
                </p>
                <p className="course-title-two">Category</p>
              </div>
              <div className="test">
                <p className="course-title-one">
                  <strong>Subcategory:</strong>
                </p>
                <p className="course-title-two">Subcategory</p>
              </div>
              <div className="test">
                <p className="course-title-one">
                  <strong>Status:</strong>
                </p>
                <p className="course-title-two">{data?.status}</p>
              </div>
              <div className="test">
                <p className="course-title-one">
                  <strong>Level:</strong>
                </p>
                <p className="course-title-two">Level</p>
              </div>
              <div className="test">
                <p className="course-title-one">
                  <strong>Class Size:</strong>
                </p>
                <p className="course-title-two"> {data?.class_size}</p>
              </div>
              <div className="test">
                <p className="course-title-one">
                  <strong>Age Limit</strong>
                </p>
                <p className="course-title-two"> {data?.min_age}-{data?.max_age}</p>
              </div>
              <div className="test">
                <p className="course-title-one">
                  <strong>About the Course</strong>
                </p>
                <p className="course-title-two"> {data?.about_course}</p>
              </div>
              <div className="test">
                <p className="course-title-one">
                  <strong>Fee</strong>
                </p>
                <p className="course-title-two"> ${data?.fee}</p>
              </div>
              <div className="test">
                <p className="course-title-one">
                  <strong>Special Instruction</strong>
                </p>
                <p className="course-title-two"> {data?.special_instruction}</p>
              </div>
              </div>
            </div>
          </Card>
          </div>

           
          </Col>
          <Col md={12}>
          <div className="card-box-outer"> 
            <p  className="card-title">Instructor Details</p>
           
              <div>
                <Card
                  hoverable
                  cover={
                    <img
                      style={{ width: "300px", height: "300px" }}
                      alt="example"
                      src={data?.profile_photo}
                    />
                  }
                >
                  <div>
                    <p>
                      <strong>Instructor Name:</strong>
                      {data?.instructor_name}
                    </p>
                    <p>
                      <strong>Work Experience:</strong>
                      {data?.work_experience}
                    </p>
                    <p>
                      <strong>About:</strong>
                      {data?.about}
                    </p>
                  </div>
                </Card>
              </div>
          </div>
          </Col>
          <Col md={24}>
          <div className="card-box-outer"> 
            <p className="card-title">Items FAQs</p>
            <Collapse
              bordered={false}
              defaultActiveKey={["1"]}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              style={{
                background: token.colorBgContainer,
              }}
              items={getItems(panelStyle)}
            />
            </div>
          </Col>
          {/* <Col md={12}>
          <div className="card-box-outer"> 
            <p className="card-title" style={{ textAlign: "center" }}>Course Timing Slots</p>
            </div>
          </Col> */}
         
        </Row>

        {/* <Card 
                    title="Instructor Details" 
                    bordered={true} 
                    className="instructor-detail-card"
                >
                    <div className="grid-container">
                        <p><strong>Instructor Name:</strong>{data?.instructor_name}</p>
                        <p><strong>Work Experience:</strong>{data?.work_experience}</p>
                        <p><strong>Profile Photo:</strong> <img src={`${data?.profile_photo}`} alt="instructor" /></p>
                        <p><strong>About:</strong>{data?.about}</p>
                    </div>
                </Card> */}
      </div>
      </div>
    </>
  );
};

export default ViewCourseDetail;
