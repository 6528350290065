import React, { } from "react";
import TestimonialSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer/footer";
import { IoMdStar } from "react-icons/io";
import {TestimonialBanner,UserProfileDefault} from "../../constant/images"

export default function Testimonial() {

   const settings = {
    dots: true,
    speed: 500,
    arrow: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 1000,

        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
      };
  return (
    <>
    <Header/>
    <div className="testimonial-banner-section" style={{backgroundImage:`url(${TestimonialBanner})`}}>
        <div className="container">
            <div className="testimonial-banner-content text-center">
                <h1>
                Testimonials
                </h1>
                <p>
                See What are Valued Customer Say
                </p>
            </div>
        </div>
    </div>
    <div className="testimonial-slider-section">
            <div className="container">
                <div className="section-head text-center">
                    <h2 className="border-bottom-heading bottom-border-center">What Our Happy Customers Say About Us</h2>
                </div>
                <div className="slider-section">
                <TestimonialSlider {...settings} className="row">
                    <div className="col-xl-4 col-md-4 px-3 testimonial-slide-card">
                        <div className="testimonial-slide">
                            <div className="card-image">
                                <img src={UserProfileDefault}/>
                            </div>
                            <div className="user-name">
                                <h3>
                                Joe Aesthetic
                                </h3>
                                <p className="user-role">
                                UX Designer
                                </p>
                            </div>
                            <div className="review-stars d-flex">
                            <IoMdStar /><IoMdStar /><IoMdStar /><IoMdStar /><IoMdStar />
                            </div>
                            <div className="description">
                            Enrolling in ‘Well Of fun’ was a game-changer for me! I highly recommend their services to anyone seeking top-notch education assistance."
                            </div>

                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 px-3 testimonial-slide-card">
                        <div className="testimonial-slide">
                            <div className="card-image">
                            <img src={UserProfileDefault}/>
                            </div>
                            <div className="user-name">
                                <h3>
                                Joe Aesthetic
                                </h3>
                                <p className="user-role">
                                UX Designer
                                </p>
                            </div>
                            <div className="review-stars d-flex">
                            <IoMdStar /><IoMdStar /><IoMdStar /><IoMdStar /><IoMdStar />
                            </div>
                            <div className="description">
                            Enrolling in ‘Well Of fun’ was a game-changer for me! I highly recommend their services to anyone seeking top-notch education assistance."
                            </div>

                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 px-3 testimonial-slide-card">
                        <div className="testimonial-slide">
                            <div className="card-image">
                            <img src={UserProfileDefault}/>
                            </div>
                            <div className="user-name">
                                <h3>
                                Joe Aesthetic
                                </h3>
                                <p className="user-role">
                                UX Designer
                                </p>
                            </div>
                            <div className="review-stars d-flex">
                            <IoMdStar /><IoMdStar /><IoMdStar /><IoMdStar /><IoMdStar />
                            </div>
                            <div className="description">
                            Enrolling in ‘Well Of fun’ was a game-changer for me! I highly recommend their services to anyone seeking top-notch education assistance."
                            </div>

                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 px-3 testimonial-slide-card">
                        <div className="testimonial-slide">
                            <div className="card-image">
                            <img src={UserProfileDefault}/>
                            </div>
                            <div className="user-name">
                                <h3>
                                Joe Aesthetic
                                </h3>
                                <p className="user-role">
                                UX Designer
                                </p>
                            </div>
                            <div className="review-stars d-flex">
                            <IoMdStar /><IoMdStar /><IoMdStar /><IoMdStar /><IoMdStar />
                            </div>
                            <div className="description">
                            Enrolling in ‘Well Of fun’ was a game-changer for me! I highly recommend their services to anyone seeking top-notch education assistance."
                            </div>

                        </div>
                    </div>

                    
                </TestimonialSlider>
                </div>
                
            </div>
    </div>
    <Footer/>
    </>
  )
}
