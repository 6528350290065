import React, { useState, useEffect } from "react";
import { Row, Col, Image, Card, Button } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";
import * as getServices from "../../services/getServices";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { featureImg2 } from "../../constant/images";
import { DEMO_URL } from "../../Helper";

const RecentCourse = ({ freeCourses }) => {
    const userData = useSelector((state) => state.user.userData);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [categories, setCategories] = useState();
    const [courses, setCourses] = useState();



    useEffect(() => {
        dispatch(getServices?.getCategories())
            .then((response) => {
                setCategories(response?.categories);
            })
            .catch((err) => {
                console.error({ err });
            });

        dispatch(getServices?.getAllCourses())
            .then((response) => {
                setCourses(response?.courses);
            })
            .catch((err) => {
                console.error({ err });
            });
    }, []);

    const Category = (category_id) => {
        const category = categories?.find(
            (category) => category?.id === category_id
        );
        return category?.name;
    };

    const recentCourse = courses
        ?.filter((course) => course.status !== "pending")
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    const freeRecentCourse = courses
        ?.filter((course) => course.status !== "pending" && (course?.is_premium === "free" || course?.trial !== 0))
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    const handlePurchase = (coursedata) => {
        navigate(`/payment/${userData?.user_id}/${coursedata?.id}`, {
            state: { courseData: coursedata },
        });
    };

    const handleCourseData = (values) => {
        navigate(`/course/${values?.id}/${values?.title}`, {
            state: { courseData: values },
        });
    };

    return (
        <div className="recentCourseWrapper">
            {recentCourse !== undefined && recentCourse?.length > 0 ? (
                <>
                    <div className="container">
                        <Row className="headerRow">
                            <Col md={24}>
                                <div className="header">
                                    <h4 className="border-bottom-heading bottom-border-center">
                                        Recently Added
                                    </h4>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mainContent-recent-course">
                            <Col md={12} className="leftSection px-1">


                                <Card>

                                    <div className="imgWrapper">
                                        <h5 className="main-category">{freeCourses ? freeRecentCourse[0]?.parent_category_name : recentCourse[0]?.parent_category_name}</h5>
                                        <Image
                                            src={
                                                freeCourses
                                                    ? (freeRecentCourse && freeRecentCourse[0]?.image)
                                                        ? freeRecentCourse[0]?.image
                                                        : "https://foundr.com/wp-content/uploads/2023/04/How-to-create-an-online-course.jpg.webp"
                                                    : (recentCourse && recentCourse[0]?.image)
                                                        ? recentCourse[0]?.image
                                                        : "https://foundr.com/wp-content/uploads/2023/04/How-to-create-an-online-course.jpg.webp"
                                            }
                                            alt="Not Found"
                                            className="img-fluid"
                                            preview={false}
                                        />
                                        <div className="trail-available-container">
                                            <p className="trial-available-text">Trail Available</p>
                                        </div>
                                        <div className="start-end-date-container">
                                            <p className="start-enddate">12 July 2024 - 15 August 2025</p>
                                        </div>
                                    </div>
                                    <h2></h2>
                                    <div className="infoSection">
                                        <div className="text">
                                            <p>{freeCourses ? freeRecentCourse[0]?.about_course : recentCourse && recentCourse[0]?.about_course}</p>
                                        </div>
                                        <div className="tags">
                                            <span className="one">
                                                {freeCourses ? Category(freeRecentCourse[0]?.category_id) : Category(recentCourse && recentCourse[0]?.category_id) ? Category(recentCourse && recentCourse[0]?.category_id) : "Web Development"}
                                            </span>
                                        </div>
                                        <div className="link">
                                            <Button
                                                className="view-course-recent-course-button"
                                                onClick={(e) =>
                                                    handleCourseData(freeCourses ? freeRecentCourse[0] : recentCourse && recentCourse[0])
                                                }
                                            >
                                                View Details
                                            </Button>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col md={12} className="rightSection px-1">
                                <div className="splitRowWrapper">
                                    {/* Conditionally render top section based on freeCourses */}

                                    {freeCourses ? (
                                        freeRecentCourse && freeRecentCourse[1] && (
                                            <div className="topSection">
                                                <Card>

                                                    <div className="imgWrapper">
                                                        <h5 className="main-category">{freeCourses ? freeRecentCourse[1]?.parent_category_name : recentCourse[1]?.parent_category_name}</h5>
                                                        <Image
                                                            src={
                                                                freeRecentCourse[1]?.image ||
                                                                "https://foundr.com/wp-content/uploads/2023/04/How-to-create-an-online-course.jpg.webp"
                                                            }
                                                            alt="Not Found"
                                                            className="img-fluid"
                                                            preview={false}
                                                        />
                                                        <div className="trail-available-container">
                                                            <p className="trial-available-text">Trial Available</p>
                                                        </div>
                                                    </div>
                                                    <div className="rightCardSection">
                                                        <div className="text">
                                                            <h4>{freeRecentCourse[1]?.title}</h4>
                                                        </div>
                                                        <div className="tags">
                                                            <span className="one">
                                                                {Category(freeRecentCourse[1]?.category_id) || "Web Development"}
                                                            </span>
                                                        </div>
                                                        <div className="link">
                                                            <Button
                                                                className="view-course-recent-course-button"
                                                                onClick={() =>
                                                                    handleCourseData(freeRecentCourse[1])
                                                                }
                                                            >
                                                                View Details
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        )
                                    ) : (
                                        recentCourse && recentCourse[1] && (
                                            <div className="topSection">

                                                <Card>

                                                    <div className="imgWrapper">
                                                        <h5 className="main-category">{freeCourses ? freeRecentCourse[1]?.parent_category_name : recentCourse[1]?.parent_category_name}</h5>
                                                        <Image
                                                            src={
                                                                recentCourse[1]?.image ||
                                                                "https://blog.coursify.me/wp-content/uploads/2018/08/plan-your-online-course.jpg"
                                                            }
                                                            alt="Not Found"
                                                            className="img-fluid"
                                                            preview={false}
                                                        />
                                                        <div className="trail-available-container">
                                                            <p className="trial-available-text">Trial Available</p>
                                                        </div>
                                                    </div>
                                                    <div className="rightCardSection">
                                                        <div className="text">
                                                            <h4>{recentCourse[1]?.title}</h4>
                                                        </div>
                                                        <div className="tags">
                                                            <span className="one">
                                                                {Category(recentCourse[1]?.category_id) || "Web Development"}
                                                            </span>
                                                        </div>
                                                        <div className="link">
                                                            <Button
                                                                className="view-course-recent-course-button"
                                                                onClick={() =>
                                                                    handleCourseData(recentCourse[1])
                                                                }
                                                            >
                                                                View Details
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        )
                                    )}
                                    {freeCourses ? (
                                        freeRecentCourse && freeRecentCourse[2] && (
                                            <div className="BottomSection">

                                                <Card>

                                                    <div className="imgWrapper">
                                                        <h5 className="main-category">{freeCourses ? freeRecentCourse[2]?.parent_category_name : recentCourse[2]?.parent_category_name}</h5>
                                                        <Image
                                                            src={
                                                                freeRecentCourse[2]?.image ||
                                                                `https://chennai.vit.ac.in/wp-content/uploads/2021/06/Cyber-Security-Certification-Course-Computer-Science-Certification-Courses.jpg`
                                                            }
                                                            alt="Not Found"
                                                            className="img-fluid"
                                                            preview={false}
                                                        />
                                                    </div>
                                                    <div className="rightCardSection">
                                                        <div className="text">
                                                            <h4>{freeRecentCourse[2]?.title}</h4>
                                                        </div>
                                                        <div className="tags">
                                                            <span className="one">
                                                                {Category(freeRecentCourse[2]?.category_id) || "Web Development"}
                                                            </span>
                                                        </div>
                                                        <div className="link">
                                                            <Button
                                                                className="view-course-recent-course-button"
                                                                onClick={() =>
                                                                    handleCourseData(freeRecentCourse[2])
                                                                }
                                                            >
                                                                View Details
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        )
                                    ) : (
                                        recentCourse && recentCourse[2] && (
                                            <div className="BottomSection">

                                                <Card>

                                                    <div className="imgWrapper">
                                                        <h5 className="main-category mb-0">{freeCourses ? freeRecentCourse[2]?.parent_category_name : recentCourse[2]?.parent_category_name}</h5>
                                                        <Image
                                                            src={
                                                                recentCourse[2]?.image ||
                                                                `https://chennai.vit.ac.in/wp-content/uploads/2021/06/Cyber-Security-Certification-Course-Computer-Science-Certification-Courses.jpg`
                                                            }
                                                            alt="Not Found"
                                                            className="img-fluid"
                                                            preview={false}
                                                        />
                                                    </div>
                                                    <div className="rightCardSection">
                                                        <div className="text">
                                                            <h4>{recentCourse[2]?.title}</h4>
                                                        </div>
                                                        <div className="tags">
                                                            <span className="one">
                                                                {Category(recentCourse[2]?.category_id) || "Web Development"}
                                                            </span>
                                                        </div>
                                                        <div className="link">
                                                            <Button
                                                                className="view-course-recent-course-button"
                                                                onClick={() =>
                                                                    handleCourseData(recentCourse[2])
                                                                }
                                                            >
                                                                View Details
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        )
                                    )}
                                </div>
                            </Col>

                        </Row>
                    </div>
                </>
            ) : (
                ""
            )}
        </div>
    );
};

export default RecentCourse;
