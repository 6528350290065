import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer/footer";
import {
  Row,
  Col,
  Input,
  Card,
  Pagination,
  Collapse,
  Image,
  Checkbox,
  Button,
  Drawer
} from "antd";
import {
  HouseHackerLogo,
  MartinsAcademyLogo,
  MatrixLogo,
  OakLegueLogo,
  FunAcademyLogo,
  arrowUpRight,
  
} from "../../constant/images";
import { MdFileCopy } from "react-icons/md";
import { LuFilter } from "react-icons/lu";

export default function Vendors() {
  const [open, setOpen] = useState(false);
  const { Panel } = Collapse;
  const [current, setCurrent] = useState(1);
  const { Search } = Input;
  const pageSize = 6;
  
  const showDrawer = () => {
    setOpen(true);
};

const onClose = () => {
    setOpen(false);
};
  const onChange = (page) => {
    setCurrent(page);
  };

  const vendorsList = [
    {
      fields: ["Art & Craft", "Web Development"],
      vendorName: "Fun Academy",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      image: FunAcademyLogo,
      courseList: "20",
    },
    {
      fields: ["App Development"],
      vendorName: "Oak League",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      image: OakLegueLogo,
      courseList: "30",
    },
    {
      fields: ["App Development", "UI/UX Design"],
      vendorName: "Matrix",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      image: MatrixLogo,
      courseList: "10",
    },
    {
      fields: ["Digital Marketing"],
      vendorName: "Martins Academy",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      image: MartinsAcademyLogo,
      courseList: "50",
    },
    {
      fields: ["App Development", "UI/UX Design"],
      vendorName: "Matrix",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      image: OakLegueLogo,
      courseList: "05",
    },
    {
      fields: ["Hacking", "Cyber Security"],
      vendorName: "House Hacker",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      image: HouseHackerLogo,
      courseList: "30",
    },
    {
        fields: ["Art & Craft", "Web Development"],
        vendorName: "Fun Academy",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        image: FunAcademyLogo,
        courseList: "20",
      },
      {
        fields: ["App Development"],
        vendorName: "Oak League",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        image: OakLegueLogo,
        courseList: "30",
      },
      {
        fields: ["App Development", "UI/UX Design"],
        vendorName: "Matrix",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        image: MatrixLogo,
        courseList: "10",
      },
      {
        fields: ["Digital Marketing"],
        vendorName: "Martins Academy",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        image: MartinsAcademyLogo,
        courseList: "50",
      },
      {
        fields: ["App Development", "UI/UX Design"],
        vendorName: "Matrix",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        image: OakLegueLogo,
        courseList: "05",
      },
      {
        fields: ["Hacking", "Cyber Security"],
        vendorName: "House Hacker",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        image: HouseHackerLogo,
        courseList: "30",
      },
      {
        fields: ["App Development"],
        vendorName: "Oak League",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        image: OakLegueLogo,
        courseList: "30",
      }
  ];

  const items = [
    {
      key: "1",
      label: "Rating",
      children: (
        <>
          <div className="category-container-checkbox">
            <Checkbox className="checkbox-rate-three" onChange={onChange}>
              <span className="category-name">Web Development</span>
            </Checkbox>
            <Checkbox className="checkbox-rate-five" onChange={onChange}>
              <span className="category-name">App Development</span>
            </Checkbox>
            <Checkbox className="checkbox-rate-four" onChange={onChange}>
              <span className="category-name">UI/UX Designing</span>
            </Checkbox>
            <Checkbox className="checkbox-rate-three" onChange={onChange}>
              <span className="category-name">Art & Craft</span>
            </Checkbox>
            <Checkbox className="checkbox-rate-five" onChange={onChange}>
              <span className="category-name">Digital Marketing</span>
            </Checkbox>
            <Checkbox className="checkbox-rate-four" onChange={onChange}>
              <span className="category-name">UI/UX Designing</span>
            </Checkbox>
            <Checkbox className="checkbox-rate-three" onChange={onChange}>
              <span className="category-name">Fitness & Sports</span>
            </Checkbox>
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: "Sort",
      
      children: (
        <>
          <div className="sort-by-container-checkbox">
            <Checkbox className="checkbox-rate-three" onChange={onChange}>
              <span className="category-name">Web Development</span>
            </Checkbox>
            <Checkbox className="checkbox-rate-five" onChange={onChange}>
              <span className="category-name">App Development</span>
            </Checkbox>
          </div>
        </>
      ),
    },
  ];
  const startIndex = (current - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const displayedCourses = vendorsList.slice(startIndex, endIndex);

  return (
    <>
      <Header />
      <div className="vendor-list-container">
        <div className="venbdor-list-banner-container">
          <p className="list-vendor-text">List of Vendors</p>
          <p className="browse-text">Browse our vendor</p>
        </div>

        <div className="vendor-list-filter-container container">
          <Row className="vendor-list-filter-row">
            <Col className="vendor-list-filter-col-one" xl={18}>
            <div className="text-right d-flex justify-content-end">
            <Button className="d-xl-none filters-button w-60 mb-1" type="button" onClick={showDrawer}>
                            <LuFilter />Filters
                            </Button>
            </div>
              <div className="vendor-list-head-container">
                <p className="all-vendor-heading border-bottom-heading">All Vendors</p>
                
                           
                            <Drawer title="Filters" onClose={onClose} open={open}>
                                <Row className="vendor-list-container filter-container-Drawer">
                                <Col className="vendor-list-filter-col-two col-xl-none col-md-block" md={24}>
                                    <div className="vendor-filter-container">
                                      <div className="all-filter-applied">
                                        <p className="all-filters">All Filters</p>
                                        <p className="applied-filters">Applied(0)</p>
                                      </div>
                                      <Collapse defaultActiveKey={["1"]} className="custom-collapse">
                                        {items.map((item) => (
                                          <Panel
                                            header={
                                              <div className="panel-header">
                                                <span className="panel-label">{item.label}</span>
                                              </div>
                                            }
                                            key={item.key}
                                          >
                                            <p className="panel-description">{item.children}</p>
                                          </Panel>
                                        ))}
                                      </Collapse>
                                    </div>
                                  </Col>
                                </Row>
                            </Drawer>
                <Search className="search-bar" placeholder="Search" />
                
              </div>
              <div className="vendor-list-continer">
                <Row className="vendor-card-row">
                  {displayedCourses?.map((vendor, index) => (
                    <Col
                      className={
                        (index + 1) % 2 === 0
                          ? "vendor-card-col ps-3"
                          : "vendor-card-col pe-3"
                      }
                      md={12}
                    >
                      <Card
                        className="vendor-list-card"
                        cover={
                          <Image
                            className="vendor-card-image"
                            preview={false}
                            src={vendor?.image}
                          />
                        }
                      >
                        <div className="card-body-container">
                          <p className="vendor-fields-text">
                            {vendor?.fields?.map((field, index) => (
                              <>
                                {index >= vendor?.fields?.length - 1 ? (
                                  <>
                                    <span>{field}</span>
                                  </>
                                ) : (
                                  <>
                                    <span>{field},&nbsp;</span>
                                  </>
                                )}
                              </>
                            ))}
                          </p>
                          <div className="vendor-name-arrow">
                            <p className="vendor-name">{vendor?.vendorName}</p>
                            <Image
                              className="vendor-arrow-img"
                              src={arrowUpRight}
                            />
                          </div>
                          <p className="vendor-description">
                            {vendor?.description}
                          </p>
                          <p className="vendor-course-list-icon">
                            <span className="copy-couse-list">
                              <MdFileCopy
                                className="copy-icon"
                                color="#106AB3"
                              />
                            </span>
                            {vendor?.courseList} Items
                          </p>
                        </div>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
              <Pagination
                className="pagination"
                current={current}
                onChange={onChange}
                total={vendorsList.length}
                pageSize={pageSize}
              />
            </Col>
            <Col className="vendor-list-filter-col-two col-md-none" xl={6}>
              <div className="vendor-filter-container">
                <div className="all-filter-applied">
                  <p className="all-filters">All Filters</p>
                  <p className="applied-filters">Applied(0)</p>
                </div>
                <Collapse defaultActiveKey={["1"]} className="custom-collapse">
                  {items.map((item) => (
                    <Panel
                      header={
                        <div className="panel-header">
                          <span className="panel-label">{item.label}</span>
                        </div>
                      }
                      key={item.key}
                    >
                      <p className="panel-description">{item.children}</p>
                    </Panel>
                  ))}
                </Collapse>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
}
