import React, { useState } from "react";
import { Input, Form, Checkbox, Row, Col, Button, Select } from "antd";
import { Country, State, City } from "country-state-city";

export default function AddressForm({ addressFormData, onData, payLoader }) {
    const allCountries = Country.getAllCountries();
    const [selectedCountry, setSelectedCountry] = useState({
        key: {
            isCode: "SG",
            name: "Singapore",
        },
    });
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);

    const state = State?.getStatesOfCountry(selectedCountry?.key?.isCode);
    const Cities = City.getCitiesOfState(
        selectedCountry?.key?.isCode,
        selectedState?.key?.isCode
    );

    const handleCountryChange = (country, key) => {
        setSelectedCountry(key);
    };

    const handleStateChange = (state, key) => {
        setSelectedState(key);
    };

    const handleCityChange = (city) => {
        setSelectedCity(city);
    };

    const onFinish = (values) => {
    
        values.country = selectedCountry?.key?.name;
        values.city = selectedCity;
        onData(values);
    };

    const onFinishFailed = () => { };
    return (
        <Form
            className="addressForm"
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            initialValues={{
                flat_no: addressFormData?.flat_no,
                address: addressFormData?.address,
                country: selectedCountry?.key?.name,
                state: selectedState?.key.name,
                city: selectedCity,
                postal_code: addressFormData?.postal_code,
                famous_landmark: addressFormData?.famous_landmark


            }}
        >
            <h4 className="formTitle">Enter Your Address Details</h4>
            <Form.Item
                name="flat_no"
                label="Flat/House no"
                rules={[{ required: true, message: "Please enter flat/house no." }]}
            >
                <Input className="form-control" placeholder="Flat/House no." />
            </Form.Item>
            <Form.Item
                name="address"
                label="Address"
                rules={[{ required: true, message: "Please enter address." }]}
            >
                <Input className="form-control" placeholder="Address" />
            </Form.Item>
            <div className="row">
                <div className="col-xl-6 col-md-12">
                    <Form.Item name="country" label="Country" rules={[{ required: true, message: "Please select county" }]}>
                        <Select
                            className="formSelect"
                            defaultValue={
                                selectedCountry ? selectedCountry?.key?.name : allCountries.find((country) => country.name === "Singapore")?.name
                            }
                            onChange={(value, key) => handleCountryChange(value, key)}
                            placeholder="Select Country"
                            options={allCountries.map((item) => ({
                                label: (
                                    <>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                gap: "0.5rem",
                                            }}
                                        >
                                            <img
                                                src={`https://flagcdn.com/16x12/${item.isoCode.toLocaleLowerCase()}.png`}
                                                alt=""
                                                className="custom-option-flag"
                                            />
                                            {item.name}
                                        </div>
                                    </>
                                ),
                                value: item.name,
                                key: { name: item.name, isCode: item?.isoCode },
                            }))}
                        />
                    </Form.Item>
                </div>
                <div className="col-xl-6 col-md-12">
                    <Form.Item name="state" label="State">
                        <Select
                            defaultValue={
                                selectedState ? selectedState?.key?.name : ""
                            }
                            className="formSelect"
                            onChange={(value, key) => handleStateChange(value, key)}
                            // isDisabled={selectedCountry === null ? true : false}
                            placeholder="Select State"
                            options={state.map((item) => ({
                                label: (
                                    <>
                                        {item?.name}
                                    </>
                                ),
                                value: item?.name,
                                key: { name: item.name, isCode: item?.isoCode },
                            }))}
                        />
                    </Form.Item>
                </div>
                <div className="col-xl-6 col-md-12">
                    <Form.Item name="city" label="City">
                        <Select
                            defaultValue={
                                selectedCity ? selectedCity : ""
                            }
                            className="formSelect"
                            placeholder="Select City"
                            onChange={handleCityChange}
                            options={Cities.map((item) => ({
                                label: (
                                    <>
                                        {item?.name}
                                    </>
                                ),
                                value: item.name,
                                key: { name: item.name, isCode: item?.isoCode },
                            }))}
                        />
                    </Form.Item>
                </div>
                <div className="col-xl-6 col-md-12">
                    <Form.Item
                        name="postal_code"
                        label="Postal Code"
                        rules={[
                            {
                                required: true,
                                message: "Please enter postal code.",
                            },
                            {
                                validator: (_, value) =>
                                    value && value.length === 6
                                        ? Promise.resolve()
                                        : Promise.reject(
                                            new Error("Postal code must be exactly 6 digits.")
                                        ),
                            },
                        ]}
                    >
                        <Input
                            placeholder="Postal Code"
                            className="form-control"
                            maxLength={6}
                            onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                }
                            }}
                            onPaste={(e) => {
                                const paste = (e.clipboardData || window.clipboardData).getData(
                                    "text"
                                );
                                if (!/^[0-9]+$/.test(paste)) {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </Form.Item>
                </div>
            </div>
            <Form.Item name="famous_landmark" label="Landmark (Optional)">
                <Input
                    style={{ padding: "10px" }}
                    className="form-control"
                    placeholder="Landmark"
                />
            </Form.Item>
            <Form.Item className="mb-0" wrapperCol={{ offset: 8, span: 24 }}>
                <Button className="register-button button-register" htmlType="submit">
                    {payLoader ? "Processing..." : "Pay Now"}
                </Button>
            </Form.Item>
        </Form>
    );
}
