import React, { useState, useEffect } from "react";
import { Country, State, City } from "country-state-city";
import { Col, Row, Form, Input, Button, Spin, Space, Checkbox, Select } from "antd";
import { CiUser } from "react-icons/ci";
import { CiMail } from "react-icons/ci";
import { BsTelephone } from "react-icons/bs";
import { CiCalendar } from "react-icons/ci";
import { CiLock } from "react-icons/ci";
import { IoEyeOutline } from "react-icons/io5";
import * as UserServices from "../../services/userServices";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import * as getServices from '../../services/getServices'
// import Select from "react-select";
import { Link } from 'react-router-dom'
import { LuAsterisk } from "react-icons/lu";

export default function UserRegister() {
  const allCountries = Country.getAllCountries();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [loader, setLoader] = useState(false);

  const state = State?.getStatesOfCountry(selectedCountry?.key?.isCode);

  const Cities = City.getCitiesOfState(
    selectedCountry?.key?.isCode,
    selectedState?.key?.isCode
  );

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
  };

  const handleStateChange = (state) => {
    setSelectedState(state);
  };
  const handleCityChange = (city) => {
    setSelectedCity(city);
  };
  const today = new Date().toISOString().split('T')[0];
  const onFinishFailed = () => { };

  const RegisterUser = (values) => {

    setLoader(true);
    let formData;
    formData = {
      email: values.email,
      name: values.fullName,
      password: values.password,
      confirmPassowrd: values.confirmPassword,
      dob: values.dob,
      phone: values.phone,
      country: selectedCountry?.value,
      state: selectedState?.value,
      user_type: "1",
      interestArea: values.value,
      term_condition: values.term_condition,
      postal_code: values?.postal_code
    };
    if (selectedCity) {
      formData.town = selectedCity.value;
    }
    let password = values.password;
    let confirmPassowrd = values.confirmPassword;
    if (password === confirmPassowrd) {
      dispatch(UserServices.Register(formData))
        .then((response) => {
          setLoader(false);
          if (response.message === "Email already found.") {
            Swal.fire(
              "Email Already Registered",
              "Please try with a different email address!",
              "error"
            );
          } else {
            Swal.fire(
              "Registration Successful!",
              "You have successfully registered with Well Of Fun!<br>Link has been sent to your mail.",
              "success"
            ).then((result) => {
              // Reload the Page
              navigate("/login");
            });
          }
        })
        .catch((err) => {
          setLoader(false);
          Swal.fire(
            "Error",
            `${err?.response?.data?.message}`,
            "error"
          );
          console.error({ err });
        });
    } else {
      setLoader(false);
      Swal.fire(
        "Passwords do not match!",
        "Please check and try again!",
        "error"
      );
    }
  };
  useEffect(() => {
    dispatch(getServices?.getCategories()).then((res) => {
      const transformedOptions = res?.categories?.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setCategories(transformedOptions)
    })
  }, [])
  return (
    <div>
      <div className="user-register-container">
        <div className="user-register-div">
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={RegisterUser}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row>
              <Col md={12} className="custom-padding">
                <p
                  style={{
                    textAlign: "start",
                    fontFamily: "POPPINS",
                    display: "inline-flex"
                  }}
                >
                  Full Name
                  <span><LuAsterisk style={{ color: 'red', }} /></span>
                </p>

                <Form.Item
                  className="form-input-class"
                  name="fullName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter full name.",
                    },
                  ]}
                >
                  <Input
                    prefix={<CiUser />}
                    placeholder="Enter your name here"
                    className="input-box-login"
                  />
                </Form.Item>
              </Col>
              <Col md={12} className="custom-padding">
                <p
                  style={{
                    textAlign: "start",
                    fontFamily: "POPPINS",
                    display: "inline-flex"
                  }}
                >
                  Email
                  <span><LuAsterisk style={{ color: 'red', }} /></span>
                </p>

                <Form.Item
                  className="form-input-class"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Email is not valid",
                      type: "email",
                    },
                  ]}
                >
                  <Input
                    prefix={<CiMail />}
                    placeholder="Enter your email"
                    className="input-box-login"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="custom-padding">
                <p
                  style={{
                    textAlign: "start",
                    fontFamily: "POPPINS",
                    display: "inline-flex"
                  }}
                >
                  Phone Number
                  <span><LuAsterisk style={{ color: 'red', }} /></span>
                </p>

                <Form.Item
                  className="form-input-class"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please enter phone number.",
                    },
                    {
                      validator: (_, value) =>
                        value && value.length === 10
                          ? Promise.resolve()
                          : Promise.reject(
                            new Error(
                              "Phone number must be exactly 10 digits."
                            )
                          ),
                    },
                  ]}
                >
                  <Input
                    prefix={<BsTelephone />}
                    placeholder="Please enter phone number"
                    className="input-box-login"
                    maxLength={10}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onPaste={(e) => {
                      const paste = (
                        e.clipboardData || window.clipboardData
                      ).getData("text");
                      if (!/^[0-9]+$/.test(paste)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col md={12} className="custom-padding">
                <p
                  style={{
                    textAlign: "start",
                    fontFamily: "POPPINS",
                    display: "inline-flex"
                  }}
                >
                  DOB (Date Of Birth)
                  <span><LuAsterisk style={{ color: 'red', }} /></span>
                </p>

                <Form.Item
                  className="form-input-class"
                  name="dob"
                  rules={[
                    {
                      required: true,
                      message: "Please select Date of Birth.",
                    },
                  ]}
                >
                  <Input
                    prefix={<CiCalendar />}
                    placeholder="DD.YYYY"
                    type="date"
                    className="input-box-login"
                    max={today}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="custom-padding">
                <p
                  style={{
                    textAlign: "start",
                    fontFamily: "POPPINS",
                    display: "inline-flex"
                  }}
                >
                  Password
                  <span><LuAsterisk style={{ color: 'red', }} /></span>
                </p>

                <Form.Item
                  className="form-input-class"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    {
                      min: 6,
                      message: "Password must be at least 6 characters long!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || /[!@#$%^&*(),.?":{}|<>]/.test(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "Password must contain at least one special character!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    prefix={<CiLock />}
                    placeholder="Please enter your password"
                    suffix={<IoEyeOutline />}
                    type="password"
                    className="input-box-login"
                    id="user_password"
                  />
                </Form.Item>
              </Col>
              <Col md={12} className="custom-padding">
                <p
                  style={{
                    textAlign: "start",
                    fontFamily: "POPPINS",
                    display: "inline-flex"
                  }}
                >
                  Confirm Password
                  <span><LuAsterisk style={{ color: 'red', }} /></span>
                </p>

                <Form.Item
                  className="form-input-class"
                  name="confirmPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },

                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The new password that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    prefix={<CiLock />}
                    placeholder="Please enter your confirm password"
                    suffix={<IoEyeOutline />}
                    className="input-box-login"
                    type="password"
                    id="user_confirm_password"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="custom-padding">
                <p
                  style={{
                    textAlign: "start",
                    fontFamily: "POPPINS",
                    display: "inline-flex"
                  }}
                >
                  Select Country
                  <span><LuAsterisk style={{ color: 'red', }} /></span>
                </p>

                <Form.Item
                  className="form-input-class"
                  rules={[
                    {
                      required: true,
                      message: "Please select a country",
                    },
                  ]}
                  name="country"
                >
                  <Select
                    defaultValue={allCountries[0].name}
                    onChange={handleCountryChange}
                    placeholder="Select Country"
                    options={allCountries.map((item) => ({
                      label: (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: "0.5rem",
                            }}
                          >
                            <img
                              src={`https://flagcdn.com/16x12/${item.isoCode.toLocaleLowerCase()}.png`}
                              alt=""
                              className="custom-option-flag"
                            />
                            <p style={{ margin: "0" }}>{item.name}</p>
                          </div>
                        </>
                      ),
                      value: item.name,
                      key: { name: item.name, isCode: item?.isoCode },
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col md={12} className="custom-padding">
                <p
                  style={{
                    textAlign: "start",
                    fontFamily: "POPPINS",
                  }}
                >
                  Select State
                </p>

                <Form.Item
                  className="form-input-class"
                  name="state"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select a state",
                //   },
                // ]}
                >
                  <Select
                    onChange={handleStateChange}
                    // isDisabled={selectedCountry === null ? true : false}
                    placeholder="Select State"
                    options={state.map((item) => ({
                      label: (
                        <>
                          <p>{item?.name}</p>
                        </>
                      ),
                      value: item?.name,
                      key: { name: item.name, isCode: item?.isoCode },
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="custom-padding">
                <p
                  style={{
                    textAlign: "start",
                    fontFamily: "POPPINS",
                  }}
                >
                  Select City
                </p>

                <Form.Item className="form-input-class" name="city"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select a city",
                //   },
                // ]}
                >
                  <Select

                    placeholder="Select City"
                    onChange={handleCityChange}
                    options={Cities.map((item) => ({
                      label: (
                        <>
                          <p>{item?.name}</p>
                        </>
                      ),
                      value: item.name,
                      key: { name: item.name, isCode: item?.isoCode },
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={12} className="custom-padding">
                <p
                  style={{
                    textAlign: "start",
                    fontFamily: "POPPINS",
                    display: "inline-flex"
                  }}
                >
                  Select your interest area
                  <span><LuAsterisk style={{ color: 'red', }} /></span>
                </p>
                <Form.Item
                  name="interestArea"

                  rules={[
                    {
                      required: true,
                      message: "Please select your interest!",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    onChange={(value) => console.log(value, 'value')}
                    placeholder="Please select"
                    optionLabelProp="label"
                    options={categories}
                  />
                  {/* optionrender={(option) => (
                      <Space>
                        <span role="img" aria-label={option.name} value={option.name} >
                          {option.name}
                        </span>
                      </Space>
                    )}
                  /> */}
                </Form.Item></Col>
            </Row>
            <Row>
              <Col span={12}>
                <p
                  style={{
                    textAlign: "start",
                    fontFamily: "POPPINS",
                  }}
                >
                  Postal Code
                </p>
                <Form.Item
                  className="form-input-class"
                  name="postal_code"
                  rules={[

                    {
                      pattern: /^[0-9]{6}$/,
                      message: "Postal code cannot more than six.",
                    },
                  ]}
                >
                  <Input

                    placeholder="Enter postal code "
                    maxLength={6}
                    inputMode="numeric"
                    className="input-box-login"
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>

              </Col>
            </Row>
            <Form.Item
              name="term_condition"
              valuePropName="checked"
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
              rules={[
                {
                  required: true,
                  message: "Please check term and condition for sign in!",
                },
              ]}
            >
              <Checkbox> By signing up  I agree all <Link to='/privacy-policy'>Term & Condition</Link></Checkbox>
            </Form.Item>
            <Form.Item
              className="mb-0"
              wrapperCol={{
                offset: 8,
                span: 24,
              }}
            >
              <Button className="register-button" htmlType="submit">
                {loader ? (
                  <Spin indicator={<LoadingOutlined spin />} />
                ) : (
                  "Sign Up"
                )}
              </Button>
            </Form.Item>
            <p className="text-center account-text">
              Have an account? <a className="login-text" href="/login">Login</a>
            </p>
          </Form>
        </div>
      </div>
    </div>
  );
}
