import React, { useState } from "react";
import { Row, Col, Input, Button, Form, Select } from "antd";
import Sidebar from "../../components/Sidebar/sidebar";
import { CiUser } from "react-icons/ci";
import { CiMail } from "react-icons/ci";
import { BsTelephone } from "react-icons/bs";
import TopNavBar from "../../components/topNavbar/index";
import SunEditor from "suneditor-react";
import * as uploadServices from "../../services/uploadServices";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import "suneditor/dist/css/suneditor.min.css";
import CopyWriteFooter from "../../components/Footer/copywriteFooter";
import {
    align,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    lineHeight,
    list,
    paragraphStyle,
    table,
    template,
    textStyle,
    image,
    link,
} from "suneditor/src/plugins";

export default function ContactAdmin() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const userData = useSelector((state) => state.user.userData);

    const [editorContent, setEditorContent] = useState("");
    const onFinish = (values) => {
        const formData = new FormData();
        formData.append(
            "user_id",
            userData?.user_id
                ? userData?.user_id
                : userData?.id
                    ? userData?.id
                    : userData?.User?.id
        );
        formData.append("name", values.fullName);
        formData.append("email", values.email);
        formData.append("phone", values.phone);
        formData.append("typeOfEnquiry", values.typeOfEnquiry);
        formData.append("message", editorContent);

        dispatch(uploadServices.contactAdmin(formData))
            .then((res) => {
                if (res.message) {
                    Swal.fire({
                        title: res.message,
                        icon: "success",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    });
                }
            })
            .catch(() => {
                Swal.fire({
                    title: "please fill  all the fields",
                    icon: "error",
                }).then((result) => {
                });
            });
    };
    const onFinishFailed = () => { };

    const SunEditorOpts = {
        showPathLabel: false,
        imageMultipleFile: true,
        imageWidth: "150px",
        imageHeight: "150px",
        height: "auto",
        minHeight: "200px",
        requestHeaders: {
            "X-Sample": "sample",
        },
        plugins: [
            align,
            font,
            fontColor,
            fontSize,
            formatBlock,
            hiliteColor,
            horizontalRule,
            lineHeight,
            list,
            paragraphStyle,
            table,
            template,
            textStyle,
            image,
            link,
        ],
        buttonList: [
            ["undo", "redo"],
            ["font", "fontSize", "formatBlock"],
            ["paragraphStyle"],
            ["bold", "underline", "italic", "strike", "subscript", "superscript"],
            ["fontColor", "hiliteColor"],
            ["removeFormat"],
            ["align", "horizontalRule", "lineHeight"],
            ["outdent", "indent"],
            ["list"],
            ["table", "link", "image"],
            ["fullScreen", "showBlocks", "preview"],
        ],
        formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
        font: [
            "Arial",
            "Calibri",
            "Comic Sans",
            "Courier",
            "Garamond",
            "Georgia",
            "Impact",
            "Lucida Console",
            "Palatino Linotype",
            "Segoe UI",
            "Tahoma",
            "Times New Roman",
            "Trebuchet MS",
            "Roboto",
        ],
    };

    const handleEditorChange = (content) => {
        setEditorContent(content);
    };

    const validateEditorContent = (_, value) => {
        if (editorContent.trim() === "") {
            return Promise.reject(new Error("Please write a message."));
        }
        return Promise.resolve();
    };
    return (
        <>
            <TopNavBar />
            <Row>
                <Col xl={4} className="d-xl-block d-md-none">
                    <Sidebar />
                </Col>
                <Col xl={20} md={24}>
                    <div className="mainContent">
                        <div className="contact-admin-container mb-4">
                            <div className="contact-admin-first-child">
                                <h2 className="contact-admin-first-head">Contact Admin</h2>

                                <Form
                                    name="basic"
                                    layout="vertical"
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    form={form}
                                >
                                    <Row>
                                        <Col md={12} className="custom-padding">
                                            <Form.Item
                                                className="form-input-class"
                                                name="fullName"
                                                label="Full Name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter full name.",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className="input-box-login"
                                                    placeholder="Enter your name here"
                                                    prefix={<CiUser />}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} className="custom-padding">
                                            <Form.Item
                                                className="form-input-class"
                                                name="email"
                                                label="Email"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Email is not valid",
                                                        type: "email",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className="input-box-login"
                                                    placeholder="Johndoe@gmail.com"
                                                    prefix={<CiMail />}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12} className="custom-padding">
                                            <Form.Item
                                                className="form-input-class"
                                                name="phone"
                                                label="Phone Number"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter phone number.",
                                                    },
                                                    {
                                                        validator: (_, value) =>
                                                            value && value.length === 10
                                                                ? Promise.resolve()
                                                                : Promise.reject(
                                                                    new Error(
                                                                        "Phone number must be exactly 10 digits."
                                                                    )
                                                                ),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className="input-box-login"
                                                    placeholder="Enter phone number"
                                                    prefix={<BsTelephone />}
                                                    maxLength={10}
                                                    onKeyPress={(e) => {
                                                        if (!/[0-9]/.test(e.key)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onPaste={(e) => {
                                                        const paste = (
                                                            e.clipboardData || window.clipboardData
                                                        ).getData("text");
                                                        if (!/^[0-9]+$/.test(paste)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} className="custom-padding">
                                            <Form.Item
                                                className="form-input-class type-of-enquiry"
                                                name="typeOfEnquiry"
                                                label="Type Of Enquiry"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select Enquiry.",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    className="input-box-login type-of-enquiry"
                                                    placeholder="Select type of enquiry"
                                                >
                                                    <Select.Option value="sample">Sample</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={24}>
                                            <Form.Item
                                                className="mb-0"
                                                label="Message"
                                                rules={[
                                                    {
                                                        validator: validateEditorContent,
                                                    },
                                                ]}
                                            >
                                                <div className="suneditor-outer">
                                                    <SunEditor
                                                        onChange={handleEditorChange}
                                                        setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                                        setOptions={SunEditorOpts}
                                                    />
                                                </div>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Form.Item
                                        className="mb-0"
                                        wrapperCol={{
                                            offset: 8,
                                            span: 24,
                                        }}
                                    >
                                        <Button
                                            className="register-button button-register"
                                            htmlType="submit"
                                        >
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <CopyWriteFooter />
                </Col>
            </Row>
        </>
    );
}
