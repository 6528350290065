const Logo = require('../assets/images/logo.png')
const BackgroundImage = require('../assets/images/background_image.png')
const mobile = require("../assets/images/mobile.png")
const monitor = require("../assets/images/monitor.png")
const d3des = require("../assets/images/lets-icons_3d-box-light.png")
const cupcake = require("../assets/images/mynaui_cupcake.png")
const song = require("../assets/images/material-symbols-light_artist-outline.png")
const featureImg1 = require("../assets/images/featureimg1.png")
const featureImg2 = require("../assets/images/featureimg2.png")
const featureImg3 = require("../assets/images/featureimg3.png")
const arrowUpRight = require("../assets/images/arrow-up-right.png")
const MyCOurseImg = require("../assets/images/mycourse-img.png")
const RewardTrophy = require("../assets/images/reward-trophy.png")
const PieChart = require("../assets/images/pieChart.png")
const Coins = require("../assets/images/coins.png")
const StarImage = require("../assets/images/star-img.png")
const BlogBanner = require("../assets/images/blog-banner-image.png")
const BlogNewsLetterCover = require("../assets/images/blog-news-letter-cover.png")
const ContactUsBannner = require("../assets/images/contact-us-cover.png")
const ConatctUsFormImage  = require("../assets/images/contact-form-image.png")
const EnrolledStudentsImage = require("../assets/images/students-enrolled-image.png")
const OurMissionIcon = require("../assets/images/our-mission-icon.png")
const OurVisionIcon = require("../assets/images/our-vision-icon.png")
const PlatformHistoryImage = require("../assets/images/about-1-border.png")
const AboutUsLastImage = require("../assets/images/about-last-image.png");
const StudentGirl = require("../assets/images/student-girl.png")
const RecentCourseImageOne = require("../assets/images/recentCourseOne.png")
const RecentCourseImageTwo = require("../assets/images/recentCourseTwo.png")
const RecentCourseImageThree = require("../assets/images/recentCourseThree.png")
const BackgroundGirlImage = require("../assets/images/student-girl.png")
const EnrollImage = require("../assets/images/enrollImage.png")
const ReferFriend = require("../assets/images/refer-earn-image.png")
const BarArrowIcon = require("../assets/images/bar-arrow-icon.png")
const BarDollarIcon = require("../assets/images/bar-dollar-icon.png")
const BoardPencilIcon = require("../assets/images/board-pencil-icon.png")
const CircleDotIcon = require("../assets/images/circle-dot-icon.png")
const CodePlayIcon = require("../assets/images/code-play-icon.png")
const ColorPallateIcon = require("../assets/images/color-pallete.png")
const FilmCameraIcon = require("../assets/images/film-camera-con.png")
const PlayPencilIcon = require("../assets/images/play-pencile.png")
const VirusIcon = require("../assets/images/virus-icon.png")
const UserProfileDefault = require("../assets/images/userProfile-default.png")
const IndiaFlag = require("../assets/images/india-flag.png")
const DollarSack = require("../assets/images/dollar-sack.png")
const HashBox = require("../assets/images/hashbox.png")
const HouseHackerLogo = require("../assets/images/house-hacker.png")
const MartinsAcademyLogo = require("../assets/images/martins-academy.png")
const MatrixLogo = require("../assets/images/matrix.png")
const OakLegueLogo = require("../assets/images/oak-legue.png")
const FunAcademyLogo = require("../assets/images/fun-academy.png")
const ForgetPasswordImage = require("../assets/images/forgetPasswordImage.png")
const ResetPasswordImage = require("../assets/images/newpasswordImage.png")
const PaymentCardImage = require("../assets/images/payment-card-image.png")
const Avatar = require("../assets/images/avatar.png")
const CourseBannerImage = require("../assets/images/singleCourseBanner.png")
const BellIcon = require("../assets/images/bellIicon.png")
const NotificationTextIcon = require("../assets/images/notification-text-icon.png")
const BlogBannder = require("../assets/images/about-us-banner.png")
const TestimonialBanner = require("../assets/images/testimonial-banner.png")


export{
    Logo,
    BackgroundImage,
    mobile,
    monitor,
    d3des,
    cupcake,
    song,
    featureImg1,
    featureImg2,
    featureImg3,
    arrowUpRight,
    MyCOurseImg,
    RewardTrophy,
    PieChart,
    Coins,
    StarImage,
    BlogBanner,
    BlogNewsLetterCover,
    ContactUsBannner,
    ConatctUsFormImage,
    EnrolledStudentsImage,
    OurVisionIcon,
    OurMissionIcon,
    AboutUsLastImage,
    PlatformHistoryImage,
    StudentGirl,
    RecentCourseImageOne,
    RecentCourseImageTwo,
    RecentCourseImageThree,
    BackgroundGirlImage,
    EnrollImage,
    ReferFriend,
    BarArrowIcon,
    BarDollarIcon,
    BoardPencilIcon,
    CircleDotIcon,
    CodePlayIcon,
    ColorPallateIcon,
    FilmCameraIcon,
    PlayPencilIcon,
    VirusIcon,
    UserProfileDefault,
    IndiaFlag,
    HashBox,
    DollarSack,
    HouseHackerLogo,
    MartinsAcademyLogo,
    MatrixLogo,
    OakLegueLogo,
    FunAcademyLogo,
    ForgetPasswordImage,
    ResetPasswordImage,
    PaymentCardImage,
    Avatar,
    CourseBannerImage,
    BellIcon,
    NotificationTextIcon,
    BlogBannder,
    TestimonialBanner
   
}