import React, { useState, useEffect } from "react";
import { Logo } from "../../constant/images";
import { MdOutlinePhone } from "react-icons/md";
import { CiMail } from "react-icons/ci";
import { AiOutlineGlobal } from "react-icons/ai";
import { CiLocationOn } from "react-icons/ci";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import * as getServices from "../../services/getServices";
import { useDispatch, useSelector } from "react-redux";
import { DownOutlined } from "@ant-design/icons";

import {
   
    Dropdown,
    Space,
   
} from "antd";


const Footer = () => {
    const [pages, setPages] = useState([]);
    const dispatch = useDispatch();
    const [contactInformation, setContactInformation] = useState();
    const logo = useSelector((state) => state?.user?.logoData)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const contactInfoResponse = await dispatch(getServices?.getCOntactInformation());
                setContactInformation(contactInfoResponse);
            } catch (err) {
                console.error("Error fetching contact information:", err);
            }

            try {
                const pagesResponse = await dispatch(getServices?.getAllPages());
                setPages(pagesResponse);
            } catch (err) {
                console.error("Error fetching pages:", err);
            }
        };
       
        fetchData();
    }, []);

   
    const pageMenu = (pages) => {
        let new_menu = [];
        pages.map((page) => new_menu.push({ label: page.title, key: page.id }));
        return new_menu;
    };

    const handleCategoryDropdownSearch = (e) => {
        e.preventDefault()
    
    }

    const setServiceScroll = () => {
        localStorage.setItem("scrollTo", "services");
        setTimeout(() => {
            localStorage.removeItem("scrollTo");
        }, 2000);
    };
    
    return (
        <footer>
            <div className="footer-grid">
                <div className="footer-row row">
                    <div className="col-lg-4 col-md-6 mb-3">
                        <div className="grid-item">
                            <div className="footer-logo">
                                <img
                                    className="well-of-fun-logo"
                                    src={logo?.back_logo}
                                    alt="alpha97 logo white"
                                />
                            </div>
                            <p className="footer-text">{contactInformation?.description}</p>

                            <div className="social-link">
                                <a
                                    href={contactInformation?.twitter}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="twitter-icon-container"
                                >
                                    <FaTwitter />
                                </a>
                                <a
                                    href={contactInformation?.facebook}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="facebook-icon-container"
                                >
                                    <FaFacebookF />
                                </a>
                                <a
                                    href={contactInformation?.instagram}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="insta-icon-container"
                                >
                                    <FaInstagram />
                                </a>
                                <a
                                    href={contactInformation?.github}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="git-icon-container"
                                >
                                    <FaGithub />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-3">
                        <ul className="grid-item text-decoration-none">
                            <h4 className="item-heading">Our Links</h4>
                            <li style={{ listStyleType: "none" }} className="list-item">
                                <a style={{ textDecoration: "none" }} href="/categories">
                                    Categories{" "}
                                </a>
                            </li>

                            <li style={{ listStyleType: "none" }} className="list-item">
                                <a
                                    style={{ textDecoration: "none", color: "#52525B" }}
                                    href="/user/wishlist"
                                >
                                    Wishlist
                                </a>
                            </li>


                            <li style={{ listStyleType: "none" }} className="list-item">
                                <a
                                    style={{ textDecoration: "none", color: "#52525B" }}
                                    href="/blogs"
                                >
                                    Blogs
                                </a>
                            </li>
                            <li style={{ listStyleType: "none" }} className="list-item">
                                <a
                                    style={{ textDecoration: "none", color: "#52525B" }}
                                    href="/faq"
                                >
                                    FAQ
                                </a>
                            </li>
                            {pages.length > 0 &&
                                pages?.map((page) => {
                                    return page.child_data.length > -1 ? (
                                        <li style={{ listStyleType: "none" }} className="list-item">
                                            <a
                                                style={{ textDecoration: "none", color: "#52525B" }}
                                                href={`${page.slug}`}>
                                                {page.title}
                                            </a>
                                        </li>
                                    ) : (
                                        <Dropdown menu={pageMenu(page.child_data)}>
                                            <a href="/" onClick={(e) => handleCategoryDropdownSearch}>
                                                <Space>
                                                    <div className="heading">{page.title}</div>
                                                    <DownOutlined />
                                                </Space>
                                            </a>
                                        </Dropdown>
                                    );
                                })}
                        </ul>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="cont-address">
                            <h2>Contact Information</h2>
                            <div className="contact-child-1">
                                <div className="icon-class">
                                    <MdOutlinePhone color="#106AB3" />
                                </div>
                                <a href={`tel:${contactInformation?.phone}`}>
                                    <p>{contactInformation?.phone}</p>
                                </a>
                            </div>
                            <div className="contact-child-1">
                                <div className="icon-class">
                                    <CiMail color="#106AB3" />
                                </div>
                                <a href={`mailto:${contactInformation?.email}`}>
                                    <p>{contactInformation?.email}</p>
                                </a>
                            </div>
                            <div className="contact-child-1">
                                <div className="icon-class">
                                    <AiOutlineGlobal color="#106AB3" />
                                </div>
                                <a
                                    href={`${contactInformation?.website.startsWith("http")
                                        ? ""
                                        : "https://"
                                        }${contactInformation?.website}`}
                                >
                                    <p>{contactInformation?.website}</p>
                                </a>
                            </div>
                            <div className="contact-child-1">
                                <div className="icon-class">
                                    <CiLocationOn color="#106AB3" />
                                </div>
                                <a href="https://maps.app.goo.gl/nc8BzUEVmEq5HGmR9">
                                    <p>{contactInformation?.address}</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copy-right-outer-container">
                <div className="copy-right-container col-md-12">
                    <p className="copyright">© Copyright 2024, All Rights Reserved</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
