const initialState = {
  userType: null,
  userID: null,
  userData: null,
  isUserLoggedIn: false,
  isVendorLoggedIn: false,
  notificationData:null,
  token: null,
  logoData:null
  
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_TYPE":
      return {
        ...state,
        userType: action.payload,
      };
    case "SET_USER_ID":
      return {
        ...state,
        userID: action.payload,
      };
      case "SET_USER_DATA":
        return {
          ...state,
          userData: action.payload,
        };
        case "IS_USER_LOGGED_IN":
          return {
            ...state,
            isUserLoggedIn: action.payload,
          };
          case "IS_VENDOR_LOGGED_IN":
          return {
            ...state,
            isVendorLoggedIn: action.payload,
          };
          case "NOTIFICATION_DATA":
            return {
              ...state,
              notificationData: action.payload,
            };
            case "TOKEN":
              return {
                ...state,
                token: action.payload,
              };
            case "LOGO":
              return {
                ...state,
                logoData: action.payload,
              };
    default:
      return state;
  }
};

export default userReducer;
