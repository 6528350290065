import React from 'react'
import { Tabs  } from 'antd';
import UserRegister from '../User/userRegister';
import VendorRegister from '../Vendor/vendorRegister';
import Header from "../../components/Header";
import Footer from "../../components/Footer/footer";

export default function Register() {

    const items = [
        {
          key: '1',
          label: 'Register as User',
          children: <UserRegister />,
        },
        {
          key: '2',
          label: 'Register as Vendor',
          children: <VendorRegister/>,
        }
      ];
    const onChange = (key) => {
        // console.log(key);
      };
  return (
    <>
      <Header />
    <div>
       <div className='register-container'>
     <div className="register-div">
    <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </div>
  </div>
    </div>
    <Footer />
    </>
  )
}
