import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/Sidebar/sidebar";
import { Row, Col, Table, Rate, Image, Button } from "antd";
import { MyCOurseImg } from "../../../constant/images";
import { BiCartDownload } from "react-icons/bi";
import { IoEyeOutline } from "react-icons/io5";
import { RiDeleteBin6Line } from "react-icons/ri";
import TopNavBar from "../../../components/topNavbar";
import * as getService from "../../../services/getServices";
import { useDispatch, useSelector } from "react-redux";
import * as uploadService from "../../../services/uploadServices";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import CopyWriteFooter from "../../../components/Footer/copywriteFooter"


export default function Wishlist() {
    const userData = useSelector((state) => state.user.userData);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [wishlistData, setWishlistData] = useState([])

    const fetchWishlistData = async () => {
        dispatch(getService.GetWistlistDataUser(userData?.user_id
            ? userData?.user_id
            : userData?.id
                ? userData?.id
                : userData?.User?.id)).then((response) => {
                    setWishlistData(response?.course_details)
                }).catch((error) => {
                    console.error("Error fetching wishlist data", error)
                })
    }

    useEffect(() => {
        try {
            fetchWishlistData();
        } catch (e) {
            console.error(e)
        }
    }, [])

    const findAverateRating = (ratingData) => {
        if (!ratingData || ratingData.length === 0) {
            return 0;
        }
        let sum = 0;
        for (let i = 0; i < ratingData.length; i++) {
            sum += parseFloat(ratingData[i]?.rating || 0);
        }
        return sum / ratingData.length;
    };


    const handleRemoveCourse = (course) => {
        let formData = new FormData();

        formData.append("user_id", userData?.user_id
            ? userData?.user_id
            : userData?.User?.id
                ? userData?.User?.id
                : userData?.user?.id);
        formData.append("course_id", course?.id)

        try {

            dispatch(uploadService.addRemoveCourseWishlist(formData)).then((response) => {
                fetchWishlistData()
                
                Swal.fire(
                    "Success!",
                    `${response?.message}`,
                    "success"
                )
            }).catch((error) => {
                fetchWishlistData()
                Swal.fire(
                    "Failed",
                    "Unable to add item to wishlist.",
                    "error"
                )
            })

        } catch (e) {
            Swal.fire(
                "Failed",
                "Unable to add item to wishlist.",
                "error"
            )
        }


    }

    const handleCourseData = (values) => {
        console.info({ values });
        navigate(`/course/${values?.id}/${values?.title}`, {
            state: { courseData: values },
        });
    };
    const columns = [
        {
            title: "My Items",
            dataIndex: "My Courses",
            key: "My Courses",
            render: (text, record) => (
                <div className="wish-image-my-course">
                    <Image
                        src={record?.image}
                        alt="Not Found"
                        className="img-fluid wish-image"
                        preview={false}
                    />
                    <p className="record-mycourse">{record?.title}</p>
                </div>
            ),
        },
        {
            title: "Price",
            dataIndex: "Price",
            key: "Price",
            render: (text, record) => (
                <div className="original-latest-price">
                    <p className="wish-latest-price">${record?.fee}</p>
                </div>
            ),
        },
        {
            title: "Ratings",
            dataIndex: "Ratings",
            key: "Ratings",
            render: (text, record) => {
                const averageRating = findAverateRating(record?.reviews || []);
                return (
                    <div className="ratings-rating">
                        <p className="wish-rating">{averageRating.toFixed(1)}</p>
                        <Rate
                            className="rating-star"
                            allowHalf
                            defaultValue={averageRating}
                        />
                        <p className="wish-total-review">({record?.reviews?.length || 0} reviews)</p>
                    </div>
                );
            },
        },
        {
            title: "Actions",
            dataIndex: "Actions",
            key: "Actions",
            render: (text, record) => (
                <>
                    <Button className="cart-button"><BiCartDownload color="#FFC227" width={12} height={10} /></Button>
                    <Button className="eye-button"><IoEyeOutline color="#106AB3" onClick={() => handleCourseData(record)} /></Button>
                    <Button className="delete-button" onClick={() => handleRemoveCourse(record)}><RiDeleteBin6Line color="#DA0734" /></Button>
                </>
            ),
        },
    ];

    const data = [
        {
            myCourses: "Web Development",
            originalPrice: 1299,
            latestPrice: 999,
            Ratings: 4,
            total_review: 2089,
            image: MyCOurseImg,
        },
        {
            myCourses: "Web Development",
            originalPrice: 1299,
            latestPrice: 999,
            Ratings: 4,
            total_review: 2089,
            image: MyCOurseImg,
        },
        {
            myCourses: "Web Development",
            originalPrice: 1299,
            latestPrice: 999,
            Ratings: 4,
            total_review: 2089,
            image: MyCOurseImg,
        },
        {
            myCourses: "Web Development",
            originalPrice: 1299,
            latestPrice: 999,
            Ratings: 4,
            total_review: 2089,
            image: MyCOurseImg,
        },
    ];
    return (
        <>
            <TopNavBar />
            <Row>
                <Col xl={4} className="d-xl-block d-md-none">
                    <Sidebar />
                </Col>
                <Col xl={20} md={24}>
                    <div className="mainContent wishlist">
                        <div className="wishlist-container">
                            <h2 className="my-wishlist-head">My Wishlist</h2>

                            <Row>
                                <Col md={24}>
                                    <div className="table-responsive">
                                        <Table
                                            className="table"
                                            pagination={{ pageSize: 10 }}
                                            columns={columns}
                                            dataSource={wishlistData}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <CopyWriteFooter />
                </Col>
            </Row>
        </>
    );
}
