import React,{useState} from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

const BannerSection = () => {
  const navigate = useNavigate();

  const [courseNameSearch,setCourseNameSearch] = useState()

  const handleCourseNameSearch = (value) =>{
    setCourseNameSearch(value)
  }

  const handleSearchHome = () =>{
     navigate("/search", {
      state: {
        courseName: courseNameSearch,
        category: null,
        subCategory:null,
        childCategory:null,
      },
    })
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchHome();
    }
  };
  return (
    <section className="bannerSection">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="innerSection">
              <div className="textSection">
                <h3 className="title">
                  A Learning Paths Tailored to Your Goals
                </h3>
                <h2 className="mainTitle">Browse Our Items Now!</h2>
              </div>
              <div className="searchSection">
                <div className="innerSection">
                  <Input
                    className="form-control"
                    placeholder="Search by Item name"
                    prefix={
                      <CiSearch className="search-icon" color="#106AB3" />
                    }
                    onChange={(e) => handleCourseNameSearch(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  <button
                    onClick={handleSearchHome}
                    type="button"
                    className="btn button-style"
                  >
                    <CiSearch className="search-icon" /> &nbsp;Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerSection;
