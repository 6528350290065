function InProgressChart(courseLength, completedCourseLength, data) {

    const percentageCompleted = (completedCourseLength / courseLength) * 100;

    const options = {
        chart: {
            type: "pie",
            height: 100,
            width: 100,
            spacing: [0, 0, 0, 0],
            margin: 0,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
        },
        title: {
            text: null
        },
        xAxis: {
            labels: {
                enabled: false
            },
            tickLength: 1
        },
        yAxis: [{
            max: 100
        }],
        plotOptions: {
            pie: {
                innerSize: '50%',
                depth: 45,
                dataLabels: {
                    enabled: false,
                },
                borderWidth: 0,
                borderColor: null,
                center: ['50%', '50%']
            }
        },
        series: [{
            pointWidth: 25,
            type: 'pie',
            size: '40%',
            innerSize: '85%',
            data: [
                {
                    name: 'Completed',
                    y: 80,
                    color: "#1EAA39"
                },
                {
                    name: 'Remaining',
                    y: 20,
                    color: "#EEEEEE"
                }
            ]
        }]
    };

    // Custom label in the center
    const customLabel = {
        chart: {
            renderTo: 'container',
            events: {
                render: function () {
                    var chart = this,
                        centerX = chart.plotLeft + (chart.plotWidth / 2),
                        centerY = chart.plotTop + (chart.plotHeight / 2);

                    if (!chart.customLabel) {
                        chart.customLabel = chart.renderer.text(
                            // `${percentageCompleted===0 || percentageCompleted==="0"?0:percentageCompleted.toFixed(1)}%`,
                            "80%",
                            centerX,
                            centerY,
                            true
                        ).css({
                            fontSize: '30px',
                            color: '#000',
                            fontFamily: 'Poppins',
                            textAlign: 'center'
                        }).attr({
                            align: 'center',
                            zIndex: 10
                        }).add();
                    } else {
                        chart.customLabel.attr({
                            // text: `${percentageCompleted===0 || percentageCompleted==="0"?0:percentageCompleted.toFixed(1)}%`
                            text:"80%"
                        });
                    }
                }
            }
        }
    };

    return { ...options, ...customLabel };
}

export { InProgressChart };
