import React, { useEffect, useState } from "react";
import { Flex, Card, Dropdown, Spin } from "antd";
import * as getServices from "../../services/getServices";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import { featureImg2, Logo } from "../../constant/images";
import { FaLock } from "react-icons/fa";
import { FaUnlock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import * as uploadService from "../../services/uploadServices";
import { FaSort } from "react-icons/fa";
import Swal from "sweetalert2";
import { LoadingOutlined } from '@ant-design/icons';
import { atom, useAtom } from 'jotai';
import { coursesType } from "../../Helpers/atoms/freeCoursesAtom";

const FeaturesCourses = ({
    freeCourses,
    courses,
    categories,
    isModalOpen,
    setIsModalOpen,
    setPaymentCourseData
}) => {
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = useSelector((state) => state.user.userData);
    const [paymentDetails, setPaymentDetails] = useState();
    const [sponsoredCourses, setSponsoredCourses] = useState([]);
    const [filterValue, setFilterValue] = useState("");
    const [loader, setLoader] = useState(false);
    const [sponsoredHeading, setSponsoredHeading] = useState("Sponsored Items");
    const [wishlistData, setWishlistData] = useState([])


    useEffect(() => {
        if (
            userData?.user_id
                ? userData?.user_id
                : userData?.User?.id
                    ? userData?.User?.id
                    : userData?.user?.id !== undefined
        ) {
            dispatch(
                getServices?.getPaymentDetails(
                    userData?.user_id
                        ? userData?.user_id
                        : userData?.User?.id
                            ? userData?.User?.id
                            : userData?.user?.id
                )
            )
                .then((response) => {
                    setPaymentDetails(response?.payments);
                })
                .catch((err) => {
                    console.error({ err });
                });
        }

        getWishlistData()
    }, []);

    const getWishlistData = () => {
        dispatch(getServices.GetWistlistDataUser(userData?.user_id
            ? userData?.user_id
            : userData?.User?.id
                ? userData?.User?.id
                : userData?.user?.id)).then((response) => {
                    setWishlistData(response?.course_details)
                }).catch((error) => {
                    console.error({ error });
                })
    }



    useEffect(() => {
        const fetchSponsoredCourses = async () => {
            setLoader(true)
            try {
                let response = await dispatch(getServices?.getSponsoredCourses());
                let courses = response?.data || [];
                setSponsoredCourses(courses);
            } catch (err) {
                console.error({ err });
            }
            setLoader(false);
        };

        fetchSponsoredCourses();
    }, []);

    const handleWishlistList = (course) => {

        let formData = new FormData();

        formData.append("user_id", userData?.user_id
            ? userData?.user_id
            : userData?.User?.id
                ? userData?.User?.id
                : userData?.user?.id);
        formData.append("course_id", course?.id)

        try {

            dispatch(uploadService.addRemoveCourseWishlist(formData)).then((response) => {
                getWishlistData()
 
                Swal.fire(
                    "Success!",
                    `${response?.message}`,
                    "success"
                )
            }).catch((error) => {
                getWishlistData()
                Swal.fire(
                    "Failed",
                    "Unable to add items to wishlist.",
                    "error"
                )
            })

        } catch (e) {
            Swal.fire(
                "Failed",
                "Unable to add item to wishlist.",
                "error"
            )
        }

    }


    const Category = (category_id) => {
        const category = categories?.find(
            (category) => category?.id === category_id
        );
        return category?.name;
    };

    const handleCourseData = (values) => {
        navigate(`/course/${values?.id}/${values?.title}`, {
            state: { courseData: values?.course, vendorData: values?.vendor },
        });
    };

    const filteredCourses = (sponsoredCourses || [])
        .filter((courseData) => {
            const course = courseData?.course;
            const isFree = course?.is_premium === "free";
            const hasTrial = course?.trial !== 0;
            return course && course.status !== "pending" && (isFree || hasTrial);
        })

    const settingsFeature = {
        dots: false,
        speed: 500,
        arrow: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        autoplay: false,
        autoplaySpeed: 1000,
        arrows:
            freeCourses ? filteredCourses && filteredCourses?.filter((course) => course?.course?.status !== "pending")?.length > 3 : sponsoredCourses && sponsoredCourses?.filter((course) => course?.course?.status !== "pending")?.length > 3,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const handlePurchase = (coursedata) => {
        if (isUserLoggedIn || isVendorLoggedIn) {
            setPaymentCourseData(coursedata);
            setIsModalOpen(!isModalOpen);
        } else {
            navigate("/login");
        }
    };

    const DateFormatter = (date) => {
        const inputDate = date;

        const dateObj = new Date(inputDate);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };

    const handleFreeCourse = (courseData) => {
        try {
            const formData = new FormData();
            formData.append(
                "user_id",
                parseInt(
                    userData?.user_id
                        ? userData?.user_id
                        : userData?.id
                            ? userData?.id
                            : userData?.User?.id,
                )
            );
            formData.append(
                "course_id", courseData?.id
            );
            formData.append("email", userData?.email
                ? userData?.email
                : userData?.user?.email
                    ? userData?.user?.email
                    : userData?.User?.email ? userData?.User?.email : userData?.user_email);
            formData.append("amount", 0);
            formData.append("course_fee", 0);
            dispatch(uploadService.payment(formData)).then((response) => {
                Swal.fire(
                    "Items Enrolled Successfully",
                    "You have been successfully enrolled in the Items.",
                    "success"
                )

            }).catch((error) => {
                Swal.fire("Failed", error.message);
            })
        } catch (e) {
            console.error('Error in free item purchase', e)
            return
        }
    }
   

    return (
        <>
            <div className="container" >
                <div className="sponsoredCourses-section" >
                    <h1 className="feature-header border-bottom-heading bottom-border-center">
                        {sponsoredHeading}
                    </h1>
                </div>
            </div>
            <div className="container" style={{ width: "100%" }}>
                {loader ? (
                    <div className="d-flex justify-content-center align-items-center mt-4 mb-4">
                        <Spin
                            indicator={
                                <LoadingOutlined
                                    style={{
                                        fontSize: 48,
                                    }}
                                    spin
                                />

                            }
                        />
                    </div>
                ) : (
                    <>
                        {(freeCourses ? filteredCourses : sponsoredCourses).length > 0 ? (

                            <Slider className="slider-outer-2 row" {...settingsFeature}>
                                {(freeCourses ? filteredCourses : sponsoredCourses)
                                    ?.filter((courseData) => courseData?.course?.status !== "pending" && courseData?.course !== null)
                                    ?.map((course) => (
                                        <>
                                     
                                            <h5 className="main-category mb-0">{course?.course?.parent_category_name}</h5 >
                                            {course?.course?.status === "approved" ? (
                                                <Card
                                                    className="feature-card home-card"
                                                    hoverable
                                                    cover={
                                                        <img
                                                            onClick={(e) => handleCourseData(course)}
                                                            style={{ height: "250px", cursor: "pointer" }}
                                                            src={course?.course?.image ? course?.course?.image : featureImg2}
                                                            alt="Course Image"
                                                        />

                                                    }
                                                >
                                                    <div className="card-position-div">
                                                        <div
                                                            style={{
                                                                zIndex: "99",
                                                                padding: "10px 15px",
                                                                // backgroundColor: "#106ab369",
                                                                backgroundColor: "#106ab3",
                                                                borderRadius: "0 10px 10px 0",
                                                            }}
                                                            className="expire-container"
                                                        >
                                                            {paymentDetails?.some(
                                                                (item) => item?.course_id == course?.course?.id
                                                            ) ? (
                                                                <span className="Lock-icon">
                                                                    <FaUnlock color="#fff" />
                                                                </span>
                                                            ) : (
                                                                <span className="Lock-icon">
                                                                    <FaLock color="#fff" />
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div
                                                            style={{
                                                                zIndex: "99",
                                                                padding: "10px 10px",
                                                                backgroundColor: "#106ab3",
                                                                borderRadius: "10px 0 0 10px",
                                                            }}
                                                            className="expire-container"
                                                        >
                                                            <div className="start-end-date-container">
                                                                <p
                                                                    style={{ color: "#fff", margin: 0 }}
                                                                    className="start-enddate"
                                                                >
                                                                    {course?.course_start && course?.course_end
                                                                        ? `${DateFormatter(
                                                                            course?.course_start
                                                                        )} - ${DateFormatter(course?.course?.course_end)}`
                                                                        : "12 July 2024 - 15 August 2025"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ cursor: "pointer" }} onClick={(e) => handleCourseData(course)}>

                                                        <div

                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <Flex
                                                                gap="middle"
                                                                className="feature-course-subhead-price"
                                                            >
                                                                <div>
                                                                    <p className="wof-certified-logo"><span><img style={{ width: "20px", height: "20px" }} src={Logo} /></span>WOF Certified</p>
                                                                    <p className="course-Name" >
                                                                        {Category(course?.course?.category_id)
                                                                            ? Category(course?.course?.category_id)
                                                                            : "Web Development"}
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p
                                                                        className="course-fee"

                                                                    >
                                                                        ${course?.course?.fee}
                                                                    </p>
                                                                </div>
                                                            </Flex>
                                                            <Flex className="feature-course-subhead-price">
                                                                <h4>{course?.course?.title}</h4>
                                                            </Flex>
                                                        </div>
                                                        <div>
                                                            <p>
                                                                <p className="description-of-course">
                                                                    {course?.course?.about_course}
                                                                </p>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <Flex className="feature-course-subhead-price customButtonSection">
                                                        {paymentDetails?.some(
                                                            (item) => item?.course_id == course?.course?.id
                                                        ) ? (
                                                            <>
                                                                <button
                                                                    type="button"
                                                                    class="btn feature-button view-course-button"
                                                                    onClick={(e) => handleCourseData(course?.course)}
                                                                >
                                                                    View Items
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <button
                                                                    type="button"
                                                                    class="btn feature-button add-to-wishlist"
                                                                    onClick={() => handleWishlistList(course?.course)}
                                                                >
                                                                    {wishlistData?.some((wish) => parseInt(wish?.id) === parseInt(course?.course?.id))
                                                                        ? "Remove Wishlist"
                                                                        : "Add to Wishlist"
                                                                    }
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    class="btn feature-button enroll-button"
                                                                    onClick={() => course?.course?.is_premium === "free" ? handleFreeCourse(course?.course) : handlePurchase(course?.course)}
                                                                >
                                                                    Purchase Now
                                                                </button>
                                                            </>
                                                        )}
                                                    </Flex>
                                                </Card>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    ))}
                            </Slider>

                        ) : (
                            <p style={{
                                fontSize: "24px",
                                fontWeight: "600",
                                marginTop: "20px",
                                fontFamily: 'Poppins',
                                lineHeight: "150%"
                            }} className="text-center">No Item Found</p>
                        )}
                    </>
                )}
            </div>


        </>
    );
};

export default FeaturesCourses;
