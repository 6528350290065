import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as uploadServices from "../../services/uploadServices";

export default function CheckoutForm({ paymentCourseData, sponrshipPlan,onData }) {
  const userData = useSelector((state) => state.user.userData);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [randorTransactionId,setTransactionId] = useState("")

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    if(sponrshipPlan !== undefined){

      const paymentResponse = await stripe.confirmPayment({
        elements,
        confirmParams: {
        },
        redirect: "if_required",
      });
  
      if (paymentResponse?.error) {
        if (paymentResponse?.error?.type === "card_error" || paymentResponse?.error?.type === "validation_error") {
          setMessage(paymentResponse?.error.message);
        } else {
          setMessage("An unexpected error occured.");
        }
        setIsProcessing(false);
        return;
      } else {
        try {
          const formData = new FormData();
  
          formData.append("vendor_id", paymentCourseData?.course?.vendor_id);
          formData.append("course_id", paymentCourseData?.course?.id);
          formData.append("sponsore_id", sponrshipPlan?.id ? sponrshipPlan?.id : 0);
          formData.append("transection_id", paymentResponse.paymentIntent.id);
          formData.append("amount", sponrshipPlan?.price ? sponrshipPlan?.price : 90);
  
     
  
          let paymentType = localStorage.getItem("paymentType")
          if (paymentType === "sponsoredCourse") {
            dispatch(uploadServices.SponsoredCoursesPayment(formData)).then((response) => {
             
              navigate("/thankyou",{
                state: {
                  transaction_id:paymentResponse.paymentIntent.id
                }
              })
            }).catch((error) => {
              console.error({ sponsorshipPaymentError: error })
              setMessage("Failed to process the payment. Please try again.");
              setIsProcessing(false);
              localStorage.removeItem("paymentType")
              return;
            })
          } else {
         
          }
        } catch (apiError) {
          console.error("API Error:", apiError);
          setMessage("Failed to process the payment. Please try again.");
          setIsProcessing(false);
          localStorage.removeItem("paymentType")
          return;
        }
      }

    }else{
     const transaction_id  = generateRandomString(24)
     onData(transaction_id);
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <div className="payment-button-container">
        <button disabled={isProcessing || !stripe || !elements} id="submit">
          <span id="button-text">
            {isProcessing ? "Processing ... " : "Pay now"}
          </span>
        </button>
      </div>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
