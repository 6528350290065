import React, { useState, useEffect } from "react";
import {
    Layout,
    Button,
    Drawer,
    Row,
    Col,
    Dropdown,
    Space,
    Menu,
    Popover,
    Image,
    Modal,
    Form,
    Input,
    Upload,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { FaFileUpload } from "react-icons/fa";
// import LeftMenu from "./LeftMenu";
// import RightMenu from "./RightMenu";
import { MenuOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { Logo, NotificationTextIcon, BellIcon } from "../../constant/images";
import { DownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MdFavorite } from "react-icons/md";
import { FaRegBell } from "react-icons/fa";
import { CgMenuLeft } from "react-icons/cg";
import Notifications from "react-notifications-menu";
import { FaUser } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import { IoLogOut } from "react-icons/io5";

import {
    setUserData,
    setUserLoggedIn,
    setVendorLoggedIn,
    setToken,
    setSettingLogo, setNotification
} from "../../store/actions/user";
import { Avatar } from "../../constant/images";
import * as updateServices from "../../services/updateServices";
import * as getServices from "../../services/getServices";
import * as uploadService from "../../services/uploadServices";
import Swal from "sweetalert2";
import { DEMO_FRONT_URL } from "../../Helper";
import { coursesType } from "../../Helpers/atoms/freeCoursesAtom";
import { atom, useAtom } from 'jotai';

const Navbar = () => {
    window.scrollTo(0, 0);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [categories, setCategories] = useState([]);
    let location = useLocation();
    const vendordata = useSelector((state) => state.user.userData);
    const notificationData = useSelector((state) => state.user.notificationData);
   

    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [pages, setPages] = useState([]);
    const [selectedData, setSelectedData] = useState(null);
    const [fileList, setFileList] = useState([]);
    const { SubMenu } = Menu;
    const [notifications, setNotifications] = useState([]);
    const [logo, setLogo] = useState('')

    // Use the atom in your component
    const [coursetype, setCoursetype] = useAtom(coursesType);
   
    const [open, setOpen] = useState(false);
    const [activeButton, setActiveButton] = useState(null);

    const handleButtonClick = (buttonType) => {
        setActiveButton(buttonType);
        navigate("/course-listing")
    };

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const DateFormatter = (date) => {
        const inputDate = date;

        const dateObj = new Date(inputDate);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };

    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);

        if (newFileList[0]?.status === "error" || newFileList[0]?.status == "success") {
            onFinishProfile(newFileList);
        }
    };

    const onFinishProfile = (newFileList) => {
        if (!newFileList.length) {

            return;
        }

        const formData = new FormData();
        const userId =
            vendordata?.user_id || vendordata?.User?.id || vendordata?.user?.id;

        if (!userId) {

            return;
        }

        formData.append("user_id", userId);
        formData.append("images", newFileList[0]?.originFileObj);

        dispatch(updateServices.updateProfileImage(formData))
            .then((response) => {
                Swal.fire(
                    "Profile Updated Successfully!",
                    "You have successfully updated your profile",
                    "success"
                ).then((result) => {
                    if (isVendorLoggedIn) {
                        dispatch(getServices.getVendorDetails(userId))
                            .then((response) => {
                                dispatch(setUserData(response));
                                window.location.reload();
                            })
                            .catch((error) => {
                                // console.error("Error updating profile image:", error);
                            });
                    } else {
                        dispatch(getServices.getUserDetails(userId))
                            .then((response) => {
                                dispatch(setUserData(response));
                                window.location.reload();
                            })
                            .catch((error) => {
                                // console.error("Error updating profile image:", error);
                            });
                    }
                });
            })
            .catch((error) => {
                // console.error("Error updating profile image:", error);

                Swal.fire("Failed", error?.response?.data?.message, "erroe");
            });
    };



    useEffect(() => {
        dispatch(getServices?.getCategories())
            .then((response) => {
           
                setCategories(response?.categories);

            })
            .catch((err) => {
                console.error({ err });
            });
        dispatch(getServices.getNotifications())
            .then((response) => {
                const formattedNotifications = response?.data.map(notification => ({
                    ...notification,
                    isRead: false
                }));
                setNotifications(formattedNotifications);
            })
            .catch((err) => {
                console.error({ err });
            });
    }, [dispatch]);



    const handleMarkAllAsRead = (e) => {
        const isChecked = e.target.checked;
        const newNotifications = notifications.map(notification => ({
            ...notification,
            isRead: isChecked
        }));
        dispatch(setNotification(newNotifications))
        setNotifications(newNotifications);
    };
    dispatch(getServices?.getCOntactInformation())
        .then((result) => {
            setLogo(result?.front_logo)
            dispatch(setSettingLogo(result))
        })
    const handleClick = (category) => {
        navigate("/search", {
            state: {
                courseName: null,
                category: category?.id,
            },
        });
    };

    const handleLoggout = () => {
        localStorage.removeItem("token");
        dispatch(setToken(null));
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        sessionStorage.removeItem('email');
        sessionStorage.removeItem('password');
        dispatch(setVendorLoggedIn(false));
        dispatch(setUserLoggedIn(false));
        localStorage?.removeItem("scrollTo")
        dispatch(setUserData(""));
        dispatch(setSettingLogo(null));
        localStorage?.removeItem("activeRoute")
        localStorage?.removeItem("previoursPage")
        localStorage?.removeItem("forgetEmail")
        navigate("/login");
    };

    const handleMenuClick = (item, category, mainCategory) => {
        let data;

        if (mainCategory) {

            data = {
                category: mainCategory,
                subCategory: category,
                childCategory: item,
            };
        } else if (category) {

            data = {
                category: category,
                subCategory: item,
            };
        } else {

            data = {
                category: item,
            };
        }

        if (data.category !== null && data.subCategory === null && data.childCategory === null) {
            dispatch(uploadService.getCoursesByCategoryID({ parent_id: data?.category?.id })).then((response) => {
                navigate("/search", {
                    state: { cartegoryPageData: response?.data },
                });
            }).catch((err) => {
                console.error({ err });
            })
        } else {
            navigate("/search", {
                state: {
                    courseName: null,
                    category: data.category,
                    subCategory: data.subCategory,
                    childCategory: data.childCategory,
                },
            })
        }


        setSelectedData(data);
    };

    // const renderSubCategories = (subCategories, parent, grandParent) => {
    //     return subCategories.map((sub) => (
    //         <Menu.Item key={sub.id} onClick={() => handleMenuClick(sub, parent, grandParent)}>
    //             {sub.name}
    //         </Menu.Item>
    //     ));
    // };

    const renderSubCategories = (subCategories, parent, grandParent) => {
        return subCategories.map((sub) => (
            <Menu.Item key={sub.id} 
            
                onClick={ () => handleMenuClick(sub, parent, grandParent) }>
                {sub.name} ({sub.total_courses})
            </Menu.Item>
        ));
    };


    const renderChildCategories = (childCategories, parent) => {
        return childCategories.map((child) => (
            child.sub_categories && child.child_category.length > 0 ? (
                <Menu.SubMenu
                    key={child.id}
                    title={
                        <>
                            {child.name} ({child.total_courses})
                        </>
                    }
                    popupClassName="sub-menu-popup"
                    onTitleClick={() => handleMenuClick(null, child, parent)}
                    // onTitleClick={child?.total_courses ? () => handleMenuClick(null, child, parent) : undefined}
                >
                    {renderSubCategories(child.child_category, child, parent)}
                </Menu.SubMenu>
            ) : (
                    <Menu.Item key={child.id} 
                        onClick={child?.total_courses ? () => handleMenuClick(null, child, parent) : undefined}
                    >
                    {child?.name} ({child?.total_courses})
                    {child.child_category.length > 0 ? (child?.child_category?.total_courses) : ""}
                </Menu.Item>
            )
        ));
    };


    // const renderChildCategories = (childCategories, parent) => {
    //     return childCategories.map((child) => (
    //         child.sub_categories.length > 0 ? (
    //             <Menu.SubMenu
    //                 key={child.id}
    //                 title={
    //                     <>
    //                         {child.name} ({child.total_courses})
    //                     </>
    //                 }
    //                 popupClassName="sub-menu-popup"
    //                 onTitleClick={() => handleMenuClick(null, child, parent)}
    //             >
    //                 {renderSubCategories(child.sub_categories, child, parent)}
    //             </Menu.SubMenu>
    //         ) : (
    //             <Menu.Item key={child.id} onClick={() => handleMenuClick(null, child, parent)}>
    //                     {child.name} {child.child_category.length > 0 ? (child?.child_category?.total_courses) : ""}
    //             </Menu.Item>
    //         )
    //     ));
    // };

    // const renderCategories = (categories) => {
    //     console.log(categories,"categoryesss")
    //     return categories.map((category) => (
    //         category ? (
    //             <Menu.SubMenu
    //                 key={category.id}
    //                 title={
    //                     <>
    //                         {console.log(category?.child_category?.total_courses, "total course")}
    //                         {category.name} ({category?.child_category?.total_courses})
    //                     </>
    //                 }
    //                 onTitleClick={() => handleMenuClick(null, null, category)}
    //                 popupClassName="menu-popup"
    //             >
    //                 {renderChildCategories(category.child_category, category)}
    //             </Menu.SubMenu>
    //         ) : (
    //             <Menu.Item key={category.id} onClick={() => handleMenuClick(null, null, category)}>
    //                     {category.name} {category ? (category?.child_category?.total_courses) : ""}
    //             </Menu.Item>
    //         )
    //     ));
    // };

    const renderCategories = (categories) => {
        
        return categories.map((category) => (
            category ? (
                <Menu.SubMenu
                    key={category.id}
                    title={
                        <>
                            {category.name} ({category?.total_courses || ''})
                        </>
                    }
                    onTitleClick={category?.total_courses ? () => handleMenuClick(null, null, category) : undefined}
                    disabled={!category?.total_courses} // Disable if no total_courses

                    popupClassName="menu-popup"
                >
                    {renderChildCategories(category.child_category, category)}
                </Menu.SubMenu>
            ) : (
                    <Menu.Item key={category.id} onClick={category?.total_courses ? () => handleMenuClick(null, null, category) : undefined}
                    >
                    {category.name} {category ? (category?.child_category?.total_courses) : ""}
                </Menu.Item>
            )
        ));
    };

    const mainCategories = categories.map((category) => ({
        id: category.id,
        name: category.name,
        child_category: category.child_category,
        total_courses: category?.total_courses
    }));

    const menu = <Menu>{renderCategories(mainCategories)}</Menu>;

    useEffect(() => {
        setVisible(false);
    }, [location]);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const showModal = () => {
        if (isVendorLoggedIn) {
            navigate('/vendor/my-profile')
        } else {
            navigate('/user/my-profile')

        }

    };

    const renderContent = () => (
        <>
            <div className="edit-profile-container">
                <p className="edit-profile" onClick={showModal}>
                    <span><FaUser /></span>Edit Profile
                </p>
                {isVendorLoggedIn ? (
                    <p className="edit-profile" onClick={() => navigate("/vendor/change-password")}>
                        <span><RiLockPasswordFill /></span> Change Password
                    </p>
                ) : ("")}

                <p className="logout-button" onClick={handleLoggout}>
                    <span><IoLogOut /></span>Logout
                </p>
            </div>
        </>
    );

    const onFinish = (value) => {
        const formData = new FormData();
        formData.append(
            "user_id",
            vendordata?.user_id
                ? vendordata?.user_id
                : vendordata?.User?.id
                    ? vendordata?.User?.id
                    : vendordata?.user?.id
        );
        formData.append("images", file);

        dispatch(updateServices.updateProfileImage(formData))
            .then((response) => {
                Swal.fire(
                    "Profile Updated Successfully!",
                    "You have successfully updated your profile",
                    "success"
                ).then((result) => {
                    dispatch(
                        getServices.getVendorDetails(
                            vendordata?.user_id ? vendordata?.user_id : vendordata?.User?.id
                        )
                    )
                        .then((response) => {
                            dispatch(setUserData(response));
                            window.location.reload();
                        })
                        .catch((error) => {
                            console.error("Error updating profile image:", error);
                        });
                });
            })
            .catch((error) => {
                console.error("Error updating profile image:", error);
            });
    };



    const onFinishFailed = (err) => {
        console.error(err);
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const handleSignUp = () => {
        navigate("/register");
    };
    const handleLogin = () => {
        navigate("/login");
    };

    const handleSearch = () => {
        navigate("/search");
    };

    const handleNavigate = () => {
        if (isVendorLoggedIn) {
            localStorage.setItem("activeRoute", "/vendor/my-profile");
            navigate("/vendor/my-profile");
        } else {
            localStorage.setItem("activeRoute", "/user/my-profile");
            navigate("/user/my-profile");
        }
    };

    const pageMenu = (pages) => {

        let new_menu = [];
        pages.map((page) => new_menu.push({ label: page.title, key: page.id }));
        return new_menu;
    };

    const uploadButton = (
        <div>
            <div className="upload-icon-container">
                <FaFileUpload />
            </div>
            <div className="upload-drag-text" style={{ marginTop: 8 }}>
                Upload aur drag image
            </div>
        </div>
    );

    const handleCategoryDropdownSearch = (e) => {
        e.preventDefault()

    }
    const handleWishlist = () => {
        navigate("/wishlist");
    }

    const generateUniqueKeys = (items, parentKey = '') => {
        let keyIndex = 0;

        const assignKeys = (items, parentKey = '') => {
            return items.map(item => {
                const key = `${parentKey}${keyIndex++}`;
                return {
                    ...item,
                    key,
                    children: item.children ? assignKeys(item.children, `${key}-`) : []
                };
            });
        };

        return assignKeys(items);
    };
    const items = generateUniqueKeys([
        {
            id: '1',
            label: "What's On",
            children: mainCategories.map((category) => ({
                id: category.id,
                label: category.name,
                children: category.child_category.map((child) => ({
                    id: child.id,
                    label: child.name,
                    children: child.sub_categories.map((subCategory) => ({
                        id: subCategory.id,
                        label: subCategory.name
                    }))
                }))
            }))
        }
    ]);


    const getLevelKeys = (items) => {
        const key = {};
        const func = (items, level = 1) => {
            items.forEach((item) => {
                if (item.key) {
                    key[item.key] = level;
                }
                if (item.children) {
                    func(item.children, level + 1);
                }
            });
        };
        func(items);
        return key;
    };

    const levelKeys = getLevelKeys(items);

    const [stateOpenKeys, setStateOpenKeys] = useState([]);

    const onOpenChange = (openKeys) => {
        const latestOpenKey = openKeys.find((key) => !stateOpenKeys.includes(key));

        if (latestOpenKey) {
            const latestOpenKeyLevel = levelKeys[latestOpenKey];
            if (latestOpenKeyLevel === undefined) {
            }
            const newOpenKeys = openKeys.filter((key) => levelKeys[key] < latestOpenKeyLevel);
            newOpenKeys.push(latestOpenKey);

            setStateOpenKeys(newOpenKeys);
        } else {
            setStateOpenKeys(openKeys);
        }
    };


    const updatedNotifications = (notifications ? notifications : notificationData)?.map(notification => ({
        message: (
            <>
                <div className="notification-message-container" style={notification?.isRead != true ? { color: '#cecece' } : { color: '#000' }}>
                    <div className="notification-title-time">
                        <p className="notification-title" style={notification?.isRead == true ? { color: '#cecece' } : { color: '#000' }}>{notification?.title}</p>
                        <p className="notification-time">{DateFormatter(notification?.created_at)}</p>
                    </div>
                    <div className="notification-message-container">
                        <p className="notification-message" style={notification?.isRead == true ? { color: '#cecece' } : { color: '#000' }}>{notification?.message}</p>
                    </div>
                </div>
            </>

        ),
        isRead: notification.isRead,

    }));



    return (
        <div className="headerComponent">
            <Row>
                <Col xl={3} md={12} sm={12}>
                    <div className="logoWrapper">
                        <img
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/")}
                            className="well-of-fun-logo"
                            src={logo}
                            alt="Well of Fun Logo"
                        />
                    </div>
                </Col>
                <Col className="d-xl-none d-md-flex align-items-md-center mobile-bar-menu justify-content-md-end   d-sm-flex align-items-sm-center justify-content-sm-end" md={12} sm={12}>
                    <Button className="d-xl-none d-md-block menu-bar-icon" type="primary" onClick={showDrawer}>
                        <CgMenuLeft />
                    </Button>
                    <Drawer className="mobile-menu" title="Menu" onClose={onClose} open={open}>
                        <div className="d-flex justify-content-start align-items-center">
                            <div className="headingContainer d-flex align-items-center">
                                <div className="leftSection">
                                    <Menu
                                        mode="inline"
                                        style={{ width: 320 }}
                                        openKeys={stateOpenKeys}
                                        onOpenChange={onOpenChange}
                                    >
                                        {items.map((item) => (
                                            item.children && item.children.length > 0 ? (
                                                <SubMenu key={item.key} title={item.label}>
                                                    {item.children.map(child => (
                                                        child.children && child.children.length > 0 ? (
                                                            <SubMenu key={child.key} title={child.label}>
                                                                {child.children.map(sub => (
                                                                    sub.children && sub.children.length > 0 ? (
                                                                        <SubMenu key={sub.key} title={sub.label}>
                                                                            {sub.children.map(grandchild => (
                                                                                <Menu.Item key={grandchild.key}>{grandchild.label}</Menu.Item>
                                                                            ))}
                                                                        </SubMenu>
                                                                    ) : (
                                                                        <Menu.Item key={sub.key}>{sub.label}</Menu.Item>
                                                                    )
                                                                ))}
                                                            </SubMenu>
                                                        ) : (
                                                            <Menu.Item key={child.key}>{child.label}</Menu.Item>
                                                        )
                                                    ))}
                                                </SubMenu>
                                            ) : (
                                                <Menu.Item key={item.key}>{item.label}</Menu.Item>
                                            )
                                        ))}
                                    </Menu>
                                    <ul className="header-button-group d-flex justify-content-start align-items-center mb-0">
                                        <li>
                                            <Button
                                                className={`header-button ${activeButton === 'filter' ? 'active' : ''}`}
                                                onClick={() => handleButtonClick('filter')}
                                            >
                                                Filter
                                            </Button>
                                        </li>
                                        <li>
                                            <Button
                                                className={`header-button ${activeButton === 'free' ? 'active' : ''}`}
                                                onClick={() => {
                                                    handleButtonClick('free');
                                                    setCoursetype("free");
                                                }}
                                            >
                                                Free/Trial
                                            </Button>
                                        </li>
                                        <li>
                                            <Button
                                                className={`header-button ${activeButton === 'offer' ? 'active' : ''}`}
                                                onClick={() => handleButtonClick('offer')}
                                            >
                                                Offer
                                            </Button>
                                        </li>
                                        <li>
                                            <Button
                                                className={`header-button ${location.pathname === "/blogs" ? 'active' : ''}`}
                                                onClick={() => {
                                                    handleButtonClick('blog');
                                                    navigate("/blogs")
                                                }}
                                            >
                                                Blog & Community
                                            </Button>
                                        </li>
                                        <li>
                                            <Button
                                                className={`header-button ${activeButton === 'shop' ? 'active' : ''}`}
                                                onClick={() => handleButtonClick('shop')}
                                            >
                                                Shop
                                            </Button>
                                        </li>
                                    </ul>
                                    {/* <div className="linksWrapper">
                                        <ul>
                                            {pages.length > 0 &&
                                                pages?.map((page) => {
                                                    return page.child_data.length > -1 ? (
                                                        <li>
                                                            <a href={`${DEMO_FRONT_URL}/${page.slug}`}>
                                                                {page.title}
                                                            </a>
                                                        </li>
                                                    ) : (
                                                        <Dropdown menu={pageMenu(page.child_data)}>
                                                            <a href="/" onClick={(e) => handleCategoryDropdownSearch}>
                                                                <Space>
                                                                    <div className="heading">{page.title}</div>
                                                                    <DownOutlined />
                                                                </Space>
                                                            </a>
                                                        </Dropdown>
                                                    );
                                                })}
                                        </ul>
                                    </div> */}


                                </div>
                            </div>
                        </div>

                        <div className="rightMenuWrapper">
                            {isUserLoggedIn || isVendorLoggedIn ? (
                                <div className="profileWrapper">
                                    <div className="bellIconWrapper">
                                    </div>
                                    <div className="bellIconWrapper">
                                        <MdFavorite className="bell-icon" color="#106AB3" onClick={handleWishlist} />
                                    </div>
                                    <div className="profile-container">
                                        <Popover content={renderContent()} title="" trigger="click" placement="bottomLeft">
                                            <Image
                                                style={{ cursor: 'pointer' }}
                                                preview={false}
                                                width={37}
                                                height={37}
                                                src={
                                                    vendordata?.User?.images
                                                        ? vendordata?.User?.images
                                                        : vendordata?.image
                                                            ? vendordata?.image
                                                            : vendordata?.Vendor?.logo
                                                                ? vendordata?.Vendor?.logo
                                                                : Avatar
                                                }
                                            />

                                            <p
                                                style={{ cursor: 'pointer' }}
                                                className="owner-name"
                                            >
                                                {vendordata?.name || vendordata?.User?.name || 'John Doe'}
                                            </p>
                                        </Popover>
                                    </div>
                                </div>
                            ) : (
                                <div className="login-signup-container">
                                    <Button onClick={handleSignUp} className="btn signUp">
                                        Sign Up
                                    </Button>
                                    <Button onClick={handleLogin} className="btn login">
                                        Login
                                    </Button>
                                </div>
                            )}
                        </div>
                    </Drawer>
                </Col>
                <Col className="d-flex align-items-center header-col-menu  align-items-center" xl={15} md={12}>
                    <div className="d-flex justify-content-start align-items-center w-100">

                        <div className="headingContainer d-flex align-items-center d-xl-block d-md-none  w-100">
                            <div className="leftSection">
                                <Menu>
                                    <div className="menuItemsWrapper">
                                        <Dropdown overlay={menu} trigger={["hover"]}>
                                            <a href="/" onClick={(e) => e.preventDefault()}>
                                                <Space>
                                                    <div className="heading">What's On</div>
                                                    <DownOutlined />
                                                </Space>
                                            </a>
                                        </Dropdown>
                                        <div className="linksWrapper">
                                            <ul>
                                                {pages.length > 0 &&
                                                    pages?.map((page) => {
                                                        return page.child_data.length > -1 ? (
                                                            <li>
                                                                <a href={`${DEMO_FRONT_URL}/${page.slug}`}>
                                                                    {page.title}
                                                                </a>
                                                            </li>
                                                        ) : (
                                                            <Dropdown menu={pageMenu(page.child_data)}>
                                                                <a href="/" onClick={(e) => handleCategoryDropdownSearch}>
                                                                    <Space>
                                                                        <div className="heading">{page.title}</div>
                                                                        <DownOutlined />
                                                                    </Space>
                                                                </a>
                                                            </Dropdown>
                                                        );
                                                    })}
                                            </ul>
                                        </div>
                                    </div>
                                </Menu>
                                <ul className="header-button-group d-flex justify-content-start align-items-cemter gap-3 ms-5 mb-0">
                                    <li>
                                        <Button
                                            className={`header-button ${activeButton === 'filter' ? 'active' : ''}`}
                                            onClick={() => handleButtonClick('filter')}
                                        >
                                            Filter
                                        </Button>
                                    </li>
                                    <li>
                                        <Button
                                            className={`header-button ${activeButton === 'free' ? 'active' : ''}`}
                                            onClick={() => {
                                                handleButtonClick('free');
                                                setCoursetype("free");
                                            }}
                                        >
                                            Free/Trial
                                        </Button>
                                    </li>
                                    <li>
                                        <Button
                                            className={`header-button ${activeButton === 'offer' ? 'active' : ''}`}
                                            onClick={() => handleButtonClick('offer')}
                                        >
                                            Offer
                                        </Button>
                                    </li>
                                    <li>
                                        <Button
                                            className={`header-button ${location.pathname === "/blogs" ? 'active' : ''}`}
                                            onClick={() => {
                                                handleButtonClick('blog');
                                                navigate("/blogs")
                                            }}
                                        >
                                            Blog & Community
                                        </Button>
                                    </li>
                                    {/* <li>
                                        <Button
                                            className={`header-button ${activeButton === 'shop' ? 'active' : ''}`}
                                            onClick={() => handleButtonClick('shop')}
                                        >
                                            Shop
                                        </Button>
                                    </li> */}
                                </ul>
                            </div>
                        </div>

                        {/* <div>
                            <button className="btn btn-primary" >Blog & Community</button>
                        </div>
                        <div>
                            <button className="btn btn-primary" onClick={() => setCoursetype("free")}>Free/Trial Course</button>
                        </div> */}
                    </div>
                </Col>
                <Col className=" d-xl-block d-md-none d-xs-none" xl={6}>
                    <div className="rightMenuWrapper">
                        {isUserLoggedIn || isVendorLoggedIn ? (
                            <div className="profileWrapper">
                                <div className="bellIconWrapper">
                                    <Notifications
                                        data={updatedNotifications ? updatedNotifications : notificationData} 
                                        header={{
                                            option: {
                                                text: (
                                                    <div className="mark-all-container">
                                                        <input
                                                            className="mark-all-checkbox"
                                                            type="checkbox"
                                                            onChange={handleMarkAllAsRead} 
                                                        />
                                                        <span className="mark-all-text">Mark all as read.</span>
                                                    </div>
                                                ),
                                            },
                                        }}
                                        icon={BellIcon}
                                    />
                                </div>
                                <div className="bellIconWrapper">
                                    <MdFavorite className="bell-icon" color="#106AB3" onClick={handleWishlist} />
                                </div>
                                <div className="profile-container">
                                    <Popover
                                        content={renderContent()}
                                        title=""
                                        trigger="click"
                                        placement="bottomLeft"
                                        className="popover-container"
                                    >
                                        <Image
                                            style={{ cursor: "pointer" }}
                                            preview={false}
                                            width={37}
                                            height={37}
                                            // src={vendordata?.User?.images? vendordata?.image?vendordata?.Image:vendordata?.User?.images: UserProfileDefault}
                                            src={
                                                vendordata?.User?.images
                                                    ? vendordata?.User?.images
                                                    : vendordata?.image
                                                        ? vendordata?.image
                                                        : vendordata?.Vendor?.logo
                                                            ? vendordata?.Vendor?.logo
                                                            : Avatar
                                            }
                                        />

                                        <p
                                            style={{ cursor: "pointer" }}

                                            className="owner-name"
                                        >
                                            {vendordata?.name
                                                ? vendordata?.name
                                                : vendordata?.User?.name
                                                    ? vendordata?.User?.name
                                                    : "John Doe"}
                                        </p>
                                    </Popover>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="login-signup-container">
                                    <Button onClick={handleSignUp} className={`${location.pathname === "/login" ? "login" : 'signUp'}`}>
                                        Sign Up
                                    </Button>
                                    <Button onClick={handleLogin} className={`${location.pathname === "/register" ? "login" : 'signUp'}`}>
                                        Login
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </Col>
            </Row>
            <Modal open={isModalOpen} onOk={handleOk} className="imgUploadModal">
                <div className="modalCloseBtn" onClick={handleCancel}>
                    <CloseOutlined />
                </div>
                <div className="modalBody">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "1rem",
                        }}
                    >
                        <Upload
                            action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                            listType="picture-card"
                            fileList={fileList}
                            onChange={handleChange}
                        >
                            {fileList.length >= 1 ? null : uploadButton}
                        </Upload>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Navbar;
