import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/Sidebar/sidebar";
import { Row, Col, Image, Layout } from "antd";
import { MdWavingHand } from "react-icons/md";
import { MyCOurseImg, RewardTrophy } from "../../../constant/images";
import TopNavBar from "../../../components/topNavbar";
import { useSelector, useDispatch } from "react-redux";
import * as getServices from "../../../services/getServices";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { InProgressChart } from "../../../Helpers/UserInProgressChart";
import CopyWriteFooter from "../../../components/Footer/copywriteFooter"
// import { LoadingOutlined } from '@ant-design/icons';
const { Header, Footer, Sider, Content } = Layout;

export default function Dashboard() {
    const userData = useSelector((state) => state.user.userData);
    const dispatch = useDispatch();

    const [paymentDetails, setPaymentDetails] = useState([]);
    // eslint-disable-next-line
    const [loader, setLoader] = useState(false);
    const [courses, setCourses] = useState([]);
    const [categories, setCategories] = useState([]);
    const [completedCourses, setCompletedCourses] = useState([]);
    const [totalPoints, setTotalPoints] = useState(0);
    const [latestReward, setLatestReward] = useState(null);

    useEffect(() => {
        dispatch(getServices.getCategories())
            .then((response) => {
                setCategories(response?.categories);
            })
            .catch((error) => {
                console.error(error);
            });
        dispatch(
            getServices.getPaymentDetails(
                userData?.user_id
                    ? userData?.user_id
                    : userData?.id
                        ? userData?.id
                        : userData?.User?.id
            )
        )
            .then((response) => {
                setPaymentDetails(response?.payments);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [dispatch, userData]);

    useEffect(() => {

        const fetchCourseData = async (course_id) => {
            try {
                const response = await dispatch(
                    getServices.getCourseByCourseID(course_id)
                );
                return response?.courses;
            } catch (error) {
                console.error(error);
                return null;
            }
        };

        const getAllCourseData = async () => {
            try {
                const dataPromises = paymentDetails
                    .filter((payment) => payment?.status !== "0")
                    .map((payment) => fetchCourseData(payment.course_id));

                const allData = await Promise.all(dataPromises);
                const filteredData = allData.filter((data) => data !== null).flat();
                setCourses((prevState) => [...prevState, ...filteredData]);
            } catch (error) {
                console.error('Error fetching all course data:', error);
            }
        };

        if (paymentDetails.length > 0) {
            getAllCourseData();
        }
    }, [dispatch, paymentDetails]);

    const Category = (category_id) => {
        const category = categories?.find(
            (category) => category?.id === category_id
        );
        return category?.name;
    };

    const compareDates = (course) => {
        let currentDate = new Date().toJSON().slice(0, 10);
        let date1 = new Date(course?.expire_date).getTime();
        let date2 = new Date(currentDate).getTime();

        if (date1 < date2) {
            setCompletedCourses((prevState) => [...prevState, course]);
        }
    };

    

    useEffect(() => {
        courses.forEach((course) => {
            compareDates(course);
        });
    }, [courses]);

    useEffect(() => {
        // Calculate total points
        const points = paymentDetails.reduce((sum, payment) => {
            return payment?.reward ? sum + payment?.reward?.points : sum;
        }, 0);
        setTotalPoints(points);

        // Find the latest reward
        const latest = paymentDetails.reduce((latest, payment) => {
            if (!payment?.reward) return latest;
            return new Date(payment?.reward?.created_at) > new Date(latest?.reward?.created_at) ? payment : latest;
        }, paymentDetails[0]);
        setLatestReward(latest?.reward);
    }, [paymentDetails]);


    const myCourses = [
        {
            category: "Web Development",
            title: "Learn Web Developemt"
        },
        {
            category: "Web Development",
            title: "Learn Web Developemt"
        },
        {
            category: "Web Development",
            title: "Learn Web Developemt"
        },
        {
            category: "Web Development",
            title: "Learn Web Developemt"
        },
        {
            category: "Web Development",
            title: "Learn Web Developemt"
        },
        {
            category: "Web Development",
            title: "Learn Web Developemt"
        },
        {
            category: "Web Development",
            title: "Learn Web Developemt"
        },
        {
            category: "Web Development",
            title: "Learn Web Developemt"
        },
        {
            category: "Web Development",
            title: "Learn Web Developemt"
        },
        {
            category: "Web Development",
            title: "Learn Web Developemt"
        },
        {
            category: "Web Development",
            title: "Learn Web Developemt"
        },
        {
            category: "Web Development",
            title: "Learn Web Developemt"
        },
        {
            category: "Web Development",
            title: "Learn Web Developemt"
        },
    ]


    return (
        <>
            <TopNavBar />
            <Row>
                <Col xl={4} className="d-xl-block d-md-none ">
                    <Sidebar />
                </Col>
                <Col xl={20} md={24}>
                    <div className="main-section mb-md-4 mb-3">
                        <Row className="first-section-div">
                            <Col className="px-2" md={12}>
                                <div className="first-section-col-one">
                                    <Row className="first-section-first-row">
                                        <Col md={24} className="first-div-dashboard">
                                            <p className="hey">
                                                Hey <MdWavingHand />
                                            </p>
                                            <p className="good-morning">
                                                Welcome{" "}
                                                {userData?.name
                                                    ? userData?.name
                                                    : userData?.name
                                                        ? userData?.name
                                                        : userData?.User?.name}
                                                !
                                            </p>
                                        </Col>
                                        <Col md={24} className="second-div-dashboard">
                                            <p className="in-progress-text">
                                                In-Progress{" "}
                                                {/* <span className="course-text">(Web Development)</span> */}
                                            </p>
                                            <p className="good-job">Good job, Keep learning!</p>
                                            <div className="highchart-container">
                                                {/* {loader ? ( */}
                                                <>
                                                    <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={InProgressChart(courses.length, completedCourses.length)}
                                                    />
                                                    <p className="completed-text">
                                                        Completed {completedCourses.length} of {courses.length}{" "}
                                                        lessons
                                                    </p>
                                                </>
                                                {/* ) : (
                        <div className="spinner-container">
                        <Spin indicator={<LoadingOutlined spin />} size="large" />
                        </div>
                    )} */}

                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col md={12} sm={24} className="px-2 small-full-width">
                                <div className="third-div-dashboard">
                                    <p className="my-course">My Items</p>
                                    {/* {courses.length > 0 ? (
                                    courses &&
                                    courses.map((course) => {
                                        return (
                                            <Row className="my-courses-row">
                                                <Col className="my-courses-col-one" md={6}>
                                                    <Image preview={false} src={MyCOurseImg} />
                                                </Col>
                                                <Col className="my-courses-col-two" md={18}>
                                                    <p className="course-name">
                                                        {Category(course?.category_id)}
                                                    </p>
                                                    <p className="coures-sub-head">{course?.title}</p>
                                                </Col>
                                            </Row>
                                        );
                                    })
                                ) : (
                                    <div className="spinner-container">
                                        <p>No Data</p>
                                    </div>
                                )} */}

                                    {myCourses.length > 0 ? (
                                        myCourses &&
                                        myCourses.map((course) => {
                                            return (
                                                <Row className="my-courses-row">
                                                    <Col className="my-courses-col-one" md={6}>
                                                        <Image preview={false} src={MyCOurseImg} />
                                                    </Col>
                                                    <Col className="my-courses-col-two" md={18}>
                                                        <p className="course-name">
                                                            {(course?.category)}
                                                        </p>
                                                        <p className="coures-sub-head">{course?.title}</p>
                                                        {/* <Slider defaultValue={30} /> */}
                                                    </Col>
                                                </Row>
                                            );
                                        })
                                    ) : (
                                        <div className="spinner-container">
                                            <p>No Data</p>
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row className="reward-point-section">
                            <Col className="reward-col" md={8}>
                                <p className="two-hund">{latestReward ? latestReward.points : 0}</p>
                                <p className="rew-point">Reward Points Earned</p>
                                <button type="button" class="btn btn-success">
                                    Redeem Now
                                </button>
                            </Col>
                            <Col md={16} className="rev-col-mobile-full">
                                <Row className="row-bg-gif-image">
                                    <Col className="total-reward-points-col" md={12}>
                                        <p className="total">You have total</p>
                                        <p className="reward-points">{totalPoints ? totalPoints : 0} Reward Points</p>
                                    </Col>
                                    <Col className="trophy-img-container" md={12}>
                                        <Image
                                            width={256}
                                            height={200}
                                            src={RewardTrophy}
                                            alt="Not Found"
                                            className="img-fluid"
                                            preview={false}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <CopyWriteFooter />
                </Col>
            </Row>
            {/* <div className="mainContent"> */}

            {/* </div> */}
        </>
    );
}
