import React, { useState,useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer/footer';
import { Collapse } from 'antd';
import { FaArrowRightLong } from 'react-icons/fa6';
import * as getServices from "../../services/getServices"
import { useDispatch } from "react-redux";
// import './Faq.css'; // Import custom CSS for styling

export default function Faq() {
  const { Panel } = Collapse;
  const dispatch = useDispatch();
  const [faqs,setFaqs] = useState()

  useEffect(() => {
    dispatch(getServices?.getFAQ())
      .then((response) => {
        setFaqs(response?.faqs)
      })
      .catch((err) => {
        console.error({ err });
      });
     
  }, []);


  // const items = [
  //   {
  //     key: '1',
  //     label: 'Lorem ipsum dolor sit amet?',
  //     children:
  //       'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.',
  //   },
  //   {
  //     key: '2',
  //     label: 'At vero eos et accusamus?',
  //     children:
  //       'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.',
  //   },
  //   {
  //     key: '3',
  //     label: 'Lorem ipsum dolor site?',
  //     children:
  //       'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.',
  //   },
  //   {
  //     key: '4',
  //     label: 'Lorem ipsum dolor site?',
  //     children:
  //       'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.',
  //   },
  // ];

  return (
    <>
      <Header />
      <div className="faq-container">
        <div className="faq-banner-container">
          <p className="faq-banner-heading">FAQ’s</p>
        </div>
        <div className="questions-container container">
          <p className="question-header border-bottom-heading bottom-border-center">Answers to our frequently asked questions</p>
          <div className="question-answer-collapse-container">
            <Collapse defaultActiveKey={['1']} className="custom-collapse">
              {faqs?.map(item => (
                <Panel
                  header={
                    <div className="panel-header">
                      <span className="panel-label">{item.question}</span>
                      <FaArrowRightLong className="arrow-icon" />
                    </div>
                  }
                  key={item.id}
                >
                  <p className='panel-description'>{item.answer}</p>
                </Panel>
              ))}
            </Collapse>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
