import React from "react";
import { FaRegCalendar } from "react-icons/fa";
import { BiSolidComment } from "react-icons/bi";
import { BlogBannder, UserProfileDefault } from "../../constant/images";
import { FaFacebookF } from "react-icons/fa";
import { FaPinterestP } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { RiInstagramFill } from "react-icons/ri";
import { FaYoutube } from "react-icons/fa";
import { Form, Input, Rate } from "antd";
import Header from "../../components/Header";
import Footer from "../../components/Footer/footer";
import { useLocation } from "react-router-dom";

export default function BlogDetailPage() {

    const currentUrl = window.location.href;
    const { TextArea } = Input;
    let { state } = useLocation();

    const onFinishFailed = (errorInfo) => {
        // console.log("Form failed:", errorInfo);
    };

    const onFinish = (values) => {

    };

    const DateFormatter = (date) => {
        const inputDate = date;

        const dateObj = new Date(inputDate);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };

    const BlogContent = ({ content }) => {
        let formattedContent = content?.replace(/\n/g, '<br />');

        return (
          <div
            dangerouslySetInnerHTML={{
              __html: formattedContent,
            }}
          />
        );
      };
      const openShareWindow = (url) => {
        window.open(
            url,
            'popup',
            'width=800,height=800,scrollbars=no,resizable=no'
        );
    };
    return (
        <>
            <Header />
            <div className="blog-details-container container">
                <p className="blog-heading">{state?.blogData?.title}</p>
                <div className="blog-created-date-comment">
                    <p className="blog-created-date">
                        <span>
                            <FaRegCalendar className="calendar-icon" />
                        </span>
                       {DateFormatter(state?.blogData?.created_at)}
                    </p>
                    <p className="blog-total-comments">
                        <span>
                            <BiSolidComment className="comment-icon" />
                        </span>
                        0 Comment
                    </p>
                </div>
                <div className="blog-image-container">
                    <img className="blog-image" src={state?.blogData?.images[0]} />
                </div>
                <div className="blog-content-container">

                    <BlogContent content={state?.blogData?.content} />
                </div>
                <div className="socail-share-container">
                    <p className="share-text">Share:</p>
                    <div className="social-icon-container">
                    <p onClick={() => openShareWindow(`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`)}>
                            <FaFacebookF className="facebook" />
                        </p>
                        <p onClick={() => openShareWindow(`https://pinterest.com/pin/create/button/?url=${currentUrl}`)}>
                            <FaPinterestP className="pinterest" />
                        </p>
                        <p onClick={() => openShareWindow(`https://twitter.com/intent/tweet?url=${currentUrl}`)}>
                            <FaXTwitter className="twitter" />
                        </p>
                        <p onClick={() => openShareWindow(currentUrl)}>
                            <RiInstagramFill className="instagram" />
                        </p>
                        <p onClick={() => openShareWindow(currentUrl)}>
                            <FaYoutube className="youtube" />
                        </p>
                    </div>
                </div>
                <div className="row form-row">
                    <div className="col-xl-6 col-md-10 form-col">
                        <div className="blog-form-container">
                            <p className="leave-comment-heading border-bottom-heading">Leave a comment</p>
                            <p className="blog-form-sub-heading">
                                Your email address will not be published. Required fields are
                                marked *
                            </p>
                            <Form
                                name="blog-comment"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                className="blog-comment-form"
                                autoComplete="off"
                            >
                                <div className="row form-row">
                                    <div className="col-md-4 form-col">
                                        <Form.Item
                                            name="fullName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter full name.",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Name*" className="name-input" />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-8 form-col">
                                        <Form.Item
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter a valid email address.",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Email*" className="email-input" />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-12 form-col">
                                        <Form.Item name="comment">
                                            <TextArea
                                                rows={4}
                                                placeholder="Add Comment here..."
                                                className="comment-input"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6 form-col">
                                        <Form.Item name="upload-button">
                                            <button className="post-comment-button">
                                                Post Comment
                                            </button>
                                        </Form.Item>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>

                <div className="blog-comments-container">
                    <div className="header d-flex justify-content-between align-items-center">
                        <p className="comment-heading border-bottom-heading">Comments</p>
                        <p className="total-comments">02 Comments</p>
                    </div>
                    <div className="comment-list-container">
                        <div className="row comment-row">
                            <div className="col-xl-1 col-md-2 comment-col">
                                <img className="user-profile" src={UserProfileDefault} />
                            </div>
                            <div className="col-xl-11 col-md-10 comment-col">
                                <div className="d-flex justify-content-between align-items-center name-date-container">
                                    <p className="name">Laura Hipster</p>
                                    <p className="date">March 13, 2024</p>
                                </div>
                                <Rate allowHalf defaultValue={4} className="rating" />
                                <p className="comment-text">
                                    Quisque nec non amet quis. Varius tellus justo odio parturient
                                    mauris curabitur lorem in. Pulvinar sit ultrices mi ut eleifend
                                    luctus ut. Id sed faucibus bibendum augue id cras purus. At eget
                                    euismod cursus non. Molestie dignissim sed volutpat feugiat vel.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="comment-list-container">
                        <div className="row comment-row">
                            <div className="col-xl-1 col-md-2 comment-col">
                                <img className="user-profile" src={UserProfileDefault} />
                            </div>
                            <div className="col-xl-11 col-md-10 comment-col">
                                <div className="d-flex justify-content-between align-items-center name-date-container">
                                    <p className="name">Laura Hipster</p>
                                    <p className="date">March 13, 2024</p>
                                </div>
                                <Rate allowHalf defaultValue={4} className="rating" />
                                <p className="comment-text">
                                    Quisque nec non amet quis. Varius tellus justo odio parturient
                                    mauris curabitur lorem in. Pulvinar sit ultrices mi ut eleifend
                                    luctus ut. Id sed faucibus bibendum augue id cras purus. At eget
                                    euismod cursus non. Molestie dignissim sed volutpat feugiat vel.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
