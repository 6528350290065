import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Button,
    Rate,
    Tabs,
    Image,
    Form,
    Input,
    Checkbox,
    Card,
    Modal,
    Collapse,
    Table,
    Divider
} from "antd";
import Header from "../../components/Header";
import Footer from "../../components/Footer/footer";
import { BsFillBarChartFill } from "react-icons/bs";
import { FaCopy } from "react-icons/fa";
import { RiGraduationCapFill } from "react-icons/ri";
import { IoIosArrowRoundBack } from "react-icons/io";
import {
    featureImg2,
    CourseBannerImage,
    UserProfileDefault
} from "../../constant/images";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Payment from "../paymentPrcess/index";
import * as getServices from "../../services/getServices";
import { useDispatch } from "react-redux";
import RecentCourse from "../HomePage/RecentCourse";
import * as uploadService from "../../services/uploadServices";
import Swal from "sweetalert2";
import { DEMO_URL } from "../../Helper"

export default function CourseDetails() {
    const { Panel } = Collapse;
    window.scrollTo(0, 0);
    const navigate = useNavigate();
    const userData = useSelector((state) => state.user.userData);
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState();
    const [paymentCourseData, setPaymentCourseData] = useState();
    const [particularCourseData, setParticularCourseData] = useState();
    const dispatch = useDispatch();

    let { state } = useLocation();
    const { TextArea } = Input;
    const [categories, setCategories] = useState();
    const [courses, setCourses] = useState();
    const [rating, setRating] = useState(0);
    const [reviews, setReviews] = useState([])

    const onFinish = (values) => {
        let formData;
        formData = {
            user_id: userData?.user_id
                ? userData?.user_id
                : userData?.id
                    ? userData?.id
                    : userData?.User?.id,
            name: values?.fullName,
            email: values?.email,
            comment: values?.comment,
            rating: rating.toString(),
            status: "pending",
            course_id: state?.courseData?.id,
            vendor_id: state?.courseData?.vendor_id,
        };

        dispatch(uploadService?.postReview(formData))
            .then((response) => {
                Swal.fire(
                    "Review Uploaded!",
                    "Your review has been uploaded successfully.",
                    "success"
                ).then((result) => { });
            })
            .catch((err) => {
                Swal.fire("Failed", "Falied to upload review", "error").then(
                    (result) => { }
                );
                console.error({ err });
            });
    };


    const onFinishFailed = () => { };

    useEffect(() => {
        dispatch(getServices?.getCategories())
            .then((response) => {
                setCategories(response?.categories);
            })
            .catch((err) => {
                console.error({ err });
            });

        dispatch(getServices?.getAllCourses())
            .then((response) => {
                setCourses(response?.courses);
            })
            .catch((err) => {
                console.error({ err });
            });
        dispatch(
            getServices?.getPaymentDetails(
                userData?.user_id
                    ? userData?.user_id
                    : userData?.User?.id
                        ? userData?.User?.id
                        : userData?.user?.id
            )
        )
            .then((response) => {
                setPaymentCourseData(response?.payments);
            })
            .catch((err) => {
                console.error({ err });
            });

        dispatch(getServices?.getParticularCourseReviews(state?.courseData?.id))
            .then((response) => {
                setReviews(response)
            })
            .catch((err) => {
                console.error({ err });
            });
    }, []);

    let doesUserPurchasedCourse =
        paymentCourseData?.some(
            (payment) =>
                parseInt(payment?.course_id) === parseInt(state?.courseData?.id)
        ) || false;

    const Category = (category_id) => {
        const category = categories?.find(
            (category) => category?.id === category_id
        );
        return category?.name;
    };



    const multipleTabsArray = [
        {
            key: "1",
            label: "About",
            children: (
                <div className="tabs-description-container">
                    <p className="description">{state?.courseData?.about_course}</p>
                </div>
            ),
        },
        {
            key: "2",
            label: "Instructor",
            children: (
                <div className="tabs-description-container instructor-container">
                    <div>
                        <Image style={{ width: "200px" }} preview={false} src={`${state?.courseData?.profile_photo}`} />
                    </div>
                    <div>
                        <p className="description">
                            Name - {state?.courseData?.instructor_name && state?.courseData?.instructor_name !== "undefined" ? state?.courseData?.instructor_name : "Mukul Rajak"}
                        </p>
                        <p className="description">About - {state?.courseData?.about && state?.courseData?.about !== "undefined" ? state?.courseData?.about : "I have 12 years of experience."}</p>
                        <p className="description">
                            Experience - {state?.courseData?.work_experience && state?.courseData?.work_experience !== "NaN" ? state?.courseData?.work_experience : 12} years
                        </p>
                    </div>
                </div>
            ),
        },
        {
            key: "3",
            label: "Refund Policy",
            children: (
                <div className="tabs-description-container">
                    <p className="description">
                        {state?.courseData?.refund_policy}
                    </p>
                </div>
            ),
        },
        {
            key: "4",
            label: "FAQ",
            children: (
                <div className="tabs-description-container course-faq-container">
                    <Collapse defaultActiveKey={["0"]} className="custom-collapse">
                        {(() => {
                            let faqData = state?.courseData?.vendor_faq;
                            if (typeof faqData === "string") {
                                faqData = JSON.parse(faqData);
                            }

                            // Now map through faqData
                            return faqData?.map((item, index) => (
                                <Panel
                                    header={
                                        <div className="panel-header">
                                            <span className="panel-label">{item.question}</span>
                                        </div>
                                    }
                                    key={index} // using index as a key if item.key is not present
                                >
                                    <p className="panel-description">{item.answer}</p>
                                </Panel>
                            ));
                        })()}
                    </Collapse>

                </div>
            ),
        },
        {
            key: "5",
            label: "Vendor",
            children: (
                <div className="vendor-details-course-container">
                    <div className="vendor-details-course-image">
                        <img className="vendor-logo" src={UserProfileDefault} />
                    </div>
                    <div className="vendor-details-container">
                        <p>Name: &nbsp;<span style={{ color: "#106AB3" }}>{state?.courseData?.vendor?.user?.name ? state?.courseData?.vendor?.user?.name : state?.vendorData?.user?.name}</span></p>
                        <p>Email: &nbsp;<span style={{ color: "#106AB3" }}>{state?.courseData?.vendor?.user?.email ? state?.courseData?.vendor?.user?.email : state?.vendorData?.user?.email}</span></p>
                        <p>Phone: &nbsp;<span style={{ color: "#106AB3" }}>{state?.courseData?.vendor?.user?.phone ? state?.courseData?.vendor?.user?.phone : state?.vendorData?.user?.phone}</span></p>
                        {/* <p>School: &nbsp;<span style={{color:"#106AB3"}}>{state?.courseData?.vendor?.school}</span></p>  */}
                        <p>Address: &nbsp;<span style={{ color: "#106AB3" }}>{state?.courseData?.vendor?.address ? state?.courseData?.vendor?.address : "Test Address"}</span></p>
                    </div>
                </div>
            ),
        },
    ];

    const filteredTabsArray = multipleTabsArray.filter(tab => {
        if (tab.key === "3" && !state?.courseData?.refund_policy) {
            return false;
        }
        if (tab.key === "4" && (state?.courseData?.vendor_faq === null || state?.courseData?.vendor_faq?.length === 0)) {
            return false;
        }
        return true;
    });

    const fileUrl = state?.courseData?.document;

    const handleDownload = () => {
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = "z6nEYKX3Ap8WGULMvsUsZvcmFK4Sx0PWleK7PNRS.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handlePurchase = (coursedata) => {
        if (isUserLoggedIn || isVendorLoggedIn) {
            // navigate(`/payment/${userData?.user_id}/${coursedata?.id}`, {
            //   state: { courseData: coursedata },
            // });
            setParticularCourseData(coursedata)
            showModal();
        } else {
            localStorage.setItem("coursePage", true);
            navigate("/login");
        }
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleCourseChange = (data) => {
        setSelectedCourse(data);
    };

    const DateFormatter = (date) => {
        const inputDate = date;

        const dateObj = new Date(inputDate);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };

    let sumOfRatings = 0;
    reviews.forEach(item => {
        sumOfRatings += parseInt(item.rating, 10);
    });

    const averageRating = reviews.length > 0 ? sumOfRatings / reviews.length : 0;


    const ConvertTiming = (time) => {
        const Newtime = new Date(time);

        const options = { hour: '2-digit', minute: '2-digit', hour12: true };

        const timeReadable = Newtime.toLocaleTimeString('en-US', options);

        return timeReadable;
    };

    const columns = [
        {
            title: 'Day',
            dataIndex: 'day',
            render: (text, record) => (
                <>
                    <div className='day-container'>
                        <p className='day-text'>{record?.week_day}</p>
                    </div>
                </>
            ),
        },
        {
            title: 'Start Time',
            dataIndex: 'start_time',
            render: (text, record) => (
                <>
                    <div className='start-time-container'>
                        <p className='starttime--text'>{ConvertTiming(record?.start_time)}</p>
                    </div>
                </>
            ),
        },
        {
            title: 'End Time',
            dataIndex: 'end_time',
            render: (text, record) => (
                <>
                    <div className='start-time-container'>
                        <p className='starttime--text'>{ConvertTiming(record?.end_time)}</p>
                    </div>
                </>
            ),
        },
    ];

    const handleFreeCourse = (courseData) => {
        try {
            const formData = new FormData();
            formData.append(
                "user_id",
                parseInt(
                    userData?.user_id
                        ? userData?.user_id
                        : userData?.id
                            ? userData?.id
                            : userData?.User?.id,
                )
            );
            formData.append(
                "course_id", courseData?.id
            );
            formData.append("email", userData?.email
                ? userData?.email
                : userData?.user?.email
                    ? userData?.user?.email
                    : userData?.User?.email ? userData?.User?.email : userData?.user_email);
            formData.append("amount", 0);
            formData.append("course_fee", 0);
            dispatch(uploadService.payment(formData)).then((response) => {
                Swal.fire(
                    "Item Enrolled Successfully",
                    "You have been successfully enrolled in the Item.",
                    "success"
                )
                
            }).catch((error) => {
                Swal.fire("Failed", error.message);
            })
        } catch (e) {
            console.error('Error in free item purchase', e)
            return
        }
    }

    const handleBack = () => {
        navigate("/");
    }
    return (
        <>
            <Header />
            <div className="course-details-container">
                <div className="course-details-banner-container" style={{ backgroundImage: state?.courseData?.image ? `url(${state?.courseData?.image})` : `url(${CourseBannerImage})` }}>
                    <div className="container-fluid"><Button className="back-to-button" onClick={handleBack}>  <IoIosArrowRoundBack /> Back to home</Button></div>
                    <div className="container">
                        <Row className="course-details-banner-row">
                            <Col className="course-details-banner-col-one" md={12}>

                                <Button className="course-banner-button">Wordpress</Button>
                                <p className="course-details-heading">
                                    {selectedCourse !== undefined
                                        ? selectedCourse.title
                                        : state?.courseData?.title}
                                </p>
                                <div className="categories-container">
                                    <p className="parent-category">{Category(state?.courseData?.category_id) ? Category(state?.courseData?.category_id) : "Regular Course"}</p>
                                    <p className="sub-category">Academic Enrichment</p>
                                    <p className="child-category">Engineering </p>
                                </div>
                                <Row className="students-level-lesson-row">
                                    <Col className="students-with-icon" md={10}>
                                        <RiGraduationCapFill className="graguation-cap-icon" />
                                        <p className="no-of-students">10 Client Batch</p>
                                    </Col>
                                    <Col className="levels-with-icon" md={10}>
                                        <BsFillBarChartFill className="bar-chart-icon" />
                                        <p className="all-levels">All levels</p>
                                    </Col>
                                    {/* <Col className="lessons-with-icon" md={8}>
                  <FaCopy className="copy-icon" />
                  <p className="no-of-lessons">20 Lessons</p>
                </Col> */}
                                </Row>
                                <Row className="banner-rating-container">
                                    <Col className="banner-rating-col-one" md={2}>
                                        <p className="rating-text">{averageRating % 1 !== 0 ? averageRating.toFixed(1) : averageRating + ".0"}</p>
                                    </Col>
                                    <Col className="banner-rating-col-two" md={22}>
                                        <Rate disabled={true} className="rating-star" key={Math.random()} defaultValue={!isNaN(averageRating) && averageRating !== 0 ? averageRating : 0} />
                                        {state?.courseData?.trial !== 0 ? (<p className="trail-available-text">Trail available</p>) : ("")}

                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={24}>
                                        <p style={{ color: "#fff", fontFamily: "Poppins" }}>Created by <span style={{ fontFamily: "Poppins", fontWeight: "600" }}>{state?.courseData?.vendor?.user?.name}</span></p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="course-details-banner-col-two" md={12}>
                                <Row className="course-img-video-with-enrol-row">
                                    <Col className="course-img-video-with-enrol-col-one" md={5}>
                                        <Row className="image-video-row">
                                            <Col className="image-video-col" md={24}></Col>
                                        </Row>
                                    </Col>
                                    <Col className="course-img-video-with-enrol-col-two" md={19}>
                                        <div className="enroll-container">
                                            <div className="enroll-banner-image-div">
                                                <div className="image-div"></div>
                                            </div>
                                            <div className="price-with-enroll-outer-div">
                                                <Row className="price-with-enroll-row">

                                                    {paymentCourseData?.some(
                                                        (item) => item?.course_id == state?.courseData?.id
                                                    ) ? (
                                                        <Col className="price-with-enroll-col-one justify-content-center" md={24}>
                                                            <p className="latest-price">
                                                                ${state?.courseData?.fee}
                                                            </p>
                                                        </Col>
                                                    ) : (
                                                        <>
                                                            <Col className="price-with-enroll-col-one" md={12}>
                                                                <p className="latest-price">
                                                                    ${state?.courseData?.fee}
                                                                </p>
                                                            </Col>
                                                            <Col className="price-with-enroll-col-two" md={12}>
                                                                <Button
                                                                    onClick={(e) => handlePurchase(state?.courseData)}
                                                                    className="enroll-button"
                                                                >
                                                                    Purchase Now
                                                                </Button>
                                                            </Col>
                                                        </>
                                                    )}

                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="multiple-tabs-container container">
                    <Tabs
                        className="multiple-tabs"
                        defaultActiveKey="1"
                        items={filteredTabsArray}
                    />
                </div>
                {state?.courseData?.slots && state?.courseData?.slots[0]?.time_slots && (state?.courseData?.slots[0]?.time_slots)?.length > 0 ? (
                    <Row className="slot-table-row">
                        <Col className="slot-table-col" md={24}>
                            <p style={{ textAlign: "center", fontSize: "20px", fontFamily: "Poppins", fontWeight: "500", marginBottom: "1rem" }}>Class Timings</p>
                            <Table className="course-slot-table" pagination={false} columns={columns} dataSource={state?.courseData?.slots[0]?.time_slots} />
                        </Col>
                    </Row>
                ) : ("")}

                <div className="downalod-document-container">
                    <Button className="download-button" onClick={handleDownload}>
                        Download Documents
                    </Button>
                </div>
                {reviews && reviews?.filter(review => review?.status === "approved")?.length > 0 ? (
                    <div className="comments-container container">
                        <div className="comments-head-container">
                            <p className="comments-heading border-bottom-heading">Comments</p>
                            <p className="no-of-comments">{reviews.length} Comments</p>
                        </div>
                        <Row className="comments-row">
                            {reviews?.filter(review => review?.status === "approved")?.map((comment) => {
                                return (
                                    <Col className="comments-col" md={24}>
                                        <Row className="comments-col-row">
                                            <Col className="avatar-col" md={1}>
                                                <Image preview={false} className="avatar-image" src={comment?.user?.images ? comment?.user?.images : UserProfileDefault} />
                                            </Col>
                                            <Col className="comment-content-col" md={23}>
                                                <div className="comment-name-date">
                                                    <p className="comment-name">{comment?.user?.name}</p>
                                                    <p className="comment-date">{DateFormatter(comment?.created_at)}</p>
                                                </div>
                                                <Rate
                                                    disabled={true}
                                                    className="comment-rating"
                                                    defaultValue={parseInt(comment?.rating)}
                                                />
                                                <p className="comment-description">{comment?.comment}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                ) : ("")}

                <div className="comments-container container"></div>
                {/* {doesUserPurchasedCourse ? ( */}
                <div className="leave-comment-form-container container">
                    <Row className="leave-comment-form-row">
                        <Col className="leave-comment-form-col-one" md={15}>
                            <p className="leave-comment-heading border-bottom-heading">
                                Leave a comment
                            </p>
                            <p className="leave-comment-not-publish">
                                Your email address will not be published. Required fields are
                                marked *
                            </p>
                            <Form
                                name="basic"
                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 24,
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                className="leave-comment-form"
                            >
                                <Row className="leave-comment-form-row">
                                    <Col md={10} className="leave-comment-form-col pe-2">
                                        <Form.Item
                                            className="form-input-class"
                                            name="fullName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter full name.",
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="leave-comment-input"
                                                placeholder="Name*"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col md={14} className="leave-comment-form-col ps-2">
                                        <Form.Item
                                            className="form-input-class"
                                            name="email"
                                            rules={[
                                                {
                                                    type: "email",
                                                    required: true,
                                                    message: "Email is not valid",
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="leave-comment-input"
                                                placeholder="Email*"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row className="leave-comment-form-row">
                                    <Col md={24} className="leave-comment-form-col">
                                        <Form.Item
                                            className="form-input-class"
                                            name="comment"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter full name.",
                                                },
                                            ]}
                                        >
                                            <TextArea
                                                className="leave-comment-text-area"
                                                rows={4}
                                                placeholder="Add Comment here..."
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="leave-comment-form-row">
                                    <Col md={24} className="leave-comment-form-col">
                                        <Form.Item
                                            className="form-input-class mb-0"
                                            wrapperCol={{
                                                offset: 8,
                                                span: 24,
                                            }}
                                        >
                                            <Button
                                                className="leave-comment-button"
                                                htmlType="submit"
                                            >
                                                Post Comment
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col className="leave-comment-form-col-two" md={9}>
                            <p className="review-rating-heading border-bottom-heading">Review & Ratings</p>
                            <div className="ratings-rate">
                                <div className="rate-with-text-div">
                                    <Rate
                                        className="review-rating-star"
                                        onChange={(e) => setRating(e)}
                                        defaultValue={rating}
                                    />
                                    <p className="review-rating-text">{rating}</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="comments-container container"></div>
                {doesUserPurchasedCourse ? (
                    <div className="leave-comment-form-container container">
                        <Row className="leave-comment-form-row">
                            <Col className="leave-comment-form-col-one" md={15}>
                                <p className="leave-comment-heading border-bottom-heading">
                                    Leave a comment
                                </p>
                                <p className="leave-comment-not-publish">
                                    Your email address will not be published. Required fields are
                                    marked *
                                </p>
                                <Form
                                    name="basic"
                                    labelCol={{
                                        span: 8,
                                    }}
                                    wrapperCol={{
                                        span: 24,
                                    }}
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    className="leave-comment-form"
                                >
                                    <Row className="leave-comment-form-row">
                                        <Col md={10} className="leave-comment-form-col pe-2">
                                            <Form.Item
                                                className="form-input-class"
                                                name="fullName"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter full name.",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className="leave-comment-input"
                                                    placeholder="Name*"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col md={14} className="leave-comment-form-col ps-2">
                                            <Form.Item
                                                className="form-input-class"
                                                name="email"
                                                rules={[
                                                    {
                                                        type: "email",
                                                        required: true,
                                                        message: "Email is not valid",
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className="leave-comment-input"
                                                    placeholder="Email*"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row className="leave-comment-form-row">
                                        <Col md={24} className="leave-comment-form-col">
                                            <Form.Item
                                                className="form-input-class"
                                                name="comment"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter full name.",
                                                    },
                                                ]}
                                            >
                                                <TextArea
                                                    className="leave-comment-text-area"
                                                    rows={4}
                                                    placeholder="Add Comment here..."
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row className="leave-comment-form-row">
                                        <Col md={24} className="leave-comment-form-col">
                                            <Form.Item
                                                className="form-input-class mb-0"
                                                wrapperCol={{
                                                    offset: 8,
                                                    span: 24,
                                                }}
                                            >
                                                <Button
                                                    className="leave-comment-button"
                                                    htmlType="submit"
                                                >
                                                    Post Comment
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <Col className="leave-comment-form-col-two" md={9}>
                                <p className="review-rating-heading">Review & Ratings</p>
                                <div className="ratings-rate">
                                    <div className="rate-with-text-div">
                                        <Rate
                                            className="review-rating-star"
                                            onChange={(e) => setRating(e)}
                                            defaultValue={rating}
                                        />
                                        <p className="review-rating-text">{rating}</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                ) : (
                    ""
                )}

                <RecentCourse handleCourseChange={handleCourseChange} />
            </div>
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                className="paymentModal"
                centered
                width={1140}
                footer={false}
            >
                <Payment paymentCourseData={particularCourseData} />
            </Modal>
            <Footer />
        </>
    );
}
