import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Input,
    Card,
    Pagination,
    Collapse,
    Image,
    Tag,
    Flex,
    Checkbox,
    Slider,
    Button,
    Drawer,
    Modal,
    InputNumber,
 DatePicker
} from "antd";
import Header from "../../components/Header";
import Footer from "../../components/Footer/footer";
import { featureImg3, arrowUpRight, featureImg2 } from "../../constant/images";
import * as getServices from "../../services/getServices";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { FaLock } from "react-icons/fa";
import { FaUnlock } from "react-icons/fa";
import { LuFilter } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import * as uploadServices from "../../services/uploadServices";
import * as updateServices from "../../services/updateServices";
import Swal from "sweetalert2";
import Payment from "../paymentPrcess/index"

export default function CourseListing() {
    const userData = useSelector((state) => state.user.userData);
    const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
    const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { Panel } = Collapse;
    const [current, setCurrent] = useState(1);
    const [courses, setCourses] = useState([]);
    const [categories, setCategories] = useState();
    //eslint-disable-next-line
    const [subCategories, setSubCategories] = useState();
    const [filterCategory, setFilterCategory] = useState([]);
    const [filterValues, setFilterValues] = useState([]);
    const [filteredCourses, setFilteredCourses] = useState([]);
    const [paymentDetails, setPaymentDetails] = useState();
    const [open, setOpen] = useState(false);
    const [distance, setDistance] = useState('');
    const [distanceCourses, setDistanceCourses] = useState([]);
    const [wishlistData, setWishlistData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [paymentCourseData, setPaymentCourseData] = useState();
    const [category, setCategory]= useState([])
    const [sorting, setSorting]= useState('')
    const [initialLoad, setInitialLoad] = useState(true);
    const [minAge, setMinAge] = useState('');
    const [maxAge, setMaxAge] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const { Search } = Input;
    const pageSize = 6;

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    // useEffect(() => {
    //     dispatch(
    //         uploadServices.getCoursesFromDistance({ latitude: location?.latitude, longitude: location?.longitude, distance: distance })
    //     )
    //         .then((response) => {
    //             setDistanceCourses(response?.data)
    //         })
    //         .catch((err) => {
    //             console.error({ err });
    //         });
    // }, [distance])

    const [location, setLocation] = useState({ latitude: null, longitude: null });
    const [error, setError] = useState(null);

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (error) => {
                    setError(error.message);
                }
            );
        } else {
            setError("Geolocation is not supported by this browser.");
        }
    };



  

    const getWishlistData = () => {
        dispatch(getServices.GetWistlistDataUser(userData?.user_id
            ? userData?.user_id
            : userData?.User?.id
                ? userData?.User?.id
                : userData?.user?.id)).then((response) => {
                    setWishlistData(response?.course_details)
                }).catch((error) => {
                    console.error({ error });
                })
    }

    useEffect(() => {
        getLocation()
        dispatch(
            getServices?.getPaymentDetails(
                userData?.user_id
                    ? userData?.user_id
                    : userData?.User?.id
                        ? userData?.User?.id
                        : userData?.user?.id
            )
        )
            .then((response) => {
                setPaymentDetails(response?.payments);
            })
            .catch((err) => {
                console.error({ err });
            });

        getWishlistData()
    }, []);

    useEffect(() => {
        dispatch(getServices?.getAllCourses())
            .then((response) => {
                setCourses(response?.courses);
            })
            .catch((err) => {
                console.error({ err });
            });

        dispatch(getServices.getCategories())
            .then((response) => {
                setCategories(response?.categories);
            })
            .catch((err) => {
                console.error({ err });
            });
        dispatch(getServices.getSubCategories())
            .then((response) => {
                setSubCategories(response?.subcategories);
            })
            .catch((err) => {
                console.error({ err });
            });
    }, []);

    const handleWishlistList = (course) => {
        // console.log(course, "course")
        let formData = new FormData();

        formData.append("user_id", userData?.user_id
            ? userData?.user_id
            : userData?.User?.id
                ? userData?.User?.id
                : userData?.user?.id);
        formData.append("course_id", course?.id)

        try {

            dispatch(uploadServices.addRemoveCourseWishlist(formData)).then((response) => {
                getWishlistData()
                // console.log('Item Added to Wishlist', response)
                Swal.fire(
                    "Success!",
                    `${response?.message}`,
                    "success"
                )
            }).catch((error) => {
                getWishlistData()
                Swal.fire(
                    "Failed",
                    "Unable to add item to wishlist.",
                    "error"
                )
            })

        } catch (e) {
            Swal.fire(
                "Failed",
                "Unable to add course to wishlist.",
                "error"
            )
        }

    }
    useEffect(() => {
        const categoryIds = courses.map((course) => course.category_id);
        const uniqueCategoryIds = [...new Set(categoryIds)];
        setFilterCategory(uniqueCategoryIds);
    }, [courses]);

    useEffect(() => {
        const categoryIds = courses.map((course) => course.category_id);
        const uniqueCategoryIds = [...new Set(categoryIds)];
        setFilterCategory(uniqueCategoryIds);
    }, [courses]);

    const startIndex = (current - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const displayedCourses = courses.slice(startIndex, endIndex);

    const Category = (category_id) => {
        const category = categories?.find(
            (category) => category?.id === category_id
        );

        return category?.name;
    };

    // const SubCategory = (subCategory_id) => {
    //   const subCategory = subCategories?.find(
    //     (subCategory) => subCategory?.id === subCategory_id
    //   );
    //   return subCategory?.name;
    // };

    const onChange = (page) => {
        setCurrent(page);
    };
    const settingFilterValues = (value,data) => {
        setCategory((prev) =>
            data ? [...prev, value] : prev.filter((item) => item !== value?.id)
        );
        fetchData()
      
    };


    const fetchData = async () => {
        let data = {};
        if (category.length > 0) {
            // Serialize category array to JSON string if needed
            data.category = JSON.stringify(category);
        }
        if (sorting) {
            data.sorting = sorting;
        }
        if (minAge) {
            data.minAge = minAge;
        }
        if (maxAge) {
            data.maxAge = maxAge;
        }
        if (startDate) {
            data.startDate = startDate;
        }
        if (endDate) {
            data.endDate = endDate;
        }
        if (distance) {
            data.distance = distance
}
        
        if (Object.keys(data).length === 0) {
            return; 
        }

 

        try {
            // Dispatch the API call with filtering and sorting parameters
            await dispatch(updateServices.customFilter(data))
                .then((response) => {

                    setFilteredCourses(response?.courses);
            })

         
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
console.log(filteredCourses , "filtered course")
    const handleSorting = (label) => {
      
        setSorting(label)
         fetchData()
    }
    useEffect(() => {
        if (!initialLoad) {
            fetchData();
        } else {
            // Mark initial load as complete
            setInitialLoad(false);
        }
    }, [category, sorting, minAge, maxAge, startDate, endDate, distance]);
   
  // dispatch(updateServices.customFilter({ category })).then(
        //     (response) => {
             
        //         console.log(response.data, "response from data");
        //         setFilteredCourses(response.data);
        //     }
        // );
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const priceFilter = (value) => {
        const maxPrice = parseInt(value.price);
       

        if (filterValues.length > 0) {
            setFilteredCourses((prevFilteredCourses) => {
                const coursesToFilter =
                    prevFilteredCourses.length > 0 ? prevFilteredCourses : courses;
                const courseFiltering = coursesToFilter.filter(
                    (course) =>
                        parseInt(course.fee) <= maxPrice &&
                        (!filterValues.length ||
                            filterValues.some(
                                (filter) => filter.category_id === course.category_id
                            ))
                );

                return courseFiltering;
            });
        } else {
            setFilteredCourses(() => {
                const courseFiltering = courses.filter(
                    (course) => parseInt(course.fee) <= maxPrice
                );
                return courseFiltering;
            });
        }
    };

    const handleMinAgeChange = (maxage) => {
        
        setMinAge(maxage);
    };

    const handleMaxAgeChange = (minage) => {
 
        setMaxAge(minage);
    };

    const onStartChange = (date, dateString) => {
        setStartDate(dateString)
    };

    const onEndDataChange = (date, dateString) => {
        setEndDate(dateString);
    };


    const sort = [
        {
            value: "Old item ",
            label: "asc",
        },
        {
            value: "latest item",
            label: "desc",
        }
    ]

    const items = [
        {
            key: "1",
            label: "Category",
            children: (
                <>
                    {categories &&
                        categories?.map((item) => (
                            <div className="d-flex align-items-center mb-2">
                                <Checkbox
                                    value={item?.id}
                                    onChange={(e) => settingFilterValues(item?.id, e.target.checked)}
                                    
                                    style={{ paddingRight: "10px" }}
                                />
                                <p style={{ marginBottom: 0 }}>{item?.name}</p>
                            </div>
                        ))}
                </>
            ),
        },
        {
            key: "2",
            label: "Sort",
            children: (
                <>
                    {sort.map((item) => {
                        return (
                            <div className="col-sm-12 px-3 py-2">
                                <Checkbox key={item.label} onChange={() => handleSorting(item.label)} >
                                    {item.value}
                                </Checkbox>
                            </div>
                        );
                    })}
                </>
            )
        },
        {
            key: "3",
            label: "Price",
            children: (
                <>
                    <Slider
                        min={0}
                        max={5000}
                        onChange={(e) => priceFilter({ price: e })}
                        className="price-slider"
                        defaultValue={10}
                    />
                    <div className="slider-content-container">
                        <p className="slider-des-text">0k</p>
                        <p className="slider-des-text">50k</p>
                    </div>
                </>
            ),
        },
        {
            key: "4",
            label: "Distance",
            children: (
                <>
                    <Slider className="distance-slider" onChange={(e) => setDistance(e)} defaultValue={0} />
                    <div className="slider-content-container">
                        <p className="slider-des-text">5km</p>
                        <p className="slider-des-text">70km</p>
                    </div>
                </>
            ),
        },
        // {
        //     key: "5",
        //     label: "Time",
        //     children:
        //         "Create products that are easy to use, efficient,, whether they are websites, mobile apps, or other digital interfaces.",
        // },
        // {
        //     key: "6",
        //     label: "Day/Week Days",
        //     children: (
        //         <>
        //             <div className="d-flex align-items-center mb-2">
        //                 <Checkbox style={{ paddingRight: "10px" }} />
        //                 <p style={{ marginBottom: 0 }}>Week Days</p>
        //             </div>
        //             <div className="d-flex align-items-center mb-2">
        //                 <Checkbox style={{ paddingRight: "10px" }} />
        //                 <p style={{ marginBottom: 0 }}>Weekends</p>
        //             </div>
        //         </>
        //     ),
        // },
        {
            key: "7",
            label: "Level",
            children:
                "Create products that are easy to use, efficient,, whether they are websites, mobile apps, or other digital interfaces.",
        },
        {
            key: "8",
            label: "Age",
            children: (
                <>
                <label>
                    Min Age:
                     
                        <InputNumber  onChange={handleMinAgeChange} />;
                        
                </label>
                <label>
                        Max Age:
                        <InputNumber  onChange={handleMaxAgeChange} />;
                    
                </label>

                </>
        
     
            )
               
        },
        // {
        //     key: "9",
        //     label: "Schedule Type",
        //     children:
        //         "Create products that are easy to use, efficient,, whether they are websites, mobile apps, or other digital interfaces.",
        // },
        // {
        //     key: "10",
        //     label: "Frequency",
        //     children:
        //         "Create products that are easy to use, efficient,, whether they are websites, mobile apps, or other digital interfaces.",
        // },
        {
            key: "11",
            label: "Date",
            children:
                (
                    <>
                   <label>
                    Start Date:
                     
                            <DatePicker onChange={onStartChange} />
                        
                </label>
                <label>
                        End Date:
                            <DatePicker onChange={onEndDataChange} />
                    
                        </label>
                    </>
  
              )
        },
    ];

    const recentArticles = [
        {
            image: featureImg3,
            description: "Best WordPress Theme Collection for 2024",
        },
        {
            image: featureImg3,
            description: "Best WordPress Theme Collection for 2024",
        },
        {
            image: featureImg3,
            description: "Best WordPress Theme Collection for 2024",
        },
    ];

    const tagsArray = [
        {
            key: "Free couses",
            label: "Free couses",
        },
        {
            key: "Marketing",
            label: "Marketing",
        },
        {
            key: "Designing",
            label: "Designing",
        },
        {
            key: "Fashion Desgning",
            label: "Fashion Desgning",
        },
        {
            key: "wordpress",
            label: "wordpress",
        },
        {
            key: "Web Development",
            label: "Web Development",
        },
    ];

    const handleCourseData = (values) => {
        navigate(`/course/${values?.id}/${values?.title}`, {
            state: { courseData: values },
        });
    };

    const handlePurchase = (coursedata) => {
        if (isUserLoggedIn || isVendorLoggedIn) {
            navigate(`/payment/${userData?.user_id}/${coursedata?.id}`, {
                state: { courseData: coursedata },
            });
        } else {
            navigate("/login");
        }
    };

    const DateFormatter = (date) => {
        const inputDate = date;

        const dateObj = new Date(inputDate);

        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        const formatDate = `${day} ${month} ${year}`;
        return formatDate;
    };

    const handleCoursePayment = (data) => {
        setIsModalOpen(true)
        setPaymentCourseData(data)
    }

    return (
        <>
            <Header />
            <div className="course-listing-container">
                <div className="course-listing-banner-container">
                    <p className="course-listing-banner-heading">Browse All Courses</p>
                    <p className="course-listing-banner-sub-heading">
                        Explore Our Comprehensive Course Catalogue
                    </p>
                </div>
                <Row className="courses-with-filter-container customRowComponent">
                    <Col className="course-list-child-one px-4 py-4 pe-5" xl={18} md={24}>
                        <div className="course-list-child-one-head d-flex justify-content-between align-items-center mobile-none">
                            <p class="border-bottom-heading bottom-border-left ">
                                All Items
                            </p>
                            <div className="right-div d-flex flex-wrap justify-content-end">
                                <Button className="d-xl-none filters-button w-100" type="button" onClick={showDrawer}>
                                    <LuFilter />Filters
                                </Button>

                                <Drawer title="Filters" onClose={onClose} open={open}>
                                    <Row className="courses-with-filter-container-Drawer">
                                        <Col className="course-list-child-two" xl={6} md={24}>


                                            <div className="course-filter-container">
                                                <div className="course-filter-head">
                                                    <p className="course-filter-heading">All Filters</p>
                                                    <p className="course-filter-count">Applied (0)</p>
                                                </div>

                                                <Collapse defaultActiveKey={["1"]} className="custom-collapse">
                                                    {items?.map((item) => (
                                                        <Panel
                                                            header={
                                                                <div className="panel-header">
                                                                    <span className="panel-label">{item.label}</span>
                                                                </div>
                                                            }
                                                            key={item.key}
                                                        >
                                                            <p className="panel-description">{item.children}</p>
                                                        </Panel>
                                                    ))}
                                                </Collapse>
                                            </div>
                                            <div className="recent-articles-container">
                                                <p className="recent-articles-heading">Recent Articles</p>

                                                <Row className="recent-articles-row">
                                                    {recentArticles.map((article) => {
                                                        return (
                                                            <Col className="recent-articles-col" md={24}>
                                                                <Row className="article-cont-row">
                                                                    <Col className="article-cont-col-one" md={7}>
                                                                        <Image
                                                                            preview={false}
                                                                            className="article-img"
                                                                            src={article?.image}
                                                                        />
                                                                    </Col>
                                                                    <Col className="article-cont-col-two" md={17}>
                                                                        <p className="article-description">
                                                                            {article?.description}
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        );
                                                    })}
                                                </Row>
                                            </div>

                                            <div className="tags-container">
                                                <p className="tag-heading">Tags</p>
                                                <div className="tags-inner-div">
                                                    {tagsArray.map((tag) => {
                                                        return <Tag className="tag-tag">{tag?.label}</Tag>;
                                                    })}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Drawer>
                                <Search className="search-box" placeholder="Search" allowClear />
                            </div>

                        </div>
                        <Row className="">
                            {filteredCourses?.length > 0 
                                ? filteredCourses
                                    ?.filter((distanceCoures) => distanceCoures?.status !== "pending")
                                    ?.map((course, index) => {

                                        return (
                                            <>
                                                {course?.status === "approved" ? (
                                                    <Col
                                                        className={
                                                            (index + 1) % 2 === 0
                                                                ? "ps-3 mb-4"
                                                                : "pe-3 mb-4"
                                                        }
                                                        style={{ padding: "0 0.5rem" }}
                                                        md={12}
                                                    >
                                                        <Card
                                                            className="feature-card"
                                                            hoverable
                                                            cover={
                                                                <img
                                                                    style={{ height: "250px" }}
                                                                    src={course?.image
                                                                        ? course?.image
                                                                        : featureImg2}
                                                                    alt="text"
                                                                />
                                                            }
                                                        >
                                                            <div>
                                                                <div className="card-position-div">
                                                                    <div
                                                                        style={{

                                                                            zIndex: "99",
                                                                            padding: "10px 15px",
                                                                            backgroundColor: "#106ab3",
                                                                            borderRadius: "0 10px 10px 0",
                                                                        }}
                                                                        className="expire-container"
                                                                    >
                                                                        <span className="Lock-icon">
                                                                            <FaLock color="#fff" />
                                                                        </span>
                                                                    </div>
                                                                    <div
                                                                        style={{

                                                                            zIndex: "99",
                                                                            padding: "10px 10px",
                                                                            backgroundColor: "#106ab3",
                                                                            borderRadius: "10px 0 0 10px",
                                                                        }}
                                                                        className="expire-container"
                                                                    >
                                                                        <div className="start-end-date-container">
                                                                            <p
                                                                                style={{ color: "#fff", margin: 0 }}
                                                                                className="start-enddate"
                                                                            >
                                                                                {course?.course_start &&
                                                                                    course?.course_end
                                                                                    ? `${DateFormatter(
                                                                                        course?.course_start
                                                                                    )} - ${DateFormatter(
                                                                                        course?.course_end
                                                                                    )}`
                                                                                    : "12 July 2024 - 15 August 2025"}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <Flex
                                                                        gap="middle"
                                                                        className="feature-course-subhead-price"
                                                                    >
                                                                        <div>
                                                                            <p
                                                                                style={{
                                                                                    color: "#106AB3",
                                                                                    fontWeight: 700,
                                                                                }}
                                                                            >
                                                                                {Category(course?.category_id)
                                                                                    ? Category(course?.category_id)
                                                                                    : "Web Development"}
                                                                            </p>
                                                                        </div>
                                                                        <div>
                                                                            <p
                                                                                style={{
                                                                                    color: "#1EAA39",
                                                                                    fontWeight: 700,
                                                                                }}
                                                                            >
                                                                                ${course?.fee}
                                                                            </p>
                                                                        </div>
                                                                    </Flex>
                                                                    <Flex className="feature-course-subhead-price">
                                                                        <h4>{course?.title}</h4>
                                                                        <img
                                                                            style={{ width: "25px", height: "auto" }}
                                                                            src={arrowUpRight}
                                                                            alt="text"
                                                                        />
                                                                    </Flex>
                                                                </div>
                                                                <div>
                                                                    <p>{course?.about_course}</p>
                                                                </div>
                                                            </div>
                                                            <Flex className="feature-course-subhead-price">
                                                                <button
                                                                    type="button"
                                                                    class="btn feature-button add-to-wishlist"
                                                                    onClick={() => handleWishlistList(course?.course)}
                                                                >
                                                                    {wishlistData?.some((wish) => parseInt(wish?.id) === parseInt(course?.id))
                                                                        ? "Remove Wishlist"
                                                                        : "Add to Wishlist"
                                                                    }
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    class="btn btn-warning feature-button enroll-button"
                                                                    onClick={() => handleCoursePayment(course)}
                                                                >
                                                                    Purchase Now
                                                                </button>
                                                            </Flex>
                                                        </Card>
                                                    </Col>
                                                ) : (
                                                    ""
                                                )}
                                            </>
                                        );
                                    })
                               
                                :displayedCourses
                                    ?.filter((course) => course.status !== "pending")
                                    ?.map((course, index) => {
                                        return (
                                            <>
                                                {course?.status === "approved" ? (
                                                    <Col
                                                        style={{
                                                            padding: "0 1rem",
                                                            marginBottom: "3.5rem",
                                                        }}
                                                        md={12}
                                                    >
                                                        <Card
                                                            className="feature-card"
                                                            hoverable
                                                            cover={
                                                                <img
                                                                    style={{ height: "250px" }}
                                                                    src={
                                                                        course?.image
                                                                            ? course?.image
                                                                            : featureImg2
                                                                    }
                                                                    alt="text"
                                                                />
                                                            }
                                                        >
                                                            <div onClick={(e) => handleCourseData(course)}>
                                                                <div className="card-position-div">
                                                                    <div
                                                                        style={{

                                                                            zIndex: "99",
                                                                            padding: "10px 15px",
                                                                            backgroundColor: "#106ab3",
                                                                            borderRadius: "0 10px 10px 0",
                                                                        }}
                                                                        className="expire-container"
                                                                    >
                                                                        {paymentDetails?.some(
                                                                            (item) => item.course_id == course?.id
                                                                        ) ? (
                                                                            <span className="Lock-icon">
                                                                                <FaUnlock color="#fff" />
                                                                            </span>
                                                                        ) : (
                                                                            <span className="Lock-icon">
                                                                                <FaLock color="#fff" />
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <div
                                                                        style={{

                                                                            zIndex: "99",
                                                                            padding: "10px 10px",
                                                                            backgroundColor: "#106ab3",
                                                                            borderRadius: "10px 0 0 10px",
                                                                        }}
                                                                        className="expire-container"
                                                                    >
                                                                        <div className="start-end-date-container">
                                                                            <p
                                                                                style={{ color: "#fff", margin: 0 }}
                                                                                className="start-enddate"
                                                                            >
                                                                                {course?.course_start &&
                                                                                    course?.course_end
                                                                                    ? `${DateFormatter(
                                                                                        course?.course_start
                                                                                    )} - ${DateFormatter(
                                                                                        course?.course_end
                                                                                    )}`
                                                                                    : "12 July 2024 - 15 August 2025"}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <Flex
                                                                        gap="middle"
                                                                        className="feature-course-subhead-price"
                                                                    >
                                                                        <div>
                                                                            <p
                                                                                style={{
                                                                                    color: "#106AB3",
                                                                                    fontWeight: 700,
                                                                                }}
                                                                            >
                                                                                {Category(course?.category_id)
                                                                                    ? Category(course?.category_id)
                                                                                    : "Web Development"}
                                                                            </p>
                                                                        </div>
                                                                        <div>
                                                                            <p
                                                                                style={{
                                                                                    color: "#1EAA39",
                                                                                    fontWeight: 700,
                                                                                }}
                                                                            >
                                                                                ${course?.fee}
                                                                            </p>
                                                                        </div>
                                                                    </Flex>
                                                                    <Flex className="feature-course-subhead-price">
                                                                        <h4>{course?.title}</h4>
                                                                    </Flex>
                                                                </div>
                                                                <div>
                                                                    <p>{course?.about_course}</p>
                                                                </div>
                                                            </div>
                                                            <Flex className="feature-course-subhead-price view-enroll-button-container">
                                                                <button
                                                                    type="button"
                                                                    class="btn feature-button add-to-wishlist"
                                                                    onClick={() => handleWishlistList(course?.course)}
                                                                >
                                                                    {wishlistData?.some((wish) => parseInt(wish?.id) === parseInt(course?.id))
                                                                        ? "Remove Wishlist"
                                                                        : "Add to Wishlist"
                                                                    }
                                                                </button>
                                                                {paymentDetails?.some(
                                                                    (item) => item.course_id == course?.id
                                                                ) ? (
                                                                    <button
                                                                        type="button"
                                                                        class="btn feature-button view-course-button"

                                                                    >
                                                                        View Items
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        type="button"
                                                                        class="btn feature-button enroll-button"
                                                                        onClick={() => handleCoursePayment(course)}
                                                                    >
                                                                        Purchase Now
                                                                    </button>
                                                                )}
                                                            </Flex>
                                                        </Card>
                                                    </Col>
                                                ) : (
                                                    ""
                                                )}
                                            </>
                                        );
                                    })}
                        </Row>
                        {
                            displayedCourses.length > 0 || filteredCourses.length > 0 ?(
                                <Pagination
                                    className="pagination"
                                    current={current}
                                    onChange={onChange}
                                    total={displayedCourses.length}
                                    pageSize={pageSize}
                                /> 
                                
                            ):""
                        }
                      
                    </Col>
                    <Col className="course-list-child-two mobile-none" xl={6} md={24}>


                        <div className="course-filter-container">
                            <div className="course-filter-head">
                                <p className="course-filter-heading">All Filters</p>
                                <p className="course-filter-count">Applied (0)</p>
                            </div>

                            <Collapse defaultActiveKey={["1"]} className="custom-collapse">
                                {items?.map((item) => (
                                    <Panel
                                        header={
                                            <div className="panel-header">
                                                <span className="panel-label">{item.label}</span>
                                            </div>
                                        }
                                        key={item.key}
                                    >
                                        <p className="panel-description">{item.children}</p>
                                    </Panel>
                                ))}
                            </Collapse>
                        </div>
                        <div className="recent-articles-container">
                            <p className="recent-articles-heading">Recent Articles</p>

                            <Row className="recent-articles-row">
                                {recentArticles.map((article) => {
                                    return (
                                        <Col className="recent-articles-col" md={24}>
                                            <Row className="article-cont-row">
                                                <Col className="article-cont-col-one" md={7}>
                                                    <Image
                                                        preview={false}
                                                        className="article-img"
                                                        src={article?.image}
                                                    />
                                                </Col>
                                                <Col className="article-cont-col-two" md={17}>
                                                    <p className="article-description">
                                                        {article?.description}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </div>

                        <div className="tags-container">
                            <p className="tag-heading">Tags</p>
                            <div className="tags-inner-div">
                                {tagsArray.map((tag) => {
                                    return <Tag className="tag-tag">{tag?.label}</Tag>;
                                })}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                className="paymentModal"
                centered
                width={1140}
                footer={false}
            >
                <Payment paymentCourseData={paymentCourseData} />
            </Modal>
            <Footer />
        </>
    );
}
