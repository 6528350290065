import Http from "../Http";
import { DEMO_URL } from "../Helper";

export function Register(value) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.post(DEMO_URL + "/api/register", value)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }

  export function login(value) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.post(DEMO_URL + "/api/login", value)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }

  export function getUserDetails(value) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.get(DEMO_URL + `/api/user-detail/${value}`)
          .then((res) => {
            return resolve(res.data);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }

  export function userUpdate(value) {
    return (dispatch) =>
      new Promise((resolve, reject) => {
        Http.post(DEMO_URL + "/api/user-update", value)
          .then((res) => {
            return resolve(res);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  }