import React, { useState, useEffect } from "react";
import { Tabs, Upload, Modal, Image, Row, Col } from "antd";
import BasicInformation from "./basicInformation";
import SchoolInformation from "./schoolInformation";
import BankingInformations from "./bankingInformations";
import Sidebar from "../../../components/Sidebar/sidebar";
import TopNavBar from "../../../components/topNavbar";
import { useDispatch } from "react-redux";
import * as VendorServices from "../../../services/vendorServices";
import { useSelector } from "react-redux";
import * as getServices from "../../../services/getServices";
import { setUserData } from "../../../store/actions/user";
import Swal from "sweetalert2";
import { Avatar } from "../../../constant/images";
import * as updateServices from "../../../services/updateServices";
import { FiEdit } from "react-icons/fi";
import CopyWriteFooter from "../../../components/Footer/copywriteFooter";

export default function MyProfile() {
  const userData = useSelector((state) => state.user.userData);
  const vendorData = useSelector((state) => state)
 
  const isUserLoggedIn = useSelector((state) => state.user.isUserLoggedIn);
  const isVendorLoggedIn = useSelector((state) => state.user.isVendorLoggedIn);

  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState("1");
  const [vendorDetails, setVendorDetails] = useState()
  const [basicInfoData, setBasicInfoData] = useState()
  const [schoolInfoData, setSchoolInfoData] = useState()
  //eslint-disable-next-line
  const [bankInfoData, setBankInfoData] = useState()
  const [categories, setCategories] = useState();
  const [subCategories, setSubCategories] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [userID, setUserID] = useState('');
  const [uploadOnce, setUploadOnce] = useState(true);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getUser = () => {
    dispatch(getServices.getVendorDetails(userID))
      .then((response) => {
        dispatch(setUserData(response));
        setIsModalOpen(false)
      })
      .catch((error) => {
        console.error("Error updating profile image:", error);
      });
  }
  const getVendor = () => {
    dispatch(getServices.getUserDetails(userID))
      .then((response) => {
        dispatch(setUserData(response));
        setIsModalOpen(false)
        // window.location.reload();
      })
      .catch((error) => {
        console.error("Error updating profile image:", error);
      });
  }
  const onProfileFinish = (newFileList) => {
    if (!newFileList.length) {
      console.error("File list is empty");
      return;
    }

    const formData = new FormData();
    const userId =
      userData?.user_id ||
      userData?.User?.id ||
      userData?.user?.id;

    if (!userId) {
      console.error("User ID is missing");
      return;
    }

    formData.append("user_id", userId);
    formData.append("images", newFileList[0]?.originFileObj);
    setUserID(userId)
    dispatch(updateServices.updateProfileImage(formData))
      .then((response) => {
       
        setUploadOnce(false);
        Swal.fire(
          "Profile Updated Successfully!",
          "You have successfully updated your profile",
          "success"
        ).then((result) => {
          if (result.isConfirmed) {
            if (isVendorLoggedIn) {
              getUser()
            } else {
              getVendor()
            }
          }
        });
      })
      .catch((error) => {
        console.error("Error updating profile image:", error);
        Swal.fire(
          "Failed",
          error?.response?.data?.message,
          "erroe"
        )

      });
  };

  const onChangeProfileImage = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (uploadOnce) {
      onProfileFinish(newFileList);
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  useEffect(() => {
    dispatch(getServices.getCategories()).then((response) => {
      setCategories(response?.categories)
    }).catch((err) => {
      console.log({ err })
    })
    dispatch(getServices.getSubCategories()).then((response) => {
      setSubCategories(response?.subcategories)
    }).catch((err) => {
      console.log({ err })
    })
  }, [dispatch])

  const onFinish = (bacicInfoData, schoolInfoData, bankInfoData) => {
    let formData;
    formData = {
      user_id: userData?.user_id ? userData?.user_id : userData?.user?.id,
      name: bacicInfoData?.fullName,
      email: bacicInfoData?.email,
      phone: bacicInfoData?.phone_number,
      school: schoolInfoData?.school_name,
      website_url: schoolInfoData?.website_url,
      address: schoolInfoData?.address,
      logo: "",
      business_type: schoolInfoData?.business_type,
      business_identification_type: schoolInfoData?.business_identification_type,
      business_identification_no: schoolInfoData?.business_identification_no,
      gst: schoolInfoData?.gst,
      category: schoolInfoData?.category,
      sub_category: schoolInfoData?.sub_categories,
      email_notification: schoolInfoData?.email_for_notification,
      about_school: schoolInfoData?.about_school,
    }

    dispatch(VendorServices.vendorUpdate(formData)).then((response) => {


      dispatch(setUserData(response))
      Swal.fire(
        "Updated Successful!",
        "You have successfully updated Your Profile!",
        "success"
      ).then((result) => {
        window.location.reload();
      });
    }).catch((err) => {
      console.log({ err })
    })
  };

  const goBack = () => {
    if (activeStep === "2") {
      setActiveStep("1");
    } else if (activeStep === "3") {
      setActiveStep("2");
    }
  };

  const goNext = (e) => {
    if (activeStep === "1") {
      setActiveStep("2");
    } else if (activeStep === "2") {
      setActiveStep("3");
    }
  };

  useEffect(() => {
    dispatch(VendorServices.vendorDetails(userData?.user_id ? userData?.user_id : userData?.user?.id ? userData?.user?.id : userData?.User?.id)).then((response) => {
      setVendorDetails(response)
    }).catch((err) => {
      console.log({ err })
    })
  }, [dispatch]);

  const handleBasicInfoData = (data) => {
    setBasicInfoData(data)
    goNext();
  }

  const handleSchoolInfoData = (data, about_school) => {
    data.about_school = about_school;
    setSchoolInfoData(data)
    goNext();
  }

  const handleBankInfoData = (data) => {
    setBankInfoData(data)
    onFinish(basicInfoData, schoolInfoData, data)
  }

  const items = [
    {
      key: "1",
      label: "Basic Information",
      children: (
        <>
          <div className="vendor-profile-outer-conatiner">
            <div className="vendor-profile-conatiner">
              <div className="edit-profile-icon-container" onClick={showModal}>
                <FiEdit color="#fff" className="edit-profile-icon" />
              </div>
              <Image style={{ width: "300px", height: "300px" }} preview={false}
                src={
                  userData?.User?.images
                    ? userData?.User?.images
                    : userData?.image
                      ? userData?.image
                      : userData?.Vendor?.logo
                        ? userData?.Vendor?.logo
                        : Avatar}
              />
            </div>
          </div>
          <BasicInformation onData={handleBasicInfoData} vendorDetails={vendorDetails} />
        </>
      ),
    },
    {
      key: "2",
      label: "Organisation  Information",
      children: <SchoolInformation onData={handleSchoolInfoData} categories={categories} subCategories={subCategories} vendorDetails={vendorDetails} goBack={goBack} />,
    },
    {
      key: "3",
      label: "Payment Details",
      children: <BankingInformations onData={handleBankInfoData} vendorDetails={vendorDetails} goBack={goBack} />,
    },
  ];

  const onChange = (key) => {
    setActiveStep(key);
  };

  return (
    <>
      <TopNavBar />
      <Row>
        <Col xl={4} className="d-xl-block d-md-none">
          <Sidebar />
        </Col>
        <Col xl={20} md={24}>
          <div className="mainContent vendor-profile">
            <div className="my-profile-container">
              <Tabs
                activeKey={activeStep}
                className="my-profile-vendor-tab"
                defaultActiveKey="1"
                items={items}
                onChange={onChange}
              />
              {/* <Form
            className="step-one-form"
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            {activeStep === "1" ? (
              <Form.Item
                className="mb-0"
                wrapperCol={{
                  offset: 8,
                  span: 24,
                }}
              >
                <Button
                  className="register-button button-register"
                  htmlType="submit"
                  onClick={(e) => goNext(e)}
                >
                  Next
                </Button>
              </Form.Item>
            ) : (
              <>
                <Row>
                  <Col md={12} className={activeStep === "2" || activeStep === "3" ? 'customBackSection pe-2' : ''}>
                    <Form.Item
                      className="mb-0"
                      wrapperCol={{
                        offset: 8,
                        span: 24,
                      }}
                    >
                      <Button
                        className="go-back-vendor"
                        htmlType="button"
                        onClick={goBack}
                      >
                        Back
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col md={12} className={activeStep === "2" || activeStep === "3" ? 'customNextSection ps-2' : ''}>
                    <Form.Item
                      className="mb-0"
                      wrapperCol={{
                        offset: 8,
                        span: 24,
                      }}
                    >
                      {activeStep === "2" ? (
                        <Button
                          className="go-next-vendor"
                          htmlType="submit"
                          onClick={goNext}
                        >
                          Next
                        </Button>
                      ) : (
                        <Button
                          className="save-changes-vendor ps-2"
                          htmlType="submit"
                          onClick={onFinish}
                        >
                          Save Change
                        </Button>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            
          </Form> */}
              <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} className="profileModel">
                <p>Upload Profile Image</p>
                <Upload
                  // action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onChangeProfileImage}
                  onPreview={onPreview}
                >
                  {fileList.length < 5 && '+ Upload'}
                </Upload>
              </Modal>

            </div>
          </div>
          <CopyWriteFooter />
        </Col>
      </Row>
    </>
  );
}
