import React from "react";
import { Row, Col, Input, Button, Form } from "antd";
import { CiUser, CiMail, CiPhone, CiLock } from "react-icons/ci";
import { IoEyeOutline } from "react-icons/io5";
import { LuAsterisk } from "react-icons/lu";

export default function RegistrationStepOne({ onData, basicDetails }) {
  const onFinish = (values) => {
    onData(values);
  };

  const onFinishFailed = () => {};

  return (
    <Row className="step-one-container">
      <h2 className="vendor-register-step-one-head">Basic Details</h2>
      <Form
        className="step-one-form"
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        initialValues={{
          fullName: basicDetails?.fullName || "",
          email: basicDetails?.email || "",
          password: basicDetails?.password || "",
          confirm_password: basicDetails?.confirm_password || "",
          phone: basicDetails?.phone || "",
        }}
        onFinish={onFinish} // Handle form submission
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row>
          <Col md={12} className="custom-padding">
            <p style={{ textAlign: "start", fontFamily: "Poppins",display:"inline-flex" }}>
              Full Name
              <span><LuAsterisk  style={{ color: 'red', }} /></span>
            </p>
            <Form.Item
              className="form-input-class"
              name="fullName"
              rules={[{ required: true, message: "Please enter full name." }]}
            >
              <Input
                className="input-box-login"
                placeholder="Enter your name here"
                prefix={<CiUser className="form-icons" />}
              />
            </Form.Item>
          </Col>
          <Col md={12} className="custom-padding">
            <p style={{ textAlign: "start", fontFamily: "Poppins",display:"inline-flex" }}>Email<span><LuAsterisk  style={{ color: 'red', }} /></span></p>
            <Form.Item
              className="form-input-class"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Email is not valid .",
                  type: "email",
                },
              ]}
            >
              <Input
                className="input-box-login"
                placeholder="Johndoe@gmail.com"
                prefix={<CiMail className="form-icons" />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="custom-padding">
            <p style={{ textAlign: "start", fontFamily: "Poppins",display:"inline-flex" }}>
              Password
              <span><LuAsterisk  style={{ color: 'red', }} /></span>
            </p>
            <Form.Item
              className="form-input-class"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  min: 6,
                  message: "Password must be at least 6 characters long!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || /[!@#$%^&*(),.?":{}|<>]/.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "Password must contain at least one special character!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                type="password"
                className="input-box-login"
                placeholder="please input password"
                prefix={<CiLock className="form-icons" />}
                suffix={
                  <Button className="show-password-button">
                    <IoEyeOutline />
                  </Button>
                }
              />
            </Form.Item>
          </Col>
          <Col md={12} className="custom-padding">
            <p style={{ textAlign: "start", fontFamily: "POPPINS",display:"inline-flex" }}>
              Confirm Password
              <span><LuAsterisk  style={{ color: 'red', }} /></span>
            </p>
            <Form.Item
              className="form-input-class"
              name="confirm_password"
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },

                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                type="password"
                className="input-box-login"
                placeholder="please input confirm password"
                prefix={<CiLock className="form-icons" />}
                suffix={
                  <Button className="show-password-button">
                    <IoEyeOutline />
                  </Button>
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
        <Col md={12} className="custom-padding">
                <p
                  style={{
                    textAlign: "start",
                    fontFamily: "Poppins",
                    display:"inline-flex"
                  }}
                >
                  Phone Number
                  <span><LuAsterisk  style={{ color: 'red', }} /></span>
                </p>

                <Form.Item
                  className="form-input-class"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please enter phone number.",
                    },
                    {
                      validator: (_, value) =>
                        value && value.length === 10
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error(
                                "Phone number must be exactly 10 digits."
                              )
                            ),
                    },
                  ]}
                >
                  <Input
                    prefix={<CiPhone className="form-icons" />}
                    placeholder="please input phone number"
                    className="input-box-login"
                    maxLength={10}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onPaste={(e) => {
                      const paste = (
                        e.clipboardData || window.clipboardData
                      ).getData("text");
                      if (!/^[0-9]+$/.test(paste)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
        </Row>
        <Form.Item className="mb-0" wrapperCol={{ offset: 8, span: 24 }}>
          <Button className="register-button button-register" htmlType="submit">
            Next
          </Button>
        </Form.Item>
      </Form>
    </Row>
  );
}
